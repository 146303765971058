@base-font-size: 16px;
@default-padding: 16;
@branding-default-color: #2575E8;
@border-default-color: #dfdfdf;

.rem(@property; @size) {
  @rem: (@size / @base-font-size) * 1rem;
    @{property}: ~'@{rem}';
}

.border-radius(@px) {
  .rem(-webkit-border-radius, @px);
  .rem(-moz-border-radius, @px);
  .rem(border-radius, @px);
}

#faq-page, #tutoriais-page {

  .header-content {

    > .row {
      align-items: center;
    }

    h2 {
      color: @branding-default-color;
      .rem(font-size, 29);
    }

    .assinaturas-wrapper {
      .rem(padding-right, @default-padding * 2);
    }

  }

  .main-content {
    .rem(margin-bottom, 60);

    .accordion {
      margin: 0 auto;
      .rem(padding-left, 20);
      .rem(padding-right, 20);
      .rem(padding-top, 30);
      .rem(padding-bottom, 30);
      .border-radius(4);
      background-color: #fff;
    }

    .card {
      .card-header,
      .card-body .list-group-item a,
      .card-body .article-content {
        .rem(padding, 12);
        .rem(padding-left, 16);
        .rem(padding-right, 16);
        .rem(font-size, 14);
      }

      .card-header {
        color: #fff;
        background-color: @branding-default-color;

        h3,
        span {
          margin: 0;
          width: 100%;
          display: flex;
          position: relative;
          font-weight: normal;
          font-size: 12px;

          .ic-sort {
            .rem(right, 20);

            @media @mediaMd {
              right: -15px;
            }
            @media @mediaXs {
              right: -25px;
            }
            position: absolute;
            background: none;
            border: none;
            cursor: pointer;
            height: 10px;
            top: -3px;

            &.ic-sort-desc {
              top: 7px;
            }

            &:active,
            &:hover,
            &:focus,
            &:visited {
              outline: none;
            }

            .icon-caret-up,
            .icon-caret-down {
              top: -4px;
              font-size: 12px;
              position: relative;
              color: #fff;
              opacity: .4;
              transition: opacity .4s;

              &.active,
              &:hover {
                opacity: 1;
                cursor: pointer;
              }
            }
          }
        }
      }

      .card-body {
        .list-group-item {
          & > a {
            .rem(padding-top, 2);
            .rem(padding-bottom, 2);
            display: block;
            color: @border-default-color;
            border-color: @border-default-color;
            border: 0;
            .rem(border-bottom-width, 2);
            border-style: solid;
            text-decoration: none;

            .article-title {
              color: @branding-default-color;
              font-size: 14px;
            }

            .article-updated-at {
              color: #000;
              font-size: 14px;
            }
          }

          .article-content-wrapper {
            margin: 0;
            padding: 0;
            max-height: 0;
            overflow: hidden;
            transition: all .5s ease-in-out;

            a {
              color: #2575E8;
              padding: 0;
              margin: 0;
            }
          }

          &.active {
            .article-content-wrapper {
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}
