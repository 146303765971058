.jornal-de-hoje{
  white-space: nowrap;
  .image{
    figure{
      margin: 0;
    }
    width: 120px;
    max-height: 105px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #e8e8e8;
    box-shadow: 2px 0 1px #e8e8e8;
    img{
      max-width: 100%;
    }
  }
  .title{
    color: #000000;
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    text-transform: uppercase;
    height: 34px;
    padding: 0 10px;
    line-height: 34px;
    border: 1px solid #e8e8e8;
    border-radius: 0 4px 4px 0;
  }
  &.footer{
    margin-top: 22px;
    .image{
      max-height: 150px;
      width: 150px;
    }
  }
}

@media (min-width:801px){
  .jornal-de-hoje {
    .image {
      width: 95px;
    }
  }
}
@media (min-width:1025px){
  .jornal-de-hoje {
    .image {
      width: 120px;
    }
  }
}