.toast-container {
    position: fixed;
    bottom: 10px;
    z-index: 999;
    max-width: 90%;
    width:100%;
    left:50%;
    transform: translate3d(-50%, 0, 0);

    @media (min-width: 820px) {
        max-width: 800px;
    }
}