.overlay-modal{
  height:100%;
  width:100%;
  background-color: rgba(0, 0, 0, 0.45);
  position:fixed;
  left:0;
  top:0;
  opacity: 0;
  z-index: 999;
  transition: opacity ease 100ms;
  .container-modal{
    background:#f5f5f5;
    position:absolute;
    max-height:100%;
    padding:22px 16px;
    .modal-title{
      font-family: 'Lato';
      font-size: 14px;
      color: #000;
      display:inline-flex;
      align-items:center;
      margin-bottom:28px;
      i{
        font-size:18px;
      }
      .title{
        margin-left:13px;
      }
    }

    .block-notification{
      font-family: 'Lato';
      font-size: 12px;
      color: #757575;
      margin-top: 8px;
      .content{
        border-radius: 2px;
        background-color: #ffffff;
        padding:8px;
        margin-top:8px;
        position: relative;
        .notification-head{
          width:100%;
          i{
            margin-right:8px;
            &.icon-chat{
              color:#3f51b5;
            }
            &.icon-question-circle-o{
              color:#ff9800;
            }
            &.icon-credit-card{
              color:#2196f3;
            }
            &.icon-doc-text-inv{
              color:#e91e63;
            }
          }
          .time-notification {
            right: 8px;
            top: 8px;
            position: absolute;
          }
        }
      }
    }

    &.notification{
      display:none;
      width:0;
      height:100%;
      top:0;
      opacity:0;
      right:-262px;
      transition: all ease 0.3s;
      &.active{
        display:block;
        width:262px;
        opacity:1;
        right:0;
      }
    }
  }
  &.active{
    opacity: 1;
  }
}
.btn-clear{
  -webkit-appearance: none;
  -moz-appearance: none;
  background:none;
  border:none;
  margin:0;
  padding:0;
  i:before{
    margin:0;
    padding:0;
  }
}