/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
    outline: none;
    position: relative;
    flex-direction: row;
    padding-bottom: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #dfe3e9;
    background-color: #ffffff;
}

.DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;

    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;

    display: inline-block;
    margin-top: 2px;
    width: 48px;
    height: 48px;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8B9898;
    cursor: pointer;
    outline: none;
    opacity: 0.5;
    &:before{
        content: "";
        display: table;
        margin: 0 auto;
        width: 6px;
        height: 10px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        top: 18px
    }
}

.DayPicker-NavButton:hover {
    opacity: 1;
}

.DayPicker-NavButton--prev{
    left: 0;
    right: auto;
    &:before{
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
    }
}

.DayPicker-NavButton--next:before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
    display: none;
}

.DayPicker-Caption {
    display: table-caption;
    margin: -1em -1em 0 -1em;
    text-align: center;
    border-bottom: 1px solid #dfe3e9;
    padding: 15px 0 15px 0;
}

.DayPicker-Caption > div {
    font-size: 14px;
    color: #354052;
    text-align: center;
}

.DayPicker-Weekdays {
    display: table-header-group;
}

.DayPicker-WeekdaysRow {
    display: table-row;
}

.DayPicker-Weekday {
    display: table-cell;
    padding: 20px 0 15px 0;
    color: #bac4d2;
    text-align: center;
    font-size: 10px;
}

.DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.DayPicker-Body {
    display: table-row-group;
}

.DayPicker-Week {
    display: table-row;
}

.DayPicker-Day {
    display: table-cell;
    padding: 12px;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    outline: none;
}

.DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #EAECEC;
    color: #8B9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
}

.DayPicker-Footer {
    padding-top: 0.5em;
}

.DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4A90E2;
    font-size: 0.875em;
    cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
    color: #0096c5;
    font-weight: 700;
}

.DayPicker-Day--outside {
    color: #8B9898;
    cursor: default;
}

.DayPicker-Day--disabled {
    color: #DCE0E0;
    cursor: default;
    /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
    background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    color: #ffffff;
    z-index: 2;
    &:after{
        content: "";
        display: inline-block;
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        z-index: -1;
        background-color: #1a91eb;
        border-radius: 100px;
    }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    &:after{
        background-color: #51A0FA;
    }
}

.DayPicker:not(.DayPicker--interactionDisabled)
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
    display: inline-block;
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPicker-Day--markGreen:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled){
    color: green;
    background-color: #eefdee;
}

.Range {
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
        background-color: transparent;
        border-radius: 0;
        color: #354052;
        z-index: 1;
        &:before{
            content: "";
            position: absolute;
            width: ~"calc(100% + 18px)";
            height: 60%;
            display: inline-block;
            left: -9px;
            top: 20%;
            z-index: -1;
            background-color: #f4f4f4
        }
        &:after{
            display: none;
        }
    }
}

@media only screen and (max-width: 450px){
    .DayPicker{
        .DayPicker-Day{
            padding: 3.5vw;
        }
    }
}

@media only screen and (max-width: 400px){
    .DayPicker{
        .DayPicker-Day{
            padding: 3vw;
        }
    }
}

@media only screen and (max-width: 350px){
    .DayPicker{
        .DayPicker-Day{
            padding: 2.5vw;
        }
    }
}
.datepicker{
    p{
        font-family: Lato, Arial, sans-serif;
        font-size: 16px;
        letter-spacing: -0.1px;
        color: #2575e8;
    }
}

.input-control-calendar{
    cursor: pointer;
    position: relative;
    .input-control{
        cursor: pointer;
        input[readonly]{
            background: #FFFFFF;
            color: #000000;
            cursor: pointer;
        }
        label{
            cursor: pointer;
        }

        input[readonly]:focus{
            border-color: #2575E8;
        }

        &:not(.isset) input[readonly]:focus + label{
            top: 18px;
            font-size: 16px;
        }

        &.isset input + label{
            color: #2575E8;
        }
    }
}