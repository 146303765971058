.previsao-do-tempo{
  display: inline-block;
  line-height: 15px;
  color: #444444;
  text-decoration: none;
  >*{
    display: inline-block;
    vertical-align: middle;
  }
  .icon{
    &:before{
      font-family: "icones-meu-estadao";
      font-size: 18px;
      vertical-align: middle;
    }
    &[data-status="1"]:before{
      content: "\e92d";
    }
    &[data-status^="2"]:before{
      content: "\e928";
    }
    &[data-status="3"]:before{
      content: "\e92a";
    }
    &[data-status="4"]:before{
      content: "\e92a";
    }
    &[data-status="4r"]:before{
      content: "\e92c";
    }
    &[data-status="4t"]:before{
      content: "\e92a";
    }
    &[data-status="5"]:before{
      content: "\e92a";
    }
    &[data-status="6"]:before{
      content: "\e92c";
    }
    &[data-status="7"]:before{
      content: "\e936";
    }
    &[data-status^="8"]:before{
      content: "\e936";
    }
    &[data-status^="9"]:before{
      content: "\e92f";
    }
  }
  .maximo{
    &:before{
      font-family: "icones-meu-estadao";
      content: "\e901";
      font-size: 9px;
      margin-right: 2px;
      vertical-align: middle;
    }
    margin-right: 2px;
  }

  .minimo{
    &:before{
      font-family: "icones-meu-estadao";
      content: "\e902";
      font-size: 9px;
      margin-right: 2px;
      vertical-align: middle;
    }
  }
}