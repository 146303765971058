.forma-de-pagamento{
    .inner-content{
        .tab-list{
            border-radius: 5px 5px 0 0;
            overflow: hidden;
            width: 100%;
            display: table;
            height: 85px;
            text-align: center;
            background: #FFFFFF;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid #dedede;
            &[data-length="3"]{
                li{
                    width: 33.33%;
                    @media @mediaXs{
                        padding: 0 15px;
                        font-size: 14px;
                    }
                }
            }
            li{
                display: table-cell;
                width: 50%;
                vertical-align: middle;
                cursor: pointer;
                font-size: 16px;
                color: #b3b3b3;
                border-right: 1px solid #dedede;
                background: #fbfbfb;
                &:last-child{
                    border-right: none;
                }
                &.active{
                    font-weight: bold;
                    background: #FFFFFF;
                    color: #5a6169;
                }
            }
        }

        .input-control:not(.checkbox):not(.radio):not(.select){
            background: transparent;
        }

        .block-single{
            border-radius: 0 0 5px 5px;
            display: table;
            width: 100%;
            form {
                padding: 0 5px;
                margin-bottom: -12px;
                .row {
                    margin-right: -5px;
                    margin-left: -5px;
                    &> [class*="col"] {
                        padding-right: 5px;
                        padding-left: 5px;
                    }

                }
            }
        }
        .lock-size-form{
            display: table;
            margin: 0 auto;
            max-width: 634px;
            width: 100%;
        }
        .credit-card, .boleto-por-email{
            form{
                background-color: #efefef;
                border-radius: 4px;
                padding: 20px;
            }
            .bandeira{
                span{
                    background: #FFFFFF;
                    border-radius: 3px;
                    border: solid 1px #dedede;
                    width: 100%;
                    height: 55px;
                    text-align: center;
                    line-height: 55px;
                    font-size: 30px;
                    color: #dedede;
                    display: inline-block;
                    i:before{
                        width: 37px;
                    }
                    i[data-flag]{
                        &:before{
                            content: "";
                            background-image: url("/static/img/sprite-cartoes.png");
                            background-repeat: no-repeat;
                            position: relative;
                        }
                        &[data-flag="AMEX"]:before{
                            width: 22px;
                            height: 22px;
                            background-position: -87px -5px;
                        }

                        &[data-flag="VISA"]:before{
                            width: 30px;
                            height: 22px;
                            background-position: -44px -5px;
                        }

                        &[data-flag="MASTERCARD"]:before{
                            width: 26px;
                            height: 22px;
                            background-position: -7px -5px;
                        }

                        &[data-flag="DINERS"]:before{
                            width: 23px;
                            height: 22px;
                            background-position: -126px -6px;
                        }
                    }
                }
                &.disabled{
                    span{
                        background-color: #f9f9f9;
                        color: #808080;
                    }
                }
            }
            .text-blue{
                color: #2575e8;
                font-size: 18px;
                margin: 0;
                display: table;
                height: 55px;
                text-align: right;
                width: 100%;
                span{
                    display: table-cell;
                    vertical-align: middle;
                }
            }
        }

        .direct-debit{
            form{
                background-color: #efefef;
                border-radius: 4px;
                padding: 20px;
                min-height: 240px;
                .lista-de-bancos{
                    display: table;
                    margin: 0 auto;
                    padding: 0;
                    width: 100%;
                    max-width: 405px;
                    &>li{
                        display: table-cell;
                        vertical-align: middle;
                        width: 25%;
                        padding: 0 3px;
                        .input-brand{
                            position: relative;
                            width: 100%;
                            max-width: 95px;
                            height: 55px;
                            margin: 0 auto;
                            i{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                border-radius: 4px;
                                border: solid 1px #dedede;
                                background-color: #ffffff;
                            }
                            input{
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                opacity: 0;
                                z-index: 2;
                                margin: 0;
                                cursor: pointer;
                                & + i{
                                    &:after{
                                        content: "";
                                        display: block;
                                        margin: 0 auto;
                                        background-image: url("/static/img/sprite-bancos.png");
                                        background-repeat: no-repeat;
                                        background-size: 150px;
                                        position: relative;
                                    }
                                }
                                &[value="bancobrasil"] + i:after{
                                    width: 40px;
                                    height: 40px;
                                    top: 7px;
                                    background-position: 0 -41px;
                                }
                                &[value="bradesco"] + i:after{
                                    width: 36px;
                                    height: 40px;
                                    top: 7px;
                                    background-position: -41px -39px;
                                }
                                &[value="itau"] + i:after{
                                    width: 32px;
                                    height: 32px;
                                    top: 11px;
                                    background-position: -77px -43px;
                                }
                                &[value="santander"] + i:after{
                                    width: 40px;
                                    height: 36px;
                                    top: 9px;
                                    background-position: -110px -39px;
                                }

                                &:checked{
                                    &[value="bancobrasil"] + i{
                                        background-color: #fff22d;
                                        &:after{
                                            background-position: 0 0;
                                        }
                                    }
                                    &[value="bradesco"] + i{
                                        background-color: #cc203c;
                                        &:after{
                                            background-position: -41px 1px;
                                        }
                                    }
                                    &[value="itau"] + i{
                                        background-color: #db812e;
                                        &:after{
                                            background-position: -77px -2px;
                                        }
                                    }
                                    &[value="santander"] + i{
                                        background-color: #fe0000;
                                        &:after{
                                            background-position: -110px 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .active-banco{
                    p{
                        text-align: left;
                    }
                }

                p{
                    text-align: center;
                    font-weight: lighter;
                    width: 100%;
                }
            }
        }

        .paypal-form{
            width: 370px;
            margin: auto;
            padding: 8px;

            .paypal-texts {
                width: 100%;
                margin-left: 23px
            }
            .h1-paypal{
                width: 100%;
                font-family: Helvetica Neue, Arial, sans-serif;
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: left;
                color: #0070ba;
                margin-top: 12px;
            }
            .h2-paypal {
                width: 100%;
                font-family: Helvetica Neue, Arial, sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #0070ba;
                margin-top: 16px;
            }
            .text-paypal {
                width: 100%;
                font-family: Lato, Arial, sans-serif;
                font-size: 16px;
                color: #4a4a4a;
            }
            #paypal-button {
                margin-top: 32px;
            }
        }

        .swg{
            padding: 50px;
            .google-brand{
                display: table;
                width: 100%;
                max-width: 200px;
                margin: 0 auto;
                border-radius: 4px;
                border: solid 1px #d1d1d1;
                height: 100px;
                .center{
                    display: table-cell;
                    vertical-align: middle;
                    img{
                        width: 100%;
                        max-width: 100px;
                        margin: 0 auto;
                        display: table;
                    }
                }
            }
            p{
                font-weight: lighter;
                color: #808080;
                margin-top: 0;
                font-size: 16px;
            }

            @media @mediaXs, @mediaSm{
                padding: 30px 10px;
            }

            @media @mediaXs{
                .google-brand{
                    margin-bottom: 30px;
                }
            }
        }
    }
}

@media @mediaMd{
    .forma-de-pagamento{
        .data-form {
            .inner-content {
                margin-top: 25px;
                padding-top: 0;
            }
        }
    }
}

@media @mediaXs{
    .forma-de-pagamento{
        .data-form{
            .inner-content{
                margin-top: 25px;
                .block-single{
                    padding: 15px 0;
                }

                .credit-card{
                    form{
                        border-radius: 0;

                        .row .bandeira{
                            padding-right: 0;
                            span{
                                border-radius: 3px 0 0 3px;
                                border-right: none;
                            }
                            & + div{
                                padding-left: 0;
                                input{
                                    border-radius: 0 3px 3px 0;
                                }
                            }
                        }
                    }
                }

            }
            .salvar-dados{
               font-size: 13px;
            }
        }

    }
}