
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icones-meu-estadao";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-location:before {
  content: '\e800';
}

/* '' */
.icon-building:before {
  content: '\e801';
}

/* '' */
.icon-mail:before {
  content: '\e802';
}

/* '' */
.icon-calendar:before {
  content: '\e803';
}

/* '' */
.icon-book:before {
  content: '\e804';
}

/* '' */
.icon-star:before {
  content: '\e805';
}

/* '' */
.icon-barcode:before {
  content: '\e806';
}

/* '' */
.icon-arrows-cw:before {
  content: '\e807';
}

/* '' */
.icon-list-alt:before {
  content: '\e808';
}

/* '' */
.icon-megaphone:before {
  content: '\e809';
}

/* '' */
.icon-up-big:before {
  content: '\e80a';
}

/* '' */
.icon-down-big:before {
  content: '\e80b';
}

/* '' */
.icon-minus:before {
  content: '\e80c';
}

/* '' */
.icon-bell:before {
  content: '\e80d';
}

/* '' */
.icon-credit-card:before {
  content: '\e80e';
}

/* '' */
.icon-user:before {
  content: '\e80f';
}

/* '' */
.icon-chat:before {
  content: '\e810';
}

/* '' */
.icon-cog:before {
  content: '\e811';
}

/* '' */
.icon-floppy:before {
  content: '\e812';
}

/* '' */
.icon-cancel-1:before {
  content: '\e813';
}

/* '' */
.icon-upload:before {
  content: '\e814';
}

/* '' */
.icon-cancel:before {
  content: '\e815';
}

/* '' */
.icon-eye:before {
  content: '\e816';
}

/* '' */
.icon-trash-empty:before {
  content: '\e817';
}

/* '' */
.icon-plus:before {
  content: '\e818';
}

/* '' */
.icon-ok-circled:before {
  content: '\e819';
}

/* '' */
.icon-login:before {
  content: '\e81a';
}

/* '' */
.icon-award:before {
  content: '\e81b';
}

/* '' */
.icon-clock:before {
  content: '\e81c';
}

/* '' */
.icon-tags:before {
  content: '\e81d';
}

/* '' */
.icon-perfil:before {
  content: '\e81e';
}

/* '' */
.icon-shape:before {
  content: '\e81f';
}

/* '' */
.icon-attach:before {
  content: '\e820';
}

/* '' */
.icon-lock:before {
  content: '\e821';
}

/* '' */
.icon-check:before {
  content: '\e822';
}

/* '' */
.icon-check-empty-1:before {
  content: '\e823';
}

/* '' */
.icon-download:before {
  content: '\e824';
}

/* '' */
.icon-estadao:before {
  content: '\e900';
}

/* '' */
.icon-long-arrow-up:before {
  content: '\e901';
}

/* '' */
.icon-long-arrow-down:before {
  content: '\e902';
}

/* '' */
.icon-angle-left:before {
  content: '\e905';
}

/* '' */
.icon-angle-right:before {
  content: '\e906';
}

/* '' */
.icon-angle-down:before {
  content: '\e907';
}

/* '' */
.icon-angle-up:before {
  content: '\e908';
}

/* '' */
.icon-caret-left:before {
  content: '\e909';
}

/* '' */
.icon-caret-right:before {
  content: '\e90a';
}

/* '' */
.icon-caret-up:before {
  content: '\e90b';
}

/* '' */
.icon-caret-down:before {
  content: '\e90c';
}

/* '' */
.icon-bars:before {
  content: '\e90f';
}

/* '' */
.icon-search:before {
  content: '\e910';
}

/* '' */
.icon-facebook:before {
  content: '\e914';
}

/* '' */
.icon-twitter:before {
  content: '\e915';
}

/* '' */
.icon-google-plus:before {
  content: '\e916';
}

/* '' */
.icon-instagram:before {
  content: '\e917';
}

/* '' */
.icon-linkedin:before {
  content: '\e918';
}

/* '' */
.icon-youtube-play:before {
  content: '\e919';
}

/* '' */
.icon-whatsapp:before {
  content: '\e91a';
}

/* '' */
.icon-play:before {
  content: '\e920';
}

/* '' */
.icon-wi-day-cloudy:before {
  content: '\e928';
}

/* '' */
.icon-wi-day-rain:before {
  content: '\e929';
}

/* '' */
.icon-wi-day-rain-mix:before {
  content: '\e92a';
}

/* '' */
.icon-wi-day-snow:before {
  content: '\e92b';
}

/* '' */
.icon-wi-day-storm-showers:before {
  content: '\e92c';
}

/* '' */
.icon-wi-day-sunny:before {
  content: '\e92d';
}

/* '' */
.icon-wi-day-sunny-overcast:before {
  content: '\e92e';
}

/* '' */
.icon-wi-fog:before {
  content: '\e92f';
}

/* '' */
.icon-wi-night-alt-cloudy:before {
  content: '\e930';
}

/* '' */
.icon-wi-night-alt-partly-cloudy:before {
  content: '\e931';
}

/* '' */
.icon-wi-night-alt-rain-mix:before {
  content: '\e932';
}

/* '' */
.icon-wi-night-alt-showers:before {
  content: '\e933';
}

/* '' */
.icon-wi-night-alt-snow:before {
  content: '\e934';
}

/* '' */
.icon-wi-night-alt-storm-showers:before {
  content: '\e935';
}

/* '' */
.icon-wi-snow:before {
  content: '\e936';
}

/* '' */
.icon-round-lock:before {
  content: '\e937';
}

/* '' */
.icon-link-ext:before {
  content: '\f08e';
}

/* '' */
.icon-check-empty:before {
  content: '\f096';
}

/* '' */
.icon-up-circled:before {
  content: '\f0aa';
}

/* '' */
.icon-docs:before {
  content: '\f0c5';
}

/* '' */
.icon-money:before {
  content: '\f0d6';
}

/* '' */
.icon-doc-text:before {
  content: '\f0f6';
}

/* '' */
.icon-circle-empty:before {
  content: '\f10c';
}

/* '' */
.icon-spinner:before {
  content: '\f110';
}

/* '' */
.icon-circle:before {
  content: '\f111';
}

/* '' */
.icon-folder-open-empty:before {
  content: '\f115';
}

/* '' */
.icon-rocket:before {
  content: '\f135';
}

/* '' */
.icon-lock-open-alt:before {
  content: '\f13e';
}

/* '' */
.icon-doc-text-inv:before {
  content: '\f15c';
}

/* '' */
.icon-apple:before {
  content: '\f179';
}

/* '' */
.icon-android:before {
  content: '\f17b';
}

/* '' */
.icon-dot-circled:before {
  content: '\f192';
}

/* '' */
.icon-file-pdf:before {
  content: '\f1c1';
}

/* '' */
.icon-paper-plane:before {
  content: '\f1d8';
}

/* '' */
.icon-paper-plane-empty:before {
  content: '\f1d9';
}

/* '' */
.icon-share:before {
  content: '\f1e0';
}

/* '' */
.icon-newspaper:before {
  content: '\f1ea';
}

/* '' */
.icon-bell-off-empty:before {
  content: '\f1f7';
}

/* '' */
.icon-toggle-off:before {
  content: '\f204';
}

/* '' */
.icon-toggle-on:before {
  content: '\f205';
}

/* '' */
.icon-cart-plus:before {
  content: '\f217';
}

/* '' */
.icon-pinterest:before {
  content: '\f231';
}

/* '' */
.icon-user-plus:before {
  content: '\f234';
}

/* '' */
.icon-television:before {
  content: '\f26c';
}

/* '' */
.icon-calendar-check-o:before {
  content: '\f274';
}

/* '' */
.icon-commenting-o:before {
  content: '\f27b';
}

/* '' */
.icon-credit-card-alt:before {
  content: '\f283';
}

/* '' */
.icon-shopping-basket:before {
  content: '\f291';
}

/* '' */
.icon-percent:before {
  content: '\f295';
}

/* '' */
.icon-question-circle-o:before {
  content: '\f29c';
}

/* '' */
.icon-envelope-open-o:before {
  content: '\f2b7';
}

/* '' */
.icon-user-circle-o:before {
  content: '\f2be';
}

/* '' */
.icon-id-card-o:before {
  content: '\f2c3';
}

/* '' */
.icon-podcast:before {
  content: '\f2ce';
}

/* '' */
.icon-facebook-squared:before {
  content: '\f308';
}

/* '' */
.icon-print:before {
  content: "\e825";
}

.icon-docs:before {
  content: '\f0c5';
}

/* '' */
.icon-doc-text:before {
  content: '\f0f6';
}

/* '' */
.icon-bookmark-o:before {
  content: '\e903';
}