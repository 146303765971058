.tabs{
  display:inline-flex;
  text-align: center;
  width: 100%;
  height:56px;
  align-items: center;

  .tab-item{
    width:25%;
    .tab-label{
      color:#9e9e9e;
      transition: color ease 200ms;
      i{
        display: block;
        font-size: 16px;
      }
    }
    &[data-status="1"]{
      .tab-label{
        transition: color ease 200ms;
        color:#2575E8;
      }
    }

  }

}