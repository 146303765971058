#as-react-datatable-table-head{
  display:none;
}
#as-react-datatable-table-foot{
  display:none;
}

table{
  font-family: Lato, Arial, sans-serif;
  border-spacing: 0 !important;
  width:100%;
  thead{
    background-color: #2575E8;
  }
  th{
    padding:8px 16px;
    text-align: left;
    color:#fff !important;
    font-size: 12px;
    font-weight: normal;
  }
}
@media print {
  table {
    border: 0 !important;
    tr:first-of-type td {
      border: 0 !important;
    }
    th{
      border: 0 !important;
    }
  }
}