.publishing-container {
  margin: 0 -10px;

  & > .item {
    float: left;
    width: calc(20% - 20px);
    margin: 0 10px 20px;

    & > .publishing {
      display: inline-block;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #fff;

      a {
          display: block;
      }
    }

    @media @mediaLg {
      width: 25%;
    }

    @media @mediaMd {
      width: 33.33%;
    }

    @media @mediaXs {
      width: 50%;
    }
  }
}

.publishing.slide-item {
  img {
    width: 100%;
  }
}