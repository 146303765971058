.nota-fiscal-wrapper {
  background-color: #fff;
  padding: 5% 0 5%;

  p.descricao-nota {
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #2575e8;
    margin-left: -15px;

    a {
      text-decoration: none;
    }

    margin-bottom: 30px;
  }

  .nota-fiscal-fundo {
    background-color: #efefef;
    padding: 75px 20px 55px;
    border-radius: 4px;

    .col-10, .col-2 {
      padding: 6px;
    }

    .btn.btn-copy {
      background: #fff;
      border: solid 1px #dedede;
      border-radius: 4px;
      height: 55px;

      i {
        color: #2a2a2a;
        font-size: 30px;
      }
    }
  }

  .ajustes-btn .col-12 {
    margin-top: 30px;
    padding: 0;
  }

  @media @mediaXs {
    .nota-fiscal-fundo {
      padding: 30px 24px 20px 8px;

      .row {
        max-width: 100%;
      }

      .btn.btn-copy {
        padding: 0 5px;
      }

      .col-10, .col-2 {
        padding: 1px;
      }

      #input_chave_id {
        font-size: 12px;
      }

      .btn.btn-copy {
        background: #fff;
        border: solid 1px #dedede;
        border-radius: 4px;
        height: 55px;

        i {
          color: #2a2a2a;
          font-size: 30px;
        }
      }
    }
  }
}

.fatura-container {
  background: #fff;
  padding: 20px;

  table.table-fatura {
    h1, h2, p {
      margin: 0;
    }

    border-collapse: collapse;
    font-family: 'Lato', sans-serif;
    width: 100%;
    border: 1px solid #ccc;
    color: #5a6169;

    tr {
      td {
        box-sizing: border-box;
        clear: both;
        border: 1px solid #ccc;
        padding: 5px 20px;
        font-size: 16px;

        h1 {
          font-size: 29px;
          font-family: 'Lato Bold', sans-serif;
        }

        b {
          font-family: "Lato bold", sans-serif;
        }

        h2 {
          font-size: 16px;
        }
      }
    }
  }
}

.google-container {
  background: white;
  padding: 90px 45px;

  p.google-descr {
    color: #808080;
    margin: 0;
    font-family: 'Flama',sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 10px 0;
    &.bold {
      line-height: 24px;
      font-weight: bold;
      &:first-child{
        margin-top: 5px;
        margin-bottom: 20px;
      }
    }
    br{
      margin: 20px 0 ;
    }
  }

  .google-icon {
    height: 100px;
    border-radius: 4px;
    border: solid 1px #d1d1d1;
    display: table;
    vertical-align: middle;
    width: 100%;

    > div {
      vertical-align: middle;
      justify-content: center;
      display: table-cell;
      text-align: center;

      .google-text {
        font-size: 24px;
        font-family: sans-serif;

        &.color-1 {
          color: #3182f6;
        }

        &.color-2 {
          color: #ef4131;
        }

        &.color-3 {
          color: #fdbe07;
        }

        &.color-4 {
          color: #31aa52;
        }
      }
    }
  }
}
.a-non-d{
  text-decoration: none;
}
.paypal_box{
  border-radius: 4px;
  border: solid 1px #d1d1d1;
}
.paypal_icon{
  width:150px;
  height: 26px;
  background-image: url("/static/img/logo-paypal.png");
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
  overflow: hidden;
  margin: 32px 5px;
  background-position: center;
}


.apple_box{
  img {
    width: 80px;
  }
  text-align: center;
  padding: 50px;
  background-color: #f5f5f5;
}
.apple_container {
  padding: 0;
  padding: 15px;
}
.apple_text {
  width: 496px;
  padding-top: 60px;
}

.apple-descr {
  color: #808080;
  margin: 0;
  font-family: 'Flama',sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 10px 0;
  &.bold {
    line-height: 24px;
    font-weight: bold;
    &:first-child{
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }
  br{
    margin: 20px 0 ;
  }
}


.status-list {
  list-style: none;
  padding: 0;

  li {
    margin-top: 0;
    margin-bottom: 25px;

    .status-titulo {
      margin: 0;
      font-size: 16px;
      color: #5a6169;
      font-weight: 400;
      font-family: Lato, sans-serif;
    }

    p.status-valor {
      margin: 0;
      font-family: Lato, sans-serif;
      color: #5a6169;
      font-weight: 700;
    }
  }

}

@media @mediaXs {
  .nota-fiscal-container {
    margin-top: 0;
  }
  .nota-fiscal-wrapper{
    p.descricao-nota {
      margin-left: 0;
    }
  }

  .fatura-container {
    margin-top: 20px;

    table.table-fatura {
      border: 0;

      tr {
        td {
          display: block;
          text-align: left !important;
        }
      }
    }
  }

  .nota-status {
    display: none;
  }

  .nota-fiscal-wrapper {
    margin-top: 20px;
  }

  .nota-fiscal-container {

    .row.justify-content-center, .nota-fiscal > .row {
      max-width: 100vw;
    }

    .espacamento-btn {
      margin-top: 30px;
    }
  }

  .fatura-container {
    margin-top: 20px;
  }

  .google-container {
    padding: 50px 0;
    margin-top: 20px;

    .google-icon {
      width: 80%;
      margin-bottom: 20px;
      margin-left: 10%;
    }

  }
}