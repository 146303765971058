.correcao-redacao {
    .block-single{
        .content-form{
            .calendar{
                width: 80%;
                margin: 0 auto;
            }
        }
    }

    form {
        margin-bottom: -12px;
        .row {
            margin-right: -8px;
            margin-left: -8px;
            &> [class*="col"] {
                padding-right: 7px;
                padding-left: 7px;
            }

        }

    }
    .legenda-entrega {
        font-size: 16px;
        color: #808080;
    }
}

@media @mediaXs, @mediaSm, @mediaMd {
    .correcao-redacao {
        .data-form {
            .block-single {
                &:first-child {
                    margin-bottom: 25px;
                }
            }
        }
    }
}

@media @mediaXs {
    .correcao-redacao{
        .data-form{
            .block-single{
                .content-form{
                    .calendar{
                        width: 100%;
                    }
                }
            }
            .salvar-dados{
                margin-top: 0;
            }
        }
    }
}