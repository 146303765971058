.contratos-assinatura-wrapper {

  .texto {
    height: 500px;
    overflow-y: auto;
    padding: 25px 40px;
    text-align: justify;
    hyphens: auto;
    word-break: break-word;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: #F5F5F5;
      border-radius: 100px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: #555;
      border-radius: 100px;

    }

    h3 {
      font-weight: 600;
      color: #5a6169;
    }

    p.identacao {
      text-indent: 45px;
    }

    ol {
      margin: 20px 0;
    }
  }

  .contratos-status {
    border-radius: 4px;
    padding: 25px 20px;
  }

  .status-list {
    padding: 0;
    list-style: none;
    li {
      margin-top: 0;
      margin-bottom: 25px;
    }

    .status-titulo {
      margin: 0;
      font-size: 16px;
      color: #5a6169;
      font-weight: 400;
      font-family: Lato, sans-serif;
    }

    p.status-valor {
      margin: 0;
      font-family: Lato, sans-serif;
      color: #5a6169;
      font-weight: bold;
    }
  }

  @media print {
    .no-print, .no-print * {
      display: none !important;
    }
  }
}

@media @mediaXs {
  .contratos-assinatura-wrapper {
    .conteudo {
      margin-top: 20px;

      .texto {
        height: 100%;
        overflow-y: auto;
        padding: 20px 5px;
        text-align: justify;
        hyphens: auto;
        word-break: break-word;

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
          background-color: #F5F5F5;
          border-radius: 100px;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: #F5F5F5;
          border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb {
          background: #555;
          border-radius: 100px;

        }

        h3 {
          font-weight: 600;
          color: #5a6169;
        }

        p.identacao {
          text-indent: 45px;
        }

        ol {
          margin: 20px 0;
        }
      }
    }
  }
}