.text-left{
  text-align:left !important;
}
.text-center{
  text-align:center !important;
}
.text-right{
  text-align:right !important;
}
.align-center{
  /* display:inline-flex; */
  display:flex;
  align-items:center;
  position:relative;
}
.align-end{
  align-items:end;
  position:relative;
}
.align-start{
  align-items:start;
  position:relative;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-end{
  justify-content: flex-end;
}
.float-right{
  float:right;
}
.float-left{
  float:left;
}
.full-width{
  width:100%;
}

.right{
  float: right;
}

