.block-single{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px;
    &.text{
        h3{
            font-weight: normal;
            color: #2575e8;
            font-size: 18px;
            letter-spacing: -0.5px;
            margin-top: 0;
            margin-bottom: 20px;
        }
        p{
            font-size: 16px;
            color: #5a6169;
        }
    }
}