.slide-item.evento{
    color:#fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    //max-width: 207px;
    //min-height: 194px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    .date{
        font-size: 16px;
        letter-spacing: 1.3px;
        text-align: center;
        color: #ffffff;
        display: block;
        height: 20px;
    }
    .content-text{
        height: 115px;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        align-items: center;
        color: #fff;
        display: inline-flex;
        white-space: normal;
    }
    .button{
        display: table;
        margin: 0 auto;
        a{
            width: 178px;
            height: 32px;
            border-radius: 4px;
            border: solid 1px #ffffff;
            background-color: rgba(255, 255, 255, 0.1);
            color:#fff;
            display: block;
            padding-top: 6px;
            font-size: 16px;
            text-transform: uppercase;
            text-decoration: none;
            transition: .2s ease all;
            &:hover{
                color: #000000;
                background-color: #FFFFFF;
            }
        }
    }

}

.events-container{
    display: inline-block;
    width: 100%;
    margin: 0 -10px;
    @media @mediaXs{
        margin: 0;
    }
    h2{
        margin: 15px 10px;
        font-size: 22px;
        font-weight: 400;
        letter-spacing: -.2px;
        color: #535353;
    }
    &>.item{
        float: left;
        width: 20%;
        margin-bottom: 20px;
        padding: 0 10px;
        &>.evento{
            display: inline-block;
            width: 100%;
        }

        @media @mediaLg{
            width: 25%;
        }

        @media @mediaMd{
            width: 33.33%;
        }

        @media @mediaXs{
            width: 50%;
            padding: 0 5px;
            margin-bottom: 10px;
            .evento{
                padding: 10px;
                .content-text{
                    font-size: 16px;
                }
                .button{
                    width: 100%;
                    a{
                        width: 100%;
                        font-size: 14px;
                    }
                }
            }

        }
    }
}