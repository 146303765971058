.partners-container{
    margin: 0 -10px;
    &>.item{
        float: left;
        width: 20%;
        margin-bottom: 20px;
        &>.partners{
            display: inline-block;
            padding: 0 10px;
            width: 100%;
        }

        @media @mediaLg{
            width: 25%;
        }

        @media @mediaMd{
            width: 33.33%;
        }

        @media @mediaXs{
            width: 50%;
        }
    }
}

.partners.slide-item{
    img{
        width: 100%;
    }
}