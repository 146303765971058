.block-cards-body{
  margin-bottom: 30px;
  display: grid;
  .header{
    margin-bottom: 15px;
    .title{
      font-size: 22px;
      letter-spacing: -0.2px;
      color: #535353;
      margin: 0;
      font-weight: normal;
    }
  }

  .block{
    width: 100%;
    padding: 11.5px 8px;
    background: #ffffff;
    display: block;
    position: relative;
    &>.lock{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.8);
      z-index: 2;
      text-align: center;
      color: #4a4a4a;
      display: inline-block;
      &:before{
        content: "";
        display: inline-block;
        height: 100%;
        width: 1px;
        vertical-align: middle;
      }
      &>.center{
        display: inline-block;
        vertical-align: middle;
        width: ~"calc(100% - 2px)";
        &>i{
          display: block;
          font-size: 30px;
        }
        &>p{
          font-size: 18px;
          max-width: 240px;
          margin: 20px auto;
        }
        a{
          color: #2575E8;
          text-decoration: none;
        }
      }
    }
    ul{
      display: table;
      margin: 0;
      padding: 0;
      list-style: none;
      &>li{
        &:not(.disabled){
          &>a:hover{
            top: -2px;
            right: -2px;
            box-shadow: -2px 2px 5px -2px #000;
          }
        }
        &>a{
          display: inline-block;
          position: relative;
          top:0;
          right: 0;
          transition: 0.15s ease all;
        }
      }
    }
    ul.links{
      &>li{
        float: left;
        width: 33.3333333%;
        display: inline-block;
        padding: 8px;
        &.disabled{
          &>a{
            background-color: #fafafa;
            opacity: 0.4;
            cursor: default;
          }
        }
        &>a{
          height: 88px;
          width: 100%;
          border: 1px solid #f5f5f5;
          color: #535353;
          &>i{
            font-size: 40px;
            line-height: 88px;
            vertical-align: middle;
            display: inline-block;
            width: 30%;
          }
          span{
            vertical-align: middle;
            display: inline-block;
            font-size: 13px;
            text-align: center;
            word-break: break-word;
            width: 70%;
            padding: 0 11px;
          }
        }
      }
    }
  }
}

.block-cards-body{
  .block{
    ul.news{
      padding: 0 8px;
      &>li{
        width: 100%;
        border-top: 1px solid transparent;
        border-bottom: 1px solid #f5f5f5;
        min-height:94px;
        &:last-child>a{
          border-bottom-color: transparent;
        }
        &>a{
          width: 100%;
          padding: 7px 5px;
          &>.image{
            width: 54px;
            height: 54px;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            img{
              display: none;
            }
          }
          &>.text{
            display: inline-block;
            width: ~"calc(100% - 60px)";
            vertical-align: top;
            padding: 2px 8px;
            &>.editoria{
              color: #2575E8;
              font-size: 14px;
              margin-top: 5px;
            }
            &>.title{
              margin: 0;
              font-size: 14px;
              color: #535353;
            }
          }
        }
      }
    }
  }
}



.block-cards-body{
  .block{
    ul.tags{
      padding: 8px;
      &>li{
        float: left;
        &>a{
          font-size: 14px;
          padding: 10px 15px;
          border: 1px solid #f5f5f5;
          display: inline-block;
          color: #2575E8;
          text-decoration: none;
          margin: 5px;
          border-radius: 4px;
          letter-spacing: -0.4px;
        }
      }
    }
  }
}

////////////// Mobile
@media @mediaXs{
  .block-cards-body{
    margin-bottom: 30px;
    .header{
      .title{
        font-size: 16px;
        color: #535353;
        font-weight: 300;
      }
    }
    .block{
      margin-left: -15px;
      margin-right: -15px;
      width: auto;
      padding: 0;
      display: block;
      ul.links{
         padding: 0;
         &>li{
            padding: 0;
            width: 50%;
         }
       }
    }
  }
}
