.before-footer{
    background: #f5f5f5;
    padding-bottom: 30px;
    .item{
        background: #FFFFFF;
        display: inline-block;
        text-decoration: none;
        width: 100%;
        border-radius: 5px;
        position: relative;
        padding: 15px;
        font-size: 14px;
        color: #999999;
        top:0;
        right: 0;
        transition: 0.15s ease all;
        vertical-align: top;
        border:none;
        text-align: left;
        height: 100%;
        cursor: pointer;
        font-family: Lato,Arial,sans-serif;
        .title-mobile{
            display: none;
        }

        .title{
            color: #4a4a4a;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 55px;
            margin-top: 15px;
            display: block;
            width: 154px;
        }

        &>i{
            position: absolute;
            right: 0;
            top: 0;
            color: #e9ecef;
            font-size: 62px;

            @media @mediaLg{
                font-size: 70px;
            }
        }
        &:not(.disabled):hover{
            top: -2px;
            right: -2px;
            box-shadow: -2px 2px 5px -2px #000;
        }

        .p-f {
            margin-top: 0;
        }
    }

    
}

footer{
    .sac-block{
        border-radius: 4px;
        background-color: #f4f4f4;
        padding:12px 16px;
        margin: 22px -15px 0 -15px;
        font-size:14px;
        h3{
            margin: 0;
        }
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
            display: inline-block;
            width: 100%;
            &:last-child{
                margin-bottom: 0;
            }
            li{
                float: left;
                display: inline-block;
                margin-right: 8px;
                &:after{
                    content: "|";
                    margin-left: 8px;
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
    .menu-content{
        margin: 10px 0;
        ul{
            display: block;
            padding:0;
            margin: 0;
            text-align: left;
        }
        li{
            margin-right: 10px;
            display: inline-block;
            &:after{
                content: "·";
                margin-left: 10px;
            }
            &:last-child{
                &:after{
                    display: none;
                }
            }
            a{
                font-size: 14px;
                color: #000000;
                text-decoration: none;
                transition: color ease 0.2s;
                &:hover{
                    color:#2575E8;
                }
            }
        }
    }
    .copyright-mobile{
        display: none;
    }
}

// Mobile
@media @mediaMd{
    .before-footer{
        padding-bottom: 0;
        .col{
            padding: 0;
        }
        .container{
            margin: 0;
            padding: 0;
            max-width: 100%;
            .row{
                margin: 0;
                display: grid;
                grid-template-columns: auto auto;
            }
        }
        .item{
            border: 1px solid #f5f5f5;
            border-radius: 0;
            padding: 3px 0 8px 0;
            &>i{
                position: relative;
                font-size: 35px;
                display: block;
                text-align: center;
                color: #c3c8cd;
                margin-bottom: 8px;
            }
            &>strong, .title, &>p{
                display: none;
            }

            &>.title-mobile{
                display: block;
                text-align: center;
                margin: 0;
                color: #868686;
                font-size: 12px;
                text-transform: uppercase;
                width: 100%;
            }
        }
    }

    footer{
        .footer-brand-wrapper{
            display: none;
        }
        .footer-newspaper-wrapper{
            display: none;
        }

        .menu-content{
            display: none;
        }

        .sac-block{
            margin: 20px 0;
            ul{
                li{
                    float: none;
                    line-height: 24px;
                    &:after{
                        display: none;
                    }
                }
            }
        }

        .copyright-mobile{
            display: block;
            margin: 20px 0;
            text-align: center;
        }
    }
}

@media @mediaXs{
    footer{
        padding-bottom: 70px;
        .copyright-mobile{
            display: block;
            margin: 0;
            text-align: left;
        }
    }
}