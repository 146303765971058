.addFont(@name, @url, @style, @weight){
  @url_comple: @url;
  @font-face {
    font-family: @name;
    src: url("@{url_comple}.eot");
    src: url("@{url_comple}.eot#iefix") format('embedded-opentype'),
    url("@{url_comple}.woff2") format('woff2'),
    url("@{url_comple}.woff") format('woff'),
    url("@{url_comple}.ttf") format('truetype'),
    url("@{url_comple}.svg") format('svg');
    font-style: @style;
    font-weight: @weight;
  }
}

.addFont('icones-meu-estadao','/static/fonts/icones-meu-estadao/meu-estadao','normal','normal');
.addFont('Flama-semibold','https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont','normal','normal');
.addFont('Flama-bold','https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont','normal','normal');
.addFont('Flama','https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont','normal','normal');

@import "../font-icon/animation";
@import "../font-icon/meu-estadao";
@import "../font-icon/meu-estadao-codes";
@import "../font-icon/meu-estadao-embedded";
