.nomargin{
  margin:0 !important;
}
.nomargin-top{
  margin-top:0px !important;
}
.nopadding{
  padding:0 !important;
}
.nopadding-left{
  padding-left:0 !important;;
}
.nopadding-right{
  padding-right:0 !important;
}
.nopadding-bottom{
  padding-bottom:0 !important;
}