.fale-conosco{
    .success-message{
        color: #fff;
        background-color: #5bb85d;
        line-height: 100px;
        font-size: 30px;
        border-radius: 10px;
        text-indent: 15px;

        i {
            font-size: 40px;
            color: #8ee690;
            text-indent: 0;
            top: 4px;
            position: relative;
        }
    }
}