.banner-wrapper{
    width: 100%;
    margin-bottom: 25px;
    img{
        max-width: 100%;
        display: table;
        margin: 0 auto;
        &.mobile{
            display: none;
        }
        @media @mediaXs{
            display: none;
            &.mobile {
                display: table;
            }
        }
    }
}