.PortalDoAssinanteIframeLoader{

}
.portal-do-assinante-wrapper{
  width: 100%;
  position: relative;
  &.hide{
    display: none;
  }
  #portal-do-assinante-iframe{
    width: 100%;
    border: 0;
  }
}