@LatoBold: 'LatoBold',Georgia;
@LatoRegular: 'LatoRegular',Georgia;
@LatoBlack: 'LatoBlack',Georgia;

.modal-inscricao{
    padding: 32px;
    @media (max-width: 767px){
        padding: 16px;        
    }
    &>button{
        right: 26px;
        top: 28px;
        @media (max-width: 767px){
            right: 11px;
            top: 12px;
        }
    }

}

.content-news{
    width: 100%;
    .logo{
        width: 88px;
        height: 19px;
        margin-top:0px;
    }
    img{
        border: 0;
        display: inline-block;
        max-width: 100%;
    }    

    //header-newsletter.less
    .header-news{
        width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;    
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; 
        margin-top: 18.6px;
        margin-bottom:20px;
        padding:8px;
        background-color: rgba(0, 95, 147, 0.1); 
        position: relative;
        @media screen and (min-width:768px){        
            -webkit-flex-wrap: nowrap;
            -moz-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;             
            padding:16px;        
        }
        
    }
    .header-news-info{
        position: relative;        
        @media (max-width: 767px){
            width: 100%;
            height: 71px;
            margin-right:0;
            margin-left:0; 
            margin-top:0;
            display: flex;
            &:before {
                content: "";
                box-shadow: inset 0 -50px 30px -30px #000;
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .title-figure{
            display: none;
            @media (max-width: 767px){
                display: block;
                position: absolute;
                bottom: 0px;
                left: 8px;
                color:#fff
            }
        }      
    }
    .header-news-title{
        //font-family: @LatoBold;
        font-weight: bold;
        font-size: 18px;
        line-height: 1.22;
        margin:0 0 8px 0;
        text-transform: uppercase;
        float: none;
        color: #000;
        @media (max-width: 767px){
            display: none;
        }
    }
    .header-news-desc{
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.38;
        margin: 0;
    }
    .header-news-excl{
        font-size: 14px;
        font-weight: bold;
        line-height: 1.21;
        color: #000;
        margin-top: 8px;
        .icon-round-lock{
            font-size: 16px;
        }
        .icon-lock-open-alt{
            border: 1px solid #000;
            border-radius: 50px;
            font-size: 12px;
            margin-left: 4px
        }
    }
    .header-news-figure{
        overflow: hidden;
        margin-bottom:10.5px;
        position: relative;
        .title-figure{
            display: none;
        }
        @media screen and (min-width:768px){
            margin-right:15px;
            margin-bottom:0;
            margin-left:0;
            margin-top:0;
            max-width: 246px;
        }
        @media (max-width: 767px){
            width: 100%;
            height: 71px;       
            margin-right:0;
            margin-left:0; 
            margin-top:0;
            &:before {
                content: "";
                box-shadow: inset 0 -50px 30px -30px #000;
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

        }
        img{
            @media (max-width: 767px){
                width: 100%;
            }
        }
        
    }
    .header-news-box{    
        -webkit-flex:1; 
        -ms-flex:1;
        flex:1;
        @media (max-width: 767px){
            margin-top: 5px;
        }
    }


    //main-newsletter.less
    .main-news{
        display: block;
        width:100%;
        .mold-form-news{
            display:block;
        }
        .mold-news-btns{
            display:none;
        }
        /*assinante*/
        &.-subscriber{
            &.-exclusiveSubscriber{
                .mold-form-news{
                    display:block;
                }   
                .mold-news-btns{
                    display:none;
                }  
            }    
        }
        /*fechadas para os assinante*/
        &.-exclusiveSubscriber{
            &.-logged{
                .news-link{
                    display:none;
                }            
            }
            .mold-form-news{
                display:none;
            }
            .mold-news-btns{
                display:block;
            }        
        }
        &.-sairConta{
            .sair-conta{
                display:block;
            }
        }
        &.-buttonActive{
            .-more-news{
                display:block;
            }
        }
        .bt-news{
            &.-more-news{
                margin-top:40px;
                margin-left: auto;
                margin-right: auto;            
                @media screen and (min-width:768px){
                    margin-top:50px;
                }
            }
        }        
    }
    .-more-news{
        display:none;        
    }

    //news-newsletter.less
    .news-btns{
        width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-flex-direction:column;
        -moz-flex-direction:column;
        -ms-flex-direction:column;
        flex-direction:column;    
        @media screen and (min-width:768px){ 
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;             
            webkit-box-pack: space-between;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            -ms-flex-pack: space-between;
            justify-content: space-between;
            -webkit-align-items: center;
            -moz-align-items: center;
            -ms-align-items: center;
            -ms-flex-align: center;
            align-items: center;        
            -webkit-flex-wrap: nowrap;
            -moz-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;        
        }
        @media (max-width: 767px){            
            text-align: center;
        }
    }
    .news-url-selo{
        display: block;     
        .news-img-selo{
            width: auto;
            max-width: 100%;
            display: block;
        }
    }
    .news-link{
        //font-family: @LatoBlack;
        font-size: 16px;
        line-height: normal;
        //color: #005f93;
        color:@cor-estadao;
        margin-top:35px;
        margin-bottom:10px;
        @media screen and (min-width:768px){
            margin:0;       
        }
        span,svg{
            display: inline-block;
            vertical-align: middle;
        }
        svg{
            margin-left:5px;
        }
    }

    .lgpd-news-modal {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        text-align: left;
        color: #808080;
        &> a {
            color:#2575E8;
        }
    }


    //Botão assine
    .assinaturas{
        .btn{
            min-width: auto;
            display: inline-block;
        }
    }
}