.menu{
    border-bottom: 1px solid #e8e8e8;
    .menu-principal{
        font-family: Lato, Arial, sans-serif;
        ul.list{
            list-style: none;
            text-align: center;
            margin: 0;
            padding: 0;
            i[class*="icon-"]{
                display: none;
            }
            &>li{
                display: inline-block;
                position: relative;
                &.home{
                    display: none;
                }
                &:not(.focusout):hover{
                    &>a{
                        background-color:rgba(0, 94, 149, 0.05);
                    }
                    &>.submenu{
                        display: inline-block;
                    }
                }
                &>a{
                    padding: 0 20px;
                    display: inline-block;
                    font-weight: normal;
                    height: 56px;
                    line-height: 56px;
                    font-size: 13px;
                    text-decoration: none;
                    text-transform: uppercase;
                    color: #000000;
                    background: #FFFFFF;
                }
                &>.submenu + a{
                    &:after{
                        font-family: "icones-meu-estadao";
                        content: "\e907";
                        margin-left: 5px;
                    }
                }
                &>.submenu{
                    display: none;
                    z-index: 100;
                    position: absolute;
                    top: 99%;
                    left: 0;
                    text-align: left;
                    padding: 20px;
                    background: #FFFFFF;
                    min-width: 270px;
                    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.5));
                    border-radius: 3px 3px 0 0;
                    border-bottom: 4px solid #2575E8;
                    &:before{
                        content: "";
                        border: 4px solid transparent;
                        border-bottom-color: #FFFFFF;
                        position: absolute;
                        bottom: 100%;
                        left: 25%;
                        z-index: 2;
                    }
                    &>a{
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        float: left;
                        white-space: nowrap;
                        font-weight: 600;
                        text-decoration: none;
                        color: #000000;
                        font-size: 15px;
                        padding: 8px 0;
                        transition: 0.2s ease all;
                        &.mobile{
                            display: none;
                        }
                        &:not(.lock){
                            &:before{
                                font-family: "icones-meu-estadao";
                                content: "\e906";
                                margin-left: 5px;
                                opacity: 0;
                                position: absolute;
                                left: -15px;
                                top: 10px;
                                text-indent: 0;
                                transition: 0.2s ease-in-out all;
                            }
                            &:hover, &.active{
                                color: #2575E8;
                                padding-left: 15px;
                                &:before{
                                    opacity: 1;
                                    left: 0px;
                                }
                            }
                        }
                        &.lock{
                            color: #9b9b9b;
                            &:after{
                                padding-left: 10px;
                                font-family: "icones-meu-estadao";
                                content: "\e937"
                            }
                        }
                    }

                    &>.assine{
                        display: inline-block;
                        width: 100%;
                        padding-top: 30px;
                        &>a{
                            line-height: 32px;
                            padding: 0 20px;
                            width: 100%;
                            height: 34px;
                            text-align: center;
                            white-space: nowrap;
                            font-size: 10px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

@media @mediaSm, @mediaXs{
    .menu{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 48px;
        background: #fafafa;
        .menu-principal{
            display: table;
            width: 100%;
            height: 100%;
            background: #fafafa;
            box-shadow: 0 -0.6px 0 0 #cecece;
            ul.list{
                display: table-row;
                i[class*="icon-"]{
                    display: block;
                }
                &>li{
                    display: table-cell;
                    width: 25%;
                    height: 100%;
                    float: left;
                    &[data-key="meus_dados"]{
                        float: right;
                    }
                    &.home{
                        display: table-cell;
                    }
                    &>a{
                        white-space: nowrap;
                        width: 100%;
                        height: 100%;
                        background: #fafafa;
                        text-transform: none;
                        font-size: 10px;
                        line-height: initial;
                        padding: 0;
                        padding-top: 8px;
                        color: #9e9e9e;
                        &.active{
                            color: #2575E8;
                            background: #fafafa;
                        }
                        &>i{
                            font-size: 16px;
                        }
                        &[data-mlabel]:not([data-mlabel="false"]){
                            &:after{
                                content: attr(data-mlabel);
                                font-family: Lato, Arial, sans-serif;
                                margin: 0;
                            }
                            span{
                                display: none;
                            }
                        }
                    }

                    &>.submenu{
                        display: inline-block;
                        position: fixed;
                        top: 55px;
                        left: 100%;
                        width: 100%;
                        height: ~"calc(100% - 103px)";
                        z-index: -1;
                        border-radius: initial;
                        border-bottom: none;
                        padding: 0;
                        filter: none;
                        transition: 0.5s ease all;
                        overflow: auto;
                        padding-bottom: 15px;
                        &:before{
                            display: none;
                        }
                        &>a{
                            height: 80px;
                            line-height: 79px;
                            border-bottom: 1px solid #f5f5f5;
                            font-size: 13px;
                            font-weight: normal;
                            padding: 0 6px;
                            color: #535353;
                            &.mobile{
                                display: inline-block;
                            }
                            &.active{
                                color: #2575E8;
                            }
                            i{
                                display: inline-block;
                                vertical-align: middle;
                                font-size: 40px;
                                height: 100%;
                            }
                            &>span{
                                display: inline-block;
                                vertical-align: middle;
                            }
                            &:not(.lock){
                                &:hover, &.active{
                                    text-indent: 0;
                                    padding-left: 0;
                                    &:before{
                                        display: none;
                                    }
                                }
                            }

                            [data-mlabel]:not([data-mlabel="false"]){
                                text-indent: -9999px;
                                overflow: hidden;
                                &:after{
                                    text-indent: 0;
                                    float: right;
                                    content: attr(data-mlabel);
                                    font-family: Lato, Arial, sans-serif;
                                    margin: 0;
                                }
                            }
                        }

                        &>.assine>a{
                            font-size: 16px;
                            height: 45px;
                            line-height: 45px;
                            max-width: 95%;
                            margin: 0 auto;
                            display: table;
                        }
                    }

                    &.focus{
                        &>a{
                            background-color: rgba(0,94,149,.05);
                            color: #9e9e9e;
                        }
                        &>.submenu{
                            left: 0;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}

