table {
  width:100%;
  .historico-pagamento {
    font-size: 14px;
    padding: 12px 16px;
    color: #000000;

    &.ciclo {
      font-weight: normal;
    }
    &.action{
      display: inline-flex;
      min-width:220px;
      min-height: 44px;
      button{
        color: #2575E8;
        font-size:15px;
        display: inline-flex;
        border-radius:3px;
        align-items: center;
        cursor:pointer;
        transition:all ease 200ms;
        &:hover{
          color: #417505;
        }
        &[disabled]{
          color: #636363;
          opacity:0.6;
          &:hover{
            border: 0;
          }
        }
      }
    }

    input {
      border: none;
      cursor: pointer;
      outline: none;
      font-size: 14px;
      padding: 12px 16px;
      color: #000000;
    }

  }

  td {
    border-bottom: solid 1px #dfdfdf;
    p{
      margin:0;
    }
    &.status {
      font-weight: bold;

      .red {
        color: #d0021b;
      }

      .yellow {
        color: #f7b500;
      }

      .green {
        color: #417505;
      }

    }
    &.expand-arrow{
      img{
        display:none;
        transition:transform ease 200ms;
      }
    }
  }
  th{
    &.expand-arrow{
      width: 70px;
    }
  }
  button{
    appearance: none;
    background:none;
    border:none;
    float:left;
  }
}
.expanded-row{
  td{
    height:0;
    transition: height ease 200ms;
    display:block;
    border:none;
    .expanded-content{
      transition: all ease 200ms;
      position: absolute;
      overflow: hidden;
      height: 0;
      font-size:14px;
      text-align: left;
      button{
        margin:0;
        padding:0;
        color:#2575E8;
        float:none;
        width: 100%;
        i{
          margin-left: -3px;
          font-size: 16px;
          margin-right: 15px;
        }
      }
    }
  }
}
th.sortable {
  color: #2196F3;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image:url("/static/icons/datatable/icon_arrow.svg");
  background-position: 95% 50%;
  background-size: 10px;
}
.as-react-table .input-group-text:empty{
  display: none;
}
.asrt-page-length{
  display: inline-block;
}
.asrt-page-length .input-group-addon{
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 0;
  background: #fff;
  border: none;
}
.asrt-pagination {
  margin: 0;
}
.alert-row{
  padding: 0 16px;
}
.google{
  h1{
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #2575e8;
  }
  h2{
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #5a6169;
  }
}
@media (max-width: 768px){
  .data-pagamento{
    background-color:#fff;
    .block-single{
      padding:0;
      .row{
        margin:0;
      }
      .col-md-12{
        padding:0;
      }
      table{
        border: solid 1px #dfdfdf;
        // border-bottom:none;
        margin-bottom: 20px;
        tr{
          &.active{
            .expand-arrow{
              img{
                
                transform:rotate(180deg);
              }
            }
          }
          &.expanded-row{
            td{
              height:0;
              border:none;
              overflow: hidden;
              padding: 0;
              min-height: initial;
            }
            &.active{
              display:block;
              min-height: 215px;
              height:auto;
              td{
                border:none;
                display:block;
                height:150px;
                .expanded-content{
                  min-height: 150px;
                  height:215px;
                  padding: 9px 16px;
                  width: 100%;
                  p{
                    margin: 0 0 8px 0 !important;
                  }
                }
              }
            }
          }
        }
        tr.active + tr{
          td{
            border-top:solid 1px #dfdfdf;
          }
        }
        th{
          font-size:12px;
          &.sortable {
            background-size: 8px;
          }
          &.ciclo {
            display:none;
          }
          &.action {
            display:none;
          }
          &.expand-arrow{
            width:34px;
          }
        }
        td{
          border-bottom: solid 1px #dfdfdf;
          text-align: center;
          padding: 8px 5px;
          min-height:34px;
          &:nth-last-child{
            border:none;
          }
          p{
            margin:0;
            width: 100%;
          }
          &.expand-arrow{
            display:table-cell;
          }
          &.ciclo {
            display:none;
          }
          &.action {
            display:none;
          }
          &.expand-arrow{
            img{
              display:block;
            }
          }
        }
      }
    }
  }
}

.button-icon-credit-card {
  width: 140px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;


  .text-button-icon-credit-card {
    color: white;
    font-size: 14px;
    font-family: Lato;
    font-weight: 700;
    line-height: 14px;
    word-wrap: break-word;
  }
}

.button-icon-print {
  width: 140px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;

  .text-button {
    color: #2575E8;
    font-size: 14px;
    font-family: Lato;
    font-weight: 700;
    line-height: 14px;
    word-wrap: break-word;
  }
}

.button-icon-mail {
  width: 140px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;

  .text-button-icon-mail {
    color: #2575E8;
    font-size: 14px;
    font-family: Lato;
    font-weight: 700;
    line-height: 14px;
    word-wrap: break-word;
  }
}
.button-icon-barcode {
  width: 140px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;

  .text-button-icon-barcode {
    color: #2575E8;
    font-size: 14px;
    font-family: Lato;
    font-weight: 700;
    line-height: 14px;
    word-wrap: break-word
  }
}

.button-icon-barcode:hover {
  background-color: #E9F1FD;
}

.bg-red {
  border: 1px #d0021b solid;
  background: #d0021b;
}

.bg-yellow {
  border: 1px #DC720C solid;
  background: #DC720C;
}

.button-icon-credit-card-m {
  width: 100%;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;

  .text-button-icon-credit-card-m {
    color: white;
    font-size: 14px;
    font-family: Lato;
    font-weight: 700;
    line-height: 14px;
    word-wrap: break-word;
  }
}

.button-icon-print-m {
  width: 100%;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;

  .text-button-m {
    color: #2575E8;
    font-size: 14px;
    font-family: Lato;
    font-weight: 700;
    line-height: 14px;
    word-wrap: break-word;
  }
}

.button-icon-mail-m {
  width: 100%;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;

  .text-button-icon-mail-m {
    color: #2575E8;
    font-size: 14px;
    font-family: Lato;
    font-weight: 700;
    line-height: 14px;
    word-wrap: break-word;
  }
}

.button-icon-barcode-m {
  width: 100%;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;

  .text-button-icon-barcode-m {
    color: #2575E8;
    font-size: 14px;
    font-family: Lato;
    font-weight: 700;
    line-height: 14px;
    word-wrap: break-word
  }
}





.menu1 {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0px 1px 5px rgba(102, 102, 102, 0.60);
  z-index: 9999;
}
.menu2 {
  height: 80px;
  padding: 12px;
  left: 0px;
  top: 3px;
  position: absolute;
  background: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
  border-bottom: 2px #2575E8 solid;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
}
.menu3 {
  // height: 89px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
  margin-top: -25px;
}
.menu4 {
  width: 149px;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.menu5 {
  width: 4.71px;
  height: 8.09px;
  position: relative;
}
.menu6 {
  width: 4.71px;
  height: 8.09px;
  left: 0px;
  top: 0px;
  position: absolute;
  background: #2575E8;
}
.menu7 {
  width: 136px;
  color: #1E1E21;
  font-size: 12px;
  font-family: Lato;
  font-weight: 700;
  word-wrap: break-word;
};
.menu8 {
  width: 149px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}
.menu9 {
  width: 149px;
  color: #1E1E21;
  font-size: 12px;
  font-family: Lato;
  font-weight: 400;
  word-wrap: break-word;
  text-align: left;
  transform: translateX(0px);
  transition: transform 0.5s ease;
  padding: 5px 0;
}

.menu9:hover {
  color: #2575E8;
  transform: translateX(10px);
  transition: transform 0.5s ease;
  font-weight: bold;
}

.menu9:hover::before {
  color: #2575E8;
  transform: translateX(10px);
  transition: transform 0.5s ease;
  content: "> ";
  font-weight: bold;
}

.seta {
  color: #2575E8;
  transform: translateX(10px);
  transition: transform 0.5s ease;
  font-family: FontAwesome;
  content: '\f0da';
}
.menu10 {
  width: 149px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}
.menu11 {
  width: 149px;
  color: #1E1E21;
  font-size: 12px;
  font-family: Lato;
  font-weight: 400;
  word-wrap: break-word;
}

.blue_arrow_r {
  width: 20px;
  height: 20px;
  opacity: 0;
}

.blue_arrow_r:hover {
  width: 20px;
  height: 20px;
  opacity: 1;
}