body {
  &.no-scroll {
    overflow: hidden;
  }
}

.overlay-tutorial {
  display: none;
  opacity: 0;
  position: fixed;
  overflow-x: hidden;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  transition: all ease 300ms;

  &.active {
    display: block;
    position: absolute;
    opacity: 1;
  }

  .modal-target {
    background: #f5f5f5;
    padding: 15px;

    .modal-current-number {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -14px;
      margin-left: -14px;
      border-radius: 50% 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #2575E8;
      font-size: 16px;
      font-weight: bold;
      width: 28px;
      height: 28px;
      border: solid 3px #2575E8;
      background-color: #f6f6f6;
      line-height: 1.88em;
    }
  }

  .modal-information {
    width: 268px;
    opacity: 0;
    transition: opacity ease 200ms;
    transition-delay: 100ms;

    &.active {
      opacity: 1;
    }

    .modal-header {
      display: inline-flex;
      padding: 20px;
      background-color: #e8e8e8;
      align-items: center;
      width: 100%;
      margin-top: 12px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border: solid 1px #e8e8e8;
      font-size: 12px;
      border-bottom: none;

      .arrow-up {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 14px solid #e8e8e8;
        position: absolute;
        top: 0;
      }

      .modal-step {
        width: 35px;
        height: 22px;
        border-radius: 2px;
        background-color: #2575E8;
        color: #fff;
        font-size: 12px;
        text-align: center;
        padding-top: 3px;
      }

      .modal-title {
        color: #000;
        font-size: 12px;
        margin-left: 8px;
        font-weight: bold;
      }
    }

    .modal-content {
      padding: 0 20px 13px;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border: solid 1px #e8e8e8;
      font-size: 12px;
      color: #000000;
      z-index: 9;
      position: relative;

      p {
        margin-top: 19px;
        min-height: 83px;
      }

      .buttons {
        margin-left: -10px;
        margin-right: -10px;
        display: flex;

        .right {
          right: 13px;
          position: absolute;
          height: 31px;
          display: inline-flex;
        }

        button {
          border-radius: 2px;
          border: solid 1px #c8c8c8;
          background-color: #e6e6e6;
          padding: 7px 0;
          color: #000;
          cursor: pointer;
          width: 73px;
          text-align: center;

          &.prev {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
          }

          &.next {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          &[disabled] {
            border-radius: 2px;
            border: solid 1px #c8c8c8;
            background-color: #f6f6f6;
            text-align: center;
            font-size: 12px;
            color: #7f7f7f;

            &.prev {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-right: none;
            }

            &.next {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }

          &[data-action="close"]:not('.default') {
            color: #7f7f7f;
            background: #fff;
          }
        }
      }

      .arrow-down {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid #ffffff;
        position: absolute;
        margin-top: 13px;
      }
    }
  }

  .user-informations {
    padding: 5px 8px;
    border-radius: 4px;
    border: solid 1px #e8e8e8;

    .user-image {
      display: inline-block;
      vertical-align: middle;

      i {
        font-size: 20px;
        line-height: 21px;
      }

      .image {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50px;
        vertical-align: middle;
      }
    }
  }
}

@media (min-width: 768px) {
  .overlay-tutorial{
    .user-informations {
      .user-name {
        display: inline-block;
        font-size: 11px;
        color: #000;
        margin-left: 5px;
        text-transform: uppercase;
        padding-top: 4px;
      }
    }
  }
}
@media (max-width: 767px) {
  .overlay-tutorial {
    .user-informations {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 0;
      .user-name {
        display:none;
      }
    }
  }
}