.alert-session{
  p{
    font-family: Lato, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5a6169;
    margin-bottom:0;
  }
}
.title-row{
  p{
    margin-top:0;
    font-family: Lato, Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: -0.1px;
    color: #425573;
  }
}

.input-control-calendar{
  cursor: pointer;
  .input-control{
    cursor: pointer;
  }
  input[readonly]{
    background: #FFFFFF;
    color: #000000;
    cursor: pointer;
  }
  label{
    cursor: pointer;
  }

  input[readonly]:focus{
    border-color: #2575E8;
    & + label{
      color: #2575E8;
    }
  }

  .isset input + label{
    color: #2575E8;
  }
}