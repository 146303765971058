.newsletter-wrapper{
    .status-block{
        border-radius: 4px;
        background-color: #ffffff;
        padding:22px 14px;
        h3{
            margin: 0;
            font-weight: normal;
            font-size: 18px;
            letter-spacing: -0.5px;
            color: #2575e8;
        }
        .line{
            display:inline-flex;
            width:100%;
            font-size: 15px;
            letter-spacing: -0.5px;
            margin-top:20px;
            &.inscritos{
                color: #417505;
            }
            &.total{
                color: #2575E8;
            }
        }
        .label{
            padding: 0;
        }
        .value{
            font-size: 13px;
            text-align: center;
            color: #2575e8;
        }
        .base {
            height: 20px;
            text-align: center;
            border-radius: 3px;
            background-color: #e9ecef;
        }
    }

    .alterar-email-news {
        margin-bottom: 12px;

        p {
            width: auto;
            height: 63px;
            margin: 0 0 10px;
            font-family: Lato, Arial, sans-serif;
            font-size: 16px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            color: #3d5170;
        }

        .alterar-email-btn {
            width: 99.6%;
            height: 30%;
            margin: 10px 0 0;
            padding: 12px 69px 11px;
            border-radius: 5px;
            background-color: #2575e8;
            border: #2575e7;
            color: #ffffff;
            font-family: Lato, Arial, sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.21;
            letter-spacing: -0.44px;
                
        }
    }

    .blocks-content{
        border-radius: 4px;
        background-color: #ffffff;
        display: table-cell;
        h2{
            font-size: 16px;
            font-weight: bold;
            padding:0 16px;
            color: #000000;
        }
    }

    .checked-layer{
        display: none;
    }
    .alert-mensagem{
        width:100%;
    }
    .newsletter-desktop{
        display:none;
    }
    .newsletter-items{
        .newsletter-title{
            display:none;
        }
    }
    .newsletter-head-mobile{
        padding:0;
        h3{
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.1px;
            color: #425573;
        }
        p{
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #5d5d5d;
            &.box{
                border-radius: 5px;
                border:solid 1px #4a4a4a;
                font-size: 16px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.1px;
                text-align: center;
                color: #4a4a4a;
                padding: 8px 0;
            }
        }
    }

    .newsletter-desktop{
        display:block;
    }
    .newsletter-head-mobile{
        display:none;
    }
    .item-acordian-bg{
        i{
            display:none;
        }
    }
    .alterar-email-btn {
        width: 99.6%;
        height: 37px;
        padding: 7px 95px 8px;
        border: #2575e8;
        border-radius: 5px;
        background-color: #2575e8;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center;
    }
    .newsletter-items{
        border-radius: 4px;
        border: solid 1px #e9ecef;
        background-color: #ffffff;
        padding:11px 9px;
        margin: 0;
        display: inline-block;
        width: 100%;
        .newsletter-title{
            display: inline-flex;
            width: 100%;
            margin-left: 10px;
            font-size: 16px;
            font-weight: bold;
        }
        .newsletter-lgpd{
            margin-left: 10px;
            p{
                padding: 0;
                margin-top: 0;
            }
        }
    }
    .newsletter-item {
        float: left;
        width: 33.33%;
        padding: 9px;
        position: relative;
        display: block;

        .inner{
            min-height: 406px;
            border:solid 1px #cecece;
        }
        .item-acordian{
            position: relative;
        }
        .newsletter-content{
            padding:14px 16px;
            .title{
                text-transform: uppercase;
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 10px;                
                i{
                    display: none;
                }
            }
        }
        img{
            width: 100%;
            vertical-align: top;
            &.img-clean{
                width:auto;
            }
        }
        .newsletter-title{
            font-size: 14px;
            font-weight: 900;
            margin-bottom:9px;
        }
        .texto{
            margin-bottom:14px;
        }
        .texto, .info-periodo{
            font-family: Lato;
            font-size: 14px;  font-stretch: normal;
            line-height: 1.29;
            color: #5d5d5d;
        }
        &[data-checked="true"]{
            .checked-layer{
                background-color: rgba(37, 117, 232, 0.5);
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                margin-left: 0;
                text-align: center;
                padding: 20% 0 0 0;
                display: inline-block;
                i{
                    margin: auto;
                    color: #fff;
                    font-size: 40px;
                }
            }
        }
        .row-subscribe{
            position: absolute;
            bottom: 32px;
            left: 16px;
            width: 100%;
        }
        .assinante{
            font-size: 14px;
            color: #5d5d5d;
            margin-bottom: 14px;
            i{
                color: #000;
                font-size: 18px;
                display: inline-block;
                vertical-align: middle;
                &.icon-lock-open-alt{
                    color: #5d5d5d;
                    margin-left: 3px;
                    width: 19px;
                    height: 19px;
                    line-height: 18px;
                    vertical-align: middle;
                    display: inline-block;
                    text-align: center;
                    font-size: 12px;
                    border: 1px solid #5d5d5d;
                    border-radius: 50px;
                }
            }
        }

        .btninscricao,
        .btninscricaom{
            width: 82%;
            text-transform: uppercase;
        }

        .btninscricaoa{
            margin: 0 auto;
            width: 95%;
        }

        .btninscricaom{ width: 100%; }

        .btninscricaom.true{ 
            color: #fff;
            background: #2575E8;
        }

        .gratis30{
            font-weight: normal;
            font-size: 14px;
            padding-bottom: 5px;
        }

        .button-wrapper{
            position: absolute;
            bottom: 30px;
            width: 100%;
            left: 0;
            z-index: 1;
            text-align: center;
            button{
                &.subscribe{
                    border-radius: 4px;
                    border:solid 1px #2575E8;
                    background-color: #ffffff;
                    padding: 0 24px;
                    height: 50px;
                    line-height: 50px;
                    text-align:center;
                    margin:0 auto;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: -0.4px;
                    color: #2575E8;
                    cursor:pointer;
                    display: table;
                    &:active{
                        background:#e8e8e8;
                    }

                    &.true{
                        background-color: #2575E8;
                        color:#fff;
                        &:active{
                            background: #0076b0;
                        }
                    }
                }
            }
        }        
    }

    @media @mediaSm{
        .newsletter-container{
            background: #FFFFFF;
            margin-top: 40px;
        }

        .alterar-email-news{
            display: none;
        }


        .newsletter-status{
            .newsletter-desktop{
                display:none;
            }
            .newsletter-head-mobile{
                padding:0;
                display: inline-block;
                width: 100%;
                margin-top: -50px;
                h3{
                    font-size: 18px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: -0.1px;
                    color: #425573;
                }
                p{
                    font-size: 16px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #5d5d5d;
                    padding-top: 4px;
                    &.box{
                        border-radius: 5px;
                        border:solid 1px #4a4a4a;
                        font-size: 16px;
                        font-weight: bold;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: -0.1px;
                        text-align: center;
                        color: #4a4a4a;
                        padding: 8px 0;
                    }
                }
            }
        }

        .newsletter-items{
            padding: 0;
            border: 0;
            .newsletter-title{
                display: none;
            }
            .newsletter-item{
                width: 100%;
                padding: 0;
                .inner{
                    height: 80px;
                    transition: 0.5s ease all;
                    min-height: auto;
                    overflow: hidden;
                    .item-acordian{
                        height:80px;
                        overflow: hidden;
                        z-index: 2;
                        &:before{
                            content: "";
                            box-shadow: inset 0 -50px 30px -30px #000000;
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;                            
                        }

                        .item-acordian-bg{
                            &>i{
                                display: inline-block;
                                position: absolute;
                                right: 15px;
                                top: 50px;
                                z-index: 3;
                                color: #FFFFFF;
                                font-size: 16px;
                            }
                            .checked-layer{
                                .img-clean{
                                    position: absolute;
                                    right: 10px;
                                    top: 10px;
                                    width: 30px;
                                }
                            }
                        }
                    }
                    .newsletter-content{
                        .title{
                            z-index: 3;
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 75px;
                            width: 100%;
                            padding: 0 15px;
                            color: #FFFFFF;
                            font-weight: 900;
                            font-size: 18px;
                            line-height: 1.22;
                            text-transform: uppercase;
                            letter-spacing: normal;
                            display: table;
                            @media (max-width: 360px){
                                width: 280px;
                            }
                            span{
                                display: table-cell;
                                vertical-align: bottom;
                            }
                            .gratis30t{ display: table-footer-group; }
                            i{
                                font-size: 18px;
                                display: inline-block;
                                vertical-align: middle;
                                &.icon-lock-open-alt{
                                    margin-left: 3px;
                                    width: 19px;
                                    height: 19px;
                                    line-height: 18px;
                                    vertical-align: middle;
                                    display: inline-block;
                                    text-align: center;
                                    font-size: 12px;
                                    border: 1px solid #FFFFFF;
                                    border-radius: 50px;
                                }
                            }
                        }
                    }
                }

                &.active{
                    .inner{
                        height: 385px;
                    }
                }

                .btninscricao{ width: 95%; }
            }
        }
    }

    .titulo-header{
        @media (max-width: 767px){
            display: none;
        }
    }

    .titulo-header-mobile{
        @media screen and (min-width:768px){
            display: none;
        }
    }    

    //Página de listagem de newsletter anônima
    .status-inscrever{
        font-size: 18px;  
        line-height: 1.22;
        color: #3d5170;
    }

    .header-content{
        .logo{
            width: 175px;
            height: 38px;
            display: block;
            float: left;
            margin-top: 0px;
        }
        .titulo-topo{
            display: block;
            float: left;
            font-size: 29px;            
            line-height: 1.21;            
            color: #2575e7; 
            margin-top: 8px;           
            margin-left: 125px;
            @media (max-width: 767px){
                display: none;
            }
        }
    }

    .titulo{    
        font-size: 20px;        
        line-height: 1.2;
        letter-spacing: -0.15px;        
        color: #2575e8;
        margin-top: 14px;
    }

    .ver-ultima{        
        svg{
            margin-left:7px
        }
    }

    .btn-ver-ultima{
        font-size: 14px;
        font-weight: bold;        
        line-height: 1.21;
        letter-spacing: -0.44px;
        padding:0;
        color: #2575e8;
        background-color: transparent;
        border:none;
        outline:none;
        cursor: pointer;
    }

    .box-inscricao{
        position: absolute;        
        width: 380px;
        min-height: 226px;
        top:270px;
        left:-45px;
        padding:23px;
        z-index: 2;
        background-color: #fff;
        border: 1px solid #707070;
        border-radius: 4px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        text-align: center;
        @media (max-width: 767px){            
            position: relative;
            width: 100%;
            top:0;
            left:0;
            box-shadow: none;
            border: none;
            height: auto;
            padding:34px 0 0 0;
        }
        .bt-news{
            margin: 12px 0 0 0;
        }    
    }

    .inscricao-info{
        font-size: 14px;
        font-weight: 900;        
        line-height: 1.21;        
        text-align: left;
        text-transform: uppercase;
        margin: 30px 0 10px 0;
        @media (max-width: 767px){
            display: none;
        }
    }
    .inscricao-close{
        position: absolute;
        top: 14px;
        right: 18px;
        border: none;
        padding: 0;
        background-color: transparent;
        display: flex;
        cursor: pointer;
        @media (max-width: 767px){
            display: none;
        }
    }

    .opaque-item{
        opacity: 0.5;  
    }

    .alterar-email{
        font-size: 12px;
        font-weight: bold;        
        line-height: 1.25;        
        color: #2575e8;
        text-transform: uppercase;
        margin-bottom:2px;
        background:none;
        border:none;
        cursor: pointer;
    }

    .newsletter-anonimo{
        .button-wrapper{
            @media (max-width: 767px){
                display: none;
            }
        }
    } 
    
    .subscribe-anonimo{
        border-radius: 4px;
        border: none;
        background-color: #fff;
        padding: 0 24px;
        height: 50px;
        line-height: 50px;
        text-align:center;
        margin:0 auto;
        font-size: 14px;
        font-weight: bold;
        color: #2575E8;
        border: solid 1px #2575E8;
        cursor:pointer;
        display: table;        
    }

    
}

.form-newsletter{
    //form-newsletter.less
    .form-news{
        width: 100%;
        .form-field{
            width: 100%;        
            position: relative;
            @media screen and (min-width:768px){
                -webkit-flex:1; 
                -ms-flex:1;
                flex:1;
                max-width: 430px;
            }
            &[data-error]{
                &:after{
                    content: attr(data-error);
                    color: #d71a12;
                    text-align: left;
                    display: block;
                    font-size: 12px;
                    line-height: 1.33;
                    margin-top: 5px;
                    margin-bottom:3px;
                }
                input{
                    border-color: #e02020;
                    color: #e02020;                
                    &:focus,
                    &:valid {                    
                        border-color: #e02020;                   
                        & + .field-label-floating{
                            color: #e02020;
                        }
                    }
                }
                &.isset{
                    .field-label-floating{
                        color: #e02020;
                    }
                }            
                .field-label-floating{
                    color: #e02020;
                }
            }
            input{
                width: 100%;
                height: 55px;
                border-radius: 3px;
                border: 1px solid #dedede;
                padding: 0 15px;
                font-size: 16px;
                background: #fff;
                &:focus{
                    padding-top: 18px;
                    outline: none;
                    //border-color: #005f93;
                    border-color:@brd-estadao;
                    & + .field-label-floating{
                        font-size: 14px;
                        top: 8px;
                        //color: #005f93;
                        color:@cor-estadao;
                    }
                }            
                & + .field-label-floating{
                    font-size: 16px;
                    color: #808080;
                    position: absolute;
                    top: 18px;
                    left: 15px;
                    transition: .2s ease all;
                }
                &:disabled{
                    background-color: rgba(83, 83, 83, 0.15);
                    border-color:#e5e5e5;
                    color:#808080;
                    -webkit-text-fill-color: #808080;
                    -webkit-opacity: 1; /* required on iOS */
                    opacity: 1; /* required on iOS */
                    + .field-label-floating{
                        color:#808080;
                    }
                }
            }
            &.isset{
                input{
                    padding-top: 18px;
                    outline: none;
                    & + .field-label-floating{
                        font-size: 14px;
                        top: 8px;
                        //color: #005f93;
                        color:@cor-estadao;
                    }                              
                    &:disabled{
                        + .field-label-floating{
                            color:#808080;
                        }    
                    }
                }
            }
            /*Quando o input esta com onblur*/
            &.isset-onblur{
                input{
                    border-color: #979797;
                }
            }
        }
        .bt-news{
            &.-inscrever{
                margin-top:12px;
                margin-left: auto;
                margin-right:auto;
                @media screen and (min-width:768px){
                    margin-top:2px;
                    margin-left:12px;
                    margin-right:0;
                }
            }
        }
        input{
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            border-radius: 0;
        }
        /* HACK BG/Color :disabled modifica a cor e bg do fundo do input quando esta no estadao disabled */
        input:disabled:-webkit-autofill,
        input:disabled:-webkit-autofill:hover,
        input:disabled:-webkit-autofill:focus{
            -webkit-box-shadow: 0 0 0px 1000px #e5e5e5 inset !important; 
            -webkit-text-fill-color:#808080 !important;
        }
        /* HACK BG modifica o bg o input quando tem autocomplete */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus{
            -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
        }
        /* HACK Color quando seleciona autocomplete e não é valido */
        .form-field{
            &[data-error]{
                input:-internal-autofill-selected{
                    // color: -internal-light-dark-color(black, white) !important;
                    -webkit-text-fill-color: #e02020 !important;
                }
            }
        }
    }
    .group-field{
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;    
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; 
        -webkit-flex-direction:row;
        -moz-flex-direction:row;
        -ms-flex-direction:row;
        flex-direction:row;
        @media screen and (min-width:768px){        
            -webkit-flex-wrap: nowrap;
            -moz-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;        
        }
    }

    .form-news-label,
    .news-title{
        //font-family: @LatoRegular ;
        font-size: 16px;
        line-height: 1.13;
        float: none;
        color: #000;   
        display: block;
    }
    .form-news-label{
        margin: 0 0 8px 0;
    }
    .news-title{
        margin: 0 0 10px 0;
    }

    .bt-news{
        //font-family:@LatoBlack;
        background-color: #fff;    
        font-size: 14px;
        border-radius: 4px;
        width:132px;
        height: 50px;
        line-height: 50px;
        padding: 0;
        transition: .3s ease all;
        cursor: pointer;
        text-decoration: none;
        //border: 1px solid #005f93;
        border: 1px solid @brd-estadao;    
        //color: #005f93;
        color:@cor-estadao;
        display: inline-block;
        outline: 0;
        white-space: nowrap;    
        text-align: center;
        font-weight: bold;
        &:hover,
        &:active{
            // background-color: #005f93;
            background-color:@bg-estadao;
            color: #fff;
            text-decoration: none;
        }
        &.btn-success{
            // background-color: #005f93;
            background-color:@bg-estadao;
            color: #fff;
        }        
        &.-enviar{
            width: 132px;
        }
        &.-more-news{
            width:153px;
        }    	
    }
    .sair-conta{
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        text-align: left;
        color: #808080;    
        display:none;
        margin-bottom:3px;
        margin-top:5px;
        a{
            //color: #005f93;
            color:@cor-estadao;
            text-decoration: underline;        
        }
    }
}

//Overlay do Modal da última newsletter
.overlay-newsletter-ultima{
    padding-top:62px;
    padding-left:10px;
    padding-right:10px;
    @media (min-width: 768px){        
        padding-top:122px;
    }
    // Btn Close
    button{
        @media (max-width: 767px){
            right:6px;
        }
    }
}
//Modal da última newsletter
.newsletter-ultima{
    margin:50px auto 0;
    padding:0 5px 12px; // iphone 5
    @media (min-width: 374px){
        padding-left: 12px;
        padding-right: 12px;
    }
    @media (min-width: 768px){
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 19px;
    }
    table{
        width:auto;
        // Ajuste tabelas com style que contém 100%
        &[width="100%"] {
            width:100%;
        }
        // Ajuste Btns
        &[width="280"] {
            //width:280px;
            max-width:280px;
            width:100%;
        }
        // Removendo Bordas adicionada pelo projeto   
        td{
            border-bottom:none;
        }
    }
    // Contato linha com width fixo ajuste mobile
    .contato{
        @media screen and (max-device-width:374px) {        
            width:100% !important;
        }
    }
}
.news-dias{
    margin: 10px 0;
    object-fit: contain;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}