header {
  z-index: 99;
  position: relative;
}

.home {
  .header-content {
    padding: 30px 0 50px 0;

    .componente-titulo {
      .titulo {
        & > span {
          display: none;
        }
      }
    }

  }
}

.header-content {
  padding: 21px 0;

  .assinaturas {
    justify-content: flex-end;

    label {
      font-size: 14px;
      letter-spacing: -0.1px;
      color: #000000;
      margin-right: 15px;
      display: inline-block;
      vertical-align: middle;
    }

    .select-box {
      display: inline-block;
      vertical-align: middle;
    }

    .input-control {
      margin: 0;
    }
  }

  .componente-titulo {
    .titulo {
      font-family: Lato, Arial, sans-serif;
      font-size: 29px;
      font-weight: 400;
      letter-spacing: -0.2px;
      color: #2575E8;
      margin: 0;

      span {
        font-size: 18px;
        color: #888888;
      }
    }
  }
}

@media (min-width: 1024px ) {
  h1 {
    &.welcome {
      font-size: 29px;
    }
  }
}


/// Mobile
@media @mediaXs {
  .home .header-content {
    padding: 20px 0;
  }

  .header-content {
    .assinaturas {
      margin-top: 20px;
    }
  }
}

@media @mediaMd {
  .header-content, .home .header-content {
    .assinaturas {
      label {
        display: none;
      }

      .select-box {
        width: 100%
      }
    }
  }
}

@media @mediaXs, @mediaSm {
  .header-content, .home .header-content {
    .componente-titulo {
      .titulo {
        font-size: 20px;

        & > span {
          display: block;
          font-size: 18px;
        }
      }
    }
  }
}

@media @mediaXs {
  .header-content {
    .componente-titulo {
      .titulo > span[data-title-mobile] {
        overflow: hidden;
        text-indent: -99999px;

        &:before {
          content: attr(data-title-mobile);
          text-indent: 0;
          float: left;
        }
      }
    }

  }
}
