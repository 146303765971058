.alert-row, .toast {
    width: 100%;
    position: relative;
    transition: all linear 300ms;
    &>a{
        text-decoration: none;
    }
    &:empty {
        height: 0;
    }

    &:not(.show) {
        .alert-mensagem {
            height: 0;
            overflow: hidden;
            padding: 0;
            margin: 0;
        }
    }

    .alert-mensagem {
        &.link{
            cursor: pointer;
        }
        .btn-clique-aqui{
            width: 128px;
            height: 21px;
            border-radius: 2px;
            border: solid 1px #ffffff;
            color:#fff;
            text-align: center;
            font-size: 12px;
            margin-left:20px;
            padding: 4px 6px;
            background: none;
        }
        &.hide {
            height: 0;
            overflow: hidden;
            padding: 0;
            margin: 0;
        }

        width: 100%;
        padding: 14px 40px 14px 14px;
        margin-top: 20px;
        font-size: 14px;
        border-radius: 4px;
        position: relative;
        opacity: 1;
        margin-left: 0;
        transition: all ease 300ms;

        a {
            color: #ffffff;
            font-weight: bold;
        }

        i.right {
            position: absolute;
            right: 10px;
            height: 45px;
            line-height: 45px;
            cursor: pointer;
            font-size: 18px;
            top: 0;
        }

        &[data-color="red"] {
            color: #ffffff;
            background-color: #da5350;
        }

        &[data-color="yellow"] {
            color: #ffffff;
            background-color: #f39d11;
        }

        &[data-color="blue"] {
            color: #ffffff;
            background-color: #00a2ca;
        }

        &[data-color="green"] {
            color: #ffffff;
            background-color: #3aae32;

            i {
                color: #8ee690;
            }
        }

        &.removing {
            overflow: hidden;
            margin-left: -50%;
            opacity: 0;
        }
    }
}
@media (max-width: 767px){

    .alert-mensagem{
        line-height: 25px;
    }

    .btn-clique-aqui{
        border: solid 1px #fff;
        color: #fff;
        text-align: center;
        margin-left: 8px;
        padding: 4px 6px;
        background: none;
    }

}