.visible-tablet-mobile {
    display: none;
}

@media (max-width: 992px) {
    .visible-tablet-mobile {
        display: unset !important;
    }
}

@media @mediaXs, @mediaSm {
    .nopadding-mobile{
      padding:0 !important;
    }
    .ultimas-noticias{
      padding-right:0 !important;
    }

    .inner-content {
        padding-top:56px;
        .header-content {
            .container {
                padding-bottom: 0;
            }
        }

        .welcome-wrapper {
            padding: 0 15px !important;

            h2 {
                margin: 0;
                color: #888;
            }
        }

        .parceiro,
        .eventos {
            margin-bottom: 0;

            h2::before {
                content: attr(title-mobile);
            }
        }

        .parceiro,
        .eventos {
            margin-top: 0;
            padding: 0 15px;

            h2 {
                font-weight: lighter;
            }

            a {
                font-weight: bold;
            }
        }
        
        .slider-mobile {
            display: block;
            padding: 0 15px;
            .scroll-area{
                display: block;
                width: auto !important;
                margin-left: -20px;
                margin-right: -20px;

                .slider-container{
                    padding: 0 20px;
                }
            }

            .track-horizontal {
                display: inline;
            }
        }
    }

    .main-content-wrapper {
        padding-bottom: 4px;
    }

    .large-bottom-margin{
        margin-bottom:65px;
    }
    h1 {
        &.welcome{
            font-size:20px;
        }
    }
    label[for="assinatura-option"]{
        display:none;
    }
    [name="assinatura-selected"]{
        border-radius: 5px;
        padding:8px 30px 8px 8px !important;
        border:solid 1px #9b9b9b;
        background:#f5f5f5;
        font-size:13px !important;
        color: #4a4a4a;
        width:100%;
    }
    .bloco-simples-wrapper{
        margin-bottom:70px;
    }
}
