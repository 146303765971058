/*.select-box{
  select{
    &.default {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance:none;
      display: inline-block;
    }

  }
  &:after{
    font-family: "icones-meu-estadao";
    content: "\e907";
    right: 10px;
    position: absolute;
    top: 22px;
  }
}*/

.data-form{
    .title-mobile{
        display: none;
    }
    .block-single{
        margin-top: 0;
    }
    .salvar-dados{
        margin-top: 30px;
        float: right;
    }
}

.input-control {
    position: relative;
    width: 100%;
    font-family: Lato, Arial, sans-serif;
    margin-bottom: 12px;
    background: #FFFFFF;

    svg {
        position: relative;
        z-index: 99;

        rect {
            width: 100%;
            height: 100%;
        }
    }

    .password-toggle-icon {
        position: absolute;
        right: 12px;
        top: 20px;
        z-index: 9;
        width: 24px;
        height: 18px;
    }

    &.radio, &.checkbox{
        border-radius: 3px;
        border: solid 1px #dedede;
        min-height: 55px;
        position: relative;
        &.readonly{
            background-color: #f9f9f9;
            color: #808080;
        }
        &>input{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            opacity: 0;
            margin: 0;
            cursor: pointer;
            z-index: 2;
        }

        &>i{
            position: absolute;
            left: 0;
            top: 0;
            width: 55px;
            height: 100%;
            border-right: 1px solid #dedede;
            z-index: 1;
            &:before{
                content: "";
                display: inline-block;
                width: 22px;
                height: 22px;
                border: 3px solid #808080;
                position: absolute;
                left: 16px;
                top: calc(50% - 11px);
            }
            &.circle{
                &:before{
                    border-radius: 22px;
                }
            }

            &.square{
                &:before{
                    border-radius: 5px;
                }
            }
        }

        &>label{
            left: 60px;
            text-indent: 0;
            cursor: pointer;
            z-index: 1;
            display: table;
            width: calc(100% - 60px);
            min-height: 52px;
            top: 0;
            position: relative;
            padding: 5px 0;
            span{
                display: table-cell;
                vertical-align: middle;
            }
        }

        input:checked + i{
            &:after{
                content: "";
                display: inline-block;
                width: 12px;
                height: 12px;
                background: #000;
                position: absolute;
                left: 21px;
                top: calc(50% - 6px);
            }
            &.circle{
                &:after{
                    border-radius: 22px;
                }
            }
            &.square{
                &:after{
                    border-radius: 3px;
                }
            }
        }

        &[data-error]{
            border-color: #d81a13;
            margin-bottom: 25px;
            i.circle:before, i.circle:after{
                border-color: #d81a13;
            }
            &:after{
                position: absolute;
                bottom: -16px;
            }
        }
    }
    [type="checkbox"]:focus + label{
        top: 5px;
        color: #808080;
    }
    [type="checkbox"]{
        display:none;
    }

    [type="checkbox"]:checked + label{
        &:after{
            content: "";
            position: absolute;
            left: 5px;
            top: 5px;
            display: inline-block;
            width: 10px;
            height: 10px;
            color: #808080;
            border-radius: 30px;
            background: #000000;
        }
    }
    [type="checkbox"] + label{
        text-align: left;
        font-size: 16px;
        height: 35px;
        padding-left: 50px;
        padding-right: 32px;
        cursor: pointer;
        position: relative;
        color: #808080;
        background:none;
        text-indent: 0em;
        display: table-cell;
        line-height: 15px;
        span{
            height: 55px;
            border-left: solid 1px #dedede;
            top: -19px;
            left: 36px;
            position:absolute;
        }
        &:before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 30px;
            border: 3px solid #808080;
        }
    }
    [type="checkbox"]:focus + label{
        top: inherit;
        color: inherit;
        font-size: inherit;
    }
    @media only screen and (max-width: 389px){

        [type="checkbox"] + label{
            top:10px;
            span {
                top: -12px;
            }
            &:before {
                top: 5px;
            }
        }
        [type="checkbox"]:checked + label {
            &:after {
                top: 10px;
            }
        }
    }

    &.select {
        position: relative;

        &>.inner{
            &:before {
                z-index: -3;
                content: "";
                background: #FFFFFF;
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }

            &:after {
                font-family: "icones-meu-estadao";
                content: "\e907";
                right: 10px;
                font-size: 14px;
                top: 18px;
                position: absolute;
                color: #0c0c0c;
                z-index: 1;
            }
        }

        &:hover {
            select:not([readonly]){
                border: solid 1px #2575E8;
            }
        }

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            cursor: pointer;
            background: transparent;
            font-size: 16px;
            text-align: left;
            color: #808080;
            padding: 18px 25px 18px 15px;
            width: 100%;
            height: 100%;
            border-radius: 3px;
            border: solid 1px #dedede;
            transition: color ease 200ms;
            color: rgba(255, 255, 255, 0);
            z-index: 3;
            position: relative;

            &[readonly] {
                background: #f9f9f9;
                pointer-events: none;
                touch-action: none;
            }

            &::-ms-expand {
                display: none;
            }

            &.no-label {
                padding: 19px 25px 16px 15px !important;
            }

            & + label {
                top: 16px;
                left: 16px;
            }

            &.selected {
                color: #000000 !important;
                text-indent: 0;
                padding: 25px 25px 11px 15px;

                & + label {
                    opacity: 1;
                    outline: none;
                    top: 5px;
                    color: #2575E8;
                    font-size: 14px;
                    pointer-events: none;
                }

                &[readonly] {
                    color: #808080 !important;
                    & + label {
                        color: #808080;
                    }
                }
            }

            option {
                color: #000000;

                &[value=""] {
                    color: #AAAAAA;
                }
            }

            &:focus:not([readonly]){
                border: solid 1px #2575E8;
            }
        }


        .select_list + .content-loader{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &.focus, &.active {
            outline: none;
        }

        .select_list{
            position: absolute;
            left: 0;
            top: 100%;
            //white-space: nowrap;
            z-index: 99999;
            background: #FFFFFF;
            border-radius: 0 0 4px 4px;
            min-width: 100%;
            box-shadow: 2px 2px 10px -3px #000000;
            display: none;
            &[data-position="left"]{
                left: auto;
                right: 0;
            }
            &.show{
                display: inline-block;
            }
            .search{
                padding: 5px;
                i{
                    position: absolute;
                    color: #c3c8cd;
                    font-size: 15px;
                    z-index: 99;
                    line-height: 35px;
                    margin-left: 5px;
                }
                input{
                    padding: 10px 15px 10px 30px;
                    height: 35px;
                    line-height: 35px;
                    font-size: 13px;
                }
            }
            ul.list{
                padding: 5px;
                margin: 0;
                list-style: none;
                max-height: 242px;
                overflow: auto;
                li{
                    text-align: left;
                    font-size: 14px;
                    line-height: 35px;
                    padding-left: 35px;
                    cursor: pointer;
                    position: relative;
                    color: #333333;
                    border-bottom: 1px solid #e6e6e6;
                    &:before{
                        content: "";
                        position: absolute;
                        left: 8px;
                        top: 8px;
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        border-radius: 30px;
                        border: 3px solid #808080;
                    }
                    &.active{
                        background-color: #EEEEEE;
                        &:after{
                            content: "";
                            position: absolute;
                            left: 13px;
                            top: 13px;
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            border-radius: 30px;
                            background: #000000;
                        }
                    }
                    &:hover{
                        background-color: #DDDDDD;
                    }
                }
            }
        }
    }

    input[type='text'], input[type='email'], input[type='password'], input[type='number'], input[type='tel'], input[type='search'], input[type='date'], textarea {
        background-color: #FFFFFF;
        color: #000000;
        border-radius: 3px;
        border: solid 1px #dedede;
        outline: 0;
        width: 100%;
        font-size: 16px;
        height: 55px;
        padding: 25px 15px 10px 15px;
        z-index: 9;
        position: relative;
        transition: 0.2s ease border;
        -webkit-appearance: textfield;
        &.no-label {
            padding: 19px 15px 16px 15px !important;
        }

        &[readonly] {
            background-color: #f9f9f9;
            color: #808080;
        }

        &:focus:not([readonly]){
            border: 1px solid #2575E8;
        }
    }
    input[type='date'] + label{
        outline: none;
        top: 5px;
        color: grey;
        font-size: 14px;
        pointer-events: none;
        border: none;
    }

    textarea{
        height: 200px;
        resize: none;
    }

    label {
        top: 18px;
        left: 15px;
        font-size: 16px;
        text-align: left;
        color: grey;
        position: absolute;
        cursor: text;
        transition: all ease .2s;
        z-index: 9;
    }

    .icon[class*="icon-"]{
        position: absolute;
        right: 0;
        top: 1px;
        height: 53px;
        width: 55px;
        z-index: 9;
        border-left: solid 1px #dedede;
        text-align: center;
        font-size: 24px;
        color: #808080;
        &:before{
            line-height: 55px;
        }
    }

    &.isset input + label,
    input:focus:not([readonly]) + label,
    input:focus:not([type="checkbox"]) + label,
    &.isset textarea + label,
    textarea:focus + label {
        outline: none;
        top: 5px;
        color: #2575E8;
        font-size: 14px;
        pointer-events: none;
        border: none;
    }

    &.isset textarea + label,
    textarea:focus:not([readonly]) + label {
        background: #FFF;
        width: calc(100% - 25px);
        left: 1px;
        top: 1px;
        height: 25px;
        line-height: 25px;
        text-indent: 15px;
    }

    &.isset input[readonly] + label,
    input[readonly]:focus + label,
    &.isset textarea[readonly] + label,
    textarea[readonly]:focus + label {
        color: #808080;
    }

    &[data-error]{
        &:after {
            content: attr(data-error);
            font-size: 12px;
            color: #d81a13;
            white-space: nowrap;
            width: 100%;
            display: inline-block;
        }

        input, select, textarea{
            border-color: #d81a13;
        }
        label{
            color: #d81a13 !important;
        }
    }

    &.file{
        border-radius: 3px;
        border: solid 1px #dedede;
        height: 55px;
        position: relative;

        &:not(.isset) input[type="file"]{
            &:focus + label, &:hover + label{
                top: 18px;
                color: #808080;
                font-size: 16px;
            }
        }

        .value{
            position: absolute;
            top: 25px;
            left: 60px;
            font-size: 15px;
            opacity: 0;
            transition: 1s ease opacity;
            width: calc(100% - 120px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        input[type="file"]{
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
            outline: none;
            opacity: 0;
            z-index: 2;
            left: 0;
            top: 0;
            & + label{
                left: 60px;
                cursor: pointer;
            }
        }

        .archive{
            position: absolute;
            left: 0;
            top: 0;
            width: 54px;
            height: 53px;
            border-right: 1px solid #dedede;
            z-index: 1;
            text-align: center;
            line-height: 53px;
            font-size: 24px;
            color: grey;
        }

        .remove{
            position: absolute;
            right: 0;
            top: 0;
            width: 54px;
            height: 53px;
            border-left: 1px solid #dedede;
            z-index: 3;
            text-align: center;
            line-height: 53px;
            font-size: 24px;
            color: grey;
            cursor: pointer;
            display: none;
        }

        &.isset {
            .value{
                opacity: 1;
            }
            .remove{
                display: inline-block;
            }
        }
    }

}

@media @mediaXs{
    .data-form{
        &>[class*="col-"]{
            padding: 0;
        }
        .block-single{
            border-radius: 0;
        }
        .salvar-dados{
            margin-right: 25px;
        }
    }
}


@media @mediaXs, @mediaSm {
    .data-form{
        .title-mobile{
            display: block;
            padding: 0 20px;
            color: #425573;
            font-size: 18px;
            letter-spacing: -0.1px;
            margin-bottom: 10px;
        }
    }
    .input-control{
        &.select{
            .select_list{
                .search{
                    input{
                        height: 40px;
                        line-height: 40px;
                    }
                }
                ul.list{
                    li{
                        height: auto!important;
                        line-height: normal!important;
                        padding: 9px 5px 16px 35px !important;
                        border-bottom: 1px solid #e6e6e6;
                    }
                }
            }
        }
    }
}


