@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: "Lato", Arial, sans-serif;
}
body * {
  box-sizing: border-box;
}
body .inner-content {
  background: #f5f5f5;
  min-height: 100px;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-bottom: 30px;
}
body .logo {
  width: 211px;
  height: 45px;
  background-image: url("https://statics.estadao.com.br/s2016/portal/logos/logo-estadao-640x70.png");
  background-size: 100%;
  background-repeat: no-repeat;
  text-indent: -9999px;
  overflow: hidden;
  margin-top: 22px;
}
body .copyright {
  font-size: 14px;
  line-height: 1.43;
  color: #000000;
  margin: 0;
}
body .undecore-a {
  text-decoration: none;
  color: inherit;
}
/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
@media (max-width: 576px) {
  .container {
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
  .container .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1168px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 16px;
  padding-left: 16px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
.full-height {
  height: 100%;
}
@media only screen and (max-width: 575px) {
  .hidden-xs {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .hidden-sm {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .hidden-md {
    display: none;
  }
}
@media only screen and (max-width: 1199px) {
  .hidden-lg {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .hidden-xl {
    display: none;
  }
}
#loading-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99999999999;
  display: block;
  top: 0;
  left: 0;
}
#loading-container .loading {
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7) !important;
}
#loading-container .loading .lds-spinner {
  color: #2575E8;
  position: relative;
  top: 49%;
  width: 64px;
  height: 64px;
  margin: 0 auto;
}
#loading-container .loading .lds-spinner div {
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}
#loading-container .loading .lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: #2575E8;
}
#loading-container .loading .lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
#loading-container .loading .lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
#loading-container .loading .lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
#loading-container .loading .lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
#loading-container .loading .lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
#loading-container .loading .lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
#loading-container .loading .lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
#loading-container .loading .lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
#loading-container .loading .lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
#loading-container .loading .lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
#loading-container .loading .lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
#loading-container .loading .lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.jornal-de-hoje {
  white-space: nowrap;
}
.jornal-de-hoje .image {
  width: 120px;
  max-height: 105px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #e8e8e8;
  box-shadow: 2px 0 1px #e8e8e8;
}
.jornal-de-hoje .image figure {
  margin: 0;
}
.jornal-de-hoje .image img {
  max-width: 100%;
}
.jornal-de-hoje .title {
  color: #000000;
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
  text-transform: uppercase;
  height: 34px;
  padding: 0 10px;
  line-height: 34px;
  border: 1px solid #e8e8e8;
  border-radius: 0 4px 4px 0;
}
.jornal-de-hoje.footer {
  margin-top: 22px;
}
.jornal-de-hoje.footer .image {
  max-height: 150px;
  width: 150px;
}
@media (min-width: 801px) {
  .jornal-de-hoje .image {
    width: 95px;
  }
}
@media (min-width: 1025px) {
  .jornal-de-hoje .image {
    width: 120px;
  }
}
.previsao-do-tempo {
  display: inline-block;
  line-height: 15px;
  color: #444444;
  text-decoration: none;
}
.previsao-do-tempo > * {
  display: inline-block;
  vertical-align: middle;
}
.previsao-do-tempo .icon:before {
  font-family: "icones-meu-estadao";
  font-size: 18px;
  vertical-align: middle;
}
.previsao-do-tempo .icon[data-status="1"]:before {
  content: "\E92D";
}
.previsao-do-tempo .icon[data-status^="2"]:before {
  content: "\E928";
}
.previsao-do-tempo .icon[data-status="3"]:before {
  content: "\E92A";
}
.previsao-do-tempo .icon[data-status="4"]:before {
  content: "\E92A";
}
.previsao-do-tempo .icon[data-status="4r"]:before {
  content: "\E92C";
}
.previsao-do-tempo .icon[data-status="4t"]:before {
  content: "\E92A";
}
.previsao-do-tempo .icon[data-status="5"]:before {
  content: "\E92A";
}
.previsao-do-tempo .icon[data-status="6"]:before {
  content: "\E92C";
}
.previsao-do-tempo .icon[data-status="7"]:before {
  content: "\E936";
}
.previsao-do-tempo .icon[data-status^="8"]:before {
  content: "\E936";
}
.previsao-do-tempo .icon[data-status^="9"]:before {
  content: "\E92F";
}
.previsao-do-tempo .maximo {
  margin-right: 2px;
}
.previsao-do-tempo .maximo:before {
  font-family: "icones-meu-estadao";
  content: "\E901";
  font-size: 9px;
  margin-right: 2px;
  vertical-align: middle;
}
.previsao-do-tempo .minimo:before {
  font-family: "icones-meu-estadao";
  content: "\E902";
  font-size: 9px;
  margin-right: 2px;
  vertical-align: middle;
}
header {
  z-index: 99;
  position: relative;
}
.home .header-content {
  padding: 30px 0 50px 0;
}
.home .header-content .componente-titulo .titulo > span {
  display: none;
}
.header-content {
  padding: 21px 0;
}
.header-content .assinaturas {
  justify-content: flex-end;
}
.header-content .assinaturas label {
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #000000;
  margin-right: 15px;
  display: inline-block;
  vertical-align: middle;
}
.header-content .assinaturas .select-box {
  display: inline-block;
  vertical-align: middle;
}
.header-content .assinaturas .input-control {
  margin: 0;
}
.header-content .componente-titulo .titulo {
  font-family: Lato, Arial, sans-serif;
  font-size: 29px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #2575E8;
  margin: 0;
}
.header-content .componente-titulo .titulo span {
  font-size: 18px;
  color: #888888;
}
@media (min-width: 1024px) {
  h1.welcome {
    font-size: 29px;
  }
}
@media only screen and (max-width: 575px) {
  .home .header-content {
    padding: 20px 0;
  }
  .header-content .assinaturas {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .header-content .assinaturas label,
  .home .header-content .assinaturas label {
    display: none;
  }
  .header-content .assinaturas .select-box,
  .home .header-content .assinaturas .select-box {
    width: 100%;
  }
}
@media only screen and (max-width: 575px), only screen and (max-width: 767px) {
  .header-content .componente-titulo .titulo,
  .home .header-content .componente-titulo .titulo {
    font-size: 20px;
  }
  .header-content .componente-titulo .titulo > span,
  .home .header-content .componente-titulo .titulo > span {
    display: block;
    font-size: 18px;
  }
}
@media only screen and (max-width: 575px) {
  .header-content .componente-titulo .titulo > span[data-title-mobile] {
    overflow: hidden;
    text-indent: -99999px;
  }
  .header-content .componente-titulo .titulo > span[data-title-mobile]:before {
    content: attr(data-title-mobile);
    text-indent: 0;
    float: left;
  }
}
.block-cards-body {
  margin-bottom: 30px;
  display: grid;
}
.block-cards-body .header {
  margin-bottom: 15px;
}
.block-cards-body .header .title {
  font-size: 22px;
  letter-spacing: -0.2px;
  color: #535353;
  margin: 0;
  font-weight: normal;
}
.block-cards-body .block {
  width: 100%;
  padding: 11.5px 8px;
  background: #ffffff;
  display: block;
  position: relative;
}
.block-cards-body .block > .lock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
  text-align: center;
  color: #4a4a4a;
  display: inline-block;
}
.block-cards-body .block > .lock:before {
  content: "";
  display: inline-block;
  height: 100%;
  width: 1px;
  vertical-align: middle;
}
.block-cards-body .block > .lock > .center {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 2px);
}
.block-cards-body .block > .lock > .center > i {
  display: block;
  font-size: 30px;
}
.block-cards-body .block > .lock > .center > p {
  font-size: 18px;
  max-width: 240px;
  margin: 20px auto;
}
.block-cards-body .block > .lock > .center a {
  color: #2575E8;
  text-decoration: none;
}
.block-cards-body .block ul {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
}
.block-cards-body .block ul > li:not(.disabled) > a:hover {
  top: -2px;
  right: -2px;
  box-shadow: -2px 2px 5px -2px #000;
}
.block-cards-body .block ul > li > a {
  display: inline-block;
  position: relative;
  top: 0;
  right: 0;
  -webkit-transition: 0.15s ease all;
  transition: 0.15s ease all;
}
.block-cards-body .block ul.links > li {
  float: left;
  width: 33.3333333%;
  display: inline-block;
  padding: 8px;
}
.block-cards-body .block ul.links > li.disabled > a {
  background-color: #fafafa;
  opacity: 0.4;
  cursor: default;
}
.block-cards-body .block ul.links > li > a {
  height: 88px;
  width: 100%;
  border: 1px solid #f5f5f5;
  color: #535353;
}
.block-cards-body .block ul.links > li > a > i {
  font-size: 40px;
  line-height: 88px;
  vertical-align: middle;
  display: inline-block;
  width: 30%;
}
.block-cards-body .block ul.links > li > a span {
  vertical-align: middle;
  display: inline-block;
  font-size: 13px;
  text-align: center;
  word-break: break-word;
  width: 70%;
  padding: 0 11px;
}
.block-cards-body .block ul.news {
  padding: 0 8px;
}
.block-cards-body .block ul.news > li {
  width: 100%;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #f5f5f5;
  min-height: 94px;
}
.block-cards-body .block ul.news > li:last-child > a {
  border-bottom-color: transparent;
}
.block-cards-body .block ul.news > li > a {
  width: 100%;
  padding: 7px 5px;
}
.block-cards-body .block ul.news > li > a > .image {
  width: 54px;
  height: 54px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-cards-body .block ul.news > li > a > .image img {
  display: none;
}
.block-cards-body .block ul.news > li > a > .text {
  display: inline-block;
  width: calc(100% - 60px);
  vertical-align: top;
  padding: 2px 8px;
}
.block-cards-body .block ul.news > li > a > .text > .editoria {
  color: #2575E8;
  font-size: 14px;
  margin-top: 5px;
}
.block-cards-body .block ul.news > li > a > .text > .title {
  margin: 0;
  font-size: 14px;
  color: #535353;
}
.block-cards-body .block ul.tags {
  padding: 8px;
}
.block-cards-body .block ul.tags > li {
  float: left;
}
.block-cards-body .block ul.tags > li > a {
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid #f5f5f5;
  display: inline-block;
  color: #2575E8;
  text-decoration: none;
  margin: 5px;
  border-radius: 4px;
  letter-spacing: -0.4px;
}
@media only screen and (max-width: 575px) {
  .block-cards-body {
    margin-bottom: 30px;
  }
  .block-cards-body .header .title {
    font-size: 16px;
    color: #535353;
    font-weight: 300;
  }
  .block-cards-body .block {
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
    padding: 0;
    display: block;
  }
  .block-cards-body .block ul.links {
    padding: 0;
  }
  .block-cards-body .block ul.links > li {
    padding: 0;
    width: 50%;
  }
}
.alert-row,
.toast {
  width: 100%;
  position: relative;
  -webkit-transition: all linear 300ms;
  transition: all linear 300ms;
}
.alert-row > a,
.toast > a {
  text-decoration: none;
}
.alert-row:empty,
.toast:empty {
  height: 0;
}
.alert-row:not(.show) .alert-mensagem,
.toast:not(.show) .alert-mensagem {
  height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.alert-row .alert-mensagem,
.toast .alert-mensagem {
  width: 100%;
  padding: 14px 40px 14px 14px;
  margin-top: 20px;
  font-size: 14px;
  border-radius: 4px;
  position: relative;
  opacity: 1;
  margin-left: 0;
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
}
.alert-row .alert-mensagem.link,
.toast .alert-mensagem.link {
  cursor: pointer;
}
.alert-row .alert-mensagem .btn-clique-aqui,
.toast .alert-mensagem .btn-clique-aqui {
  width: 128px;
  height: 21px;
  border-radius: 2px;
  border: solid 1px #ffffff;
  color: #fff;
  text-align: center;
  font-size: 12px;
  margin-left: 20px;
  padding: 4px 6px;
  background: none;
}
.alert-row .alert-mensagem.hide,
.toast .alert-mensagem.hide {
  height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.alert-row .alert-mensagem a,
.toast .alert-mensagem a {
  color: #ffffff;
  font-weight: bold;
}
.alert-row .alert-mensagem i.right,
.toast .alert-mensagem i.right {
  position: absolute;
  right: 10px;
  height: 45px;
  line-height: 45px;
  cursor: pointer;
  font-size: 18px;
  top: 0;
}
.alert-row .alert-mensagem[data-color="red"],
.toast .alert-mensagem[data-color="red"] {
  color: #ffffff;
  background-color: #da5350;
}
.alert-row .alert-mensagem[data-color="yellow"],
.toast .alert-mensagem[data-color="yellow"] {
  color: #ffffff;
  background-color: #f39d11;
}
.alert-row .alert-mensagem[data-color="blue"],
.toast .alert-mensagem[data-color="blue"] {
  color: #ffffff;
  background-color: #00a2ca;
}
.alert-row .alert-mensagem[data-color="green"],
.toast .alert-mensagem[data-color="green"] {
  color: #ffffff;
  background-color: #3aae32;
}
.alert-row .alert-mensagem[data-color="green"] i,
.toast .alert-mensagem[data-color="green"] i {
  color: #8ee690;
}
.alert-row .alert-mensagem.removing,
.toast .alert-mensagem.removing {
  overflow: hidden;
  margin-left: -50%;
  opacity: 0;
}
@media (max-width: 767px) {
  .alert-mensagem {
    line-height: 25px;
  }
  .btn-clique-aqui {
    border: solid 1px #fff;
    color: #fff;
    text-align: center;
    margin-left: 8px;
    padding: 4px 6px;
    background: none;
  }
}
.toast-container {
  position: fixed;
  bottom: 10px;
  z-index: 999;
  max-width: 90%;
  width: 100%;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}
@media (min-width: 820px) {
  .toast-container {
    max-width: 800px;
  }
}
/*.select-box{
  select{
    &.default {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance:none;
      display: inline-block;
    }

  }
  &:after{
    font-family: "icones-meu-estadao";
    content: "\e907";
    right: 10px;
    position: absolute;
    top: 22px;
  }
}*/
.data-form .title-mobile {
  display: none;
}
.data-form .block-single {
  margin-top: 0;
}
.data-form .salvar-dados {
  margin-top: 30px;
  float: right;
}
.input-control {
  position: relative;
  width: 100%;
  font-family: Lato, Arial, sans-serif;
  margin-bottom: 12px;
  background: #FFFFFF;
}
.input-control svg {
  position: relative;
  z-index: 99;
}
.input-control svg rect {
  width: 100%;
  height: 100%;
}
.input-control .password-toggle-icon {
  position: absolute;
  right: 12px;
  top: 20px;
  z-index: 9;
  width: 24px;
  height: 18px;
}
.input-control.radio,
.input-control.checkbox {
  border-radius: 3px;
  border: solid 1px #dedede;
  min-height: 55px;
  position: relative;
}
.input-control.radio.readonly,
.input-control.checkbox.readonly {
  background-color: #f9f9f9;
  color: #808080;
}
.input-control.radio > input,
.input-control.checkbox > input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  margin: 0;
  cursor: pointer;
  z-index: 2;
}
.input-control.radio > i,
.input-control.checkbox > i {
  position: absolute;
  left: 0;
  top: 0;
  width: 55px;
  height: 100%;
  border-right: 1px solid #dedede;
  z-index: 1;
}
.input-control.radio > i:before,
.input-control.checkbox > i:before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 3px solid #808080;
  position: absolute;
  left: 16px;
  top: calc(50% - 11px);
}
.input-control.radio > i.circle:before,
.input-control.checkbox > i.circle:before {
  border-radius: 22px;
}
.input-control.radio > i.square:before,
.input-control.checkbox > i.square:before {
  border-radius: 5px;
}
.input-control.radio > label,
.input-control.checkbox > label {
  left: 60px;
  text-indent: 0;
  cursor: pointer;
  z-index: 1;
  display: table;
  width: calc(100% - 60px);
  min-height: 52px;
  top: 0;
  position: relative;
  padding: 5px 0;
}
.input-control.radio > label span,
.input-control.checkbox > label span {
  display: table-cell;
  vertical-align: middle;
}
.input-control.radio input:checked + i:after,
.input-control.checkbox input:checked + i:after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #000;
  position: absolute;
  left: 21px;
  top: calc(50% - 6px);
}
.input-control.radio input:checked + i.circle:after,
.input-control.checkbox input:checked + i.circle:after {
  border-radius: 22px;
}
.input-control.radio input:checked + i.square:after,
.input-control.checkbox input:checked + i.square:after {
  border-radius: 3px;
}
.input-control.radio[data-error],
.input-control.checkbox[data-error] {
  border-color: #d81a13;
  margin-bottom: 25px;
}
.input-control.radio[data-error] i.circle:before,
.input-control.checkbox[data-error] i.circle:before,
.input-control.radio[data-error] i.circle:after,
.input-control.checkbox[data-error] i.circle:after {
  border-color: #d81a13;
}
.input-control.radio[data-error]:after,
.input-control.checkbox[data-error]:after {
  position: absolute;
  bottom: -16px;
}
.input-control [type="checkbox"]:focus + label {
  top: 5px;
  color: #808080;
}
.input-control [type="checkbox"] {
  display: none;
}
.input-control [type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  color: #808080;
  border-radius: 30px;
  background: #000000;
}
.input-control [type="checkbox"] + label {
  text-align: left;
  font-size: 16px;
  height: 35px;
  padding-left: 50px;
  padding-right: 32px;
  cursor: pointer;
  position: relative;
  color: #808080;
  background: none;
  text-indent: 0em;
  display: table-cell;
  line-height: 15px;
}
.input-control [type="checkbox"] + label span {
  height: 55px;
  border-left: solid 1px #dedede;
  top: -19px;
  left: 36px;
  position: absolute;
}
.input-control [type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  border: 3px solid #808080;
}
.input-control [type="checkbox"]:focus + label {
  top: inherit;
  color: inherit;
  font-size: inherit;
}
@media only screen and (max-width: 389px) {
  .input-control [type="checkbox"] + label {
    top: 10px;
  }
  .input-control [type="checkbox"] + label span {
    top: -12px;
  }
  .input-control [type="checkbox"] + label:before {
    top: 5px;
  }
  .input-control [type="checkbox"]:checked + label:after {
    top: 10px;
  }
}
.input-control.select {
  position: relative;
}
.input-control.select > .inner:before {
  z-index: -3;
  content: "";
  background: #FFFFFF;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.input-control.select > .inner:after {
  font-family: "icones-meu-estadao";
  content: "\E907";
  right: 10px;
  font-size: 14px;
  top: 18px;
  position: absolute;
  color: #0c0c0c;
  z-index: 1;
}
.input-control.select:hover select:not([readonly]) {
  border: solid 1px #2575E8;
}
.input-control.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  background: transparent;
  font-size: 16px;
  text-align: left;
  color: #808080;
  padding: 18px 25px 18px 15px;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: solid 1px #dedede;
  -webkit-transition: color ease 200ms;
  transition: color ease 200ms;
  color: rgba(255, 255, 255, 0);
  z-index: 3;
  position: relative;
}
.input-control.select select[readonly] {
  background: #f9f9f9;
  pointer-events: none;
  touch-action: none;
}
.input-control.select select::-ms-expand {
  display: none;
}
.input-control.select select.no-label {
  padding: 19px 25px 16px 15px !important;
}
.input-control.select select + label {
  top: 16px;
  left: 16px;
}
.input-control.select select.selected {
  color: #000000 !important;
  text-indent: 0;
  padding: 25px 25px 11px 15px;
}
.input-control.select select.selected + label {
  opacity: 1;
  outline: none;
  top: 5px;
  color: #2575E8;
  font-size: 14px;
  pointer-events: none;
}
.input-control.select select.selected[readonly] {
  color: #808080 !important;
}
.input-control.select select.selected[readonly] + label {
  color: #808080;
}
.input-control.select select option {
  color: #000000;
}
.input-control.select select option[value=""] {
  color: #AAAAAA;
}
.input-control.select select:focus:not([readonly]) {
  border: solid 1px #2575E8;
}
.input-control.select .select_list + .content-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.input-control.select.focus,
.input-control.select.active {
  outline: none;
}
.input-control.select .select_list {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 99999;
  background: #FFFFFF;
  border-radius: 0 0 4px 4px;
  min-width: 100%;
  box-shadow: 2px 2px 10px -3px #000000;
  display: none;
}
.input-control.select .select_list[data-position="left"] {
  left: auto;
  right: 0;
}
.input-control.select .select_list.show {
  display: inline-block;
}
.input-control.select .select_list .search {
  padding: 5px;
}
.input-control.select .select_list .search i {
  position: absolute;
  color: #c3c8cd;
  font-size: 15px;
  z-index: 99;
  line-height: 35px;
  margin-left: 5px;
}
.input-control.select .select_list .search input {
  padding: 10px 15px 10px 30px;
  height: 35px;
  line-height: 35px;
  font-size: 13px;
}
.input-control.select .select_list ul.list {
  padding: 5px;
  margin: 0;
  list-style: none;
  max-height: 242px;
  overflow: auto;
}
.input-control.select .select_list ul.list li {
  text-align: left;
  font-size: 14px;
  line-height: 35px;
  padding-left: 35px;
  cursor: pointer;
  position: relative;
  color: #333333;
  border-bottom: 1px solid #e6e6e6;
}
.input-control.select .select_list ul.list li:before {
  content: "";
  position: absolute;
  left: 8px;
  top: 8px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  border: 3px solid #808080;
}
.input-control.select .select_list ul.list li.active {
  background-color: #EEEEEE;
}
.input-control.select .select_list ul.list li.active:after {
  content: "";
  position: absolute;
  left: 13px;
  top: 13px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  background: #000000;
}
.input-control.select .select_list ul.list li:hover {
  background-color: #DDDDDD;
}
.input-control input[type='text'],
.input-control input[type='email'],
.input-control input[type='password'],
.input-control input[type='number'],
.input-control input[type='tel'],
.input-control input[type='search'],
.input-control input[type='date'],
.input-control textarea {
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 3px;
  border: solid 1px #dedede;
  outline: 0;
  width: 100%;
  font-size: 16px;
  height: 55px;
  padding: 25px 15px 10px 15px;
  z-index: 9;
  position: relative;
  -webkit-transition: 0.2s ease border;
  transition: 0.2s ease border;
  -webkit-appearance: textfield;
}
.input-control input[type='text'].no-label,
.input-control input[type='email'].no-label,
.input-control input[type='password'].no-label,
.input-control input[type='number'].no-label,
.input-control input[type='tel'].no-label,
.input-control input[type='search'].no-label,
.input-control input[type='date'].no-label,
.input-control textarea.no-label {
  padding: 19px 15px 16px 15px !important;
}
.input-control input[type='text'][readonly],
.input-control input[type='email'][readonly],
.input-control input[type='password'][readonly],
.input-control input[type='number'][readonly],
.input-control input[type='tel'][readonly],
.input-control input[type='search'][readonly],
.input-control input[type='date'][readonly],
.input-control textarea[readonly] {
  background-color: #f9f9f9;
  color: #808080;
}
.input-control input[type='text']:focus:not([readonly]),
.input-control input[type='email']:focus:not([readonly]),
.input-control input[type='password']:focus:not([readonly]),
.input-control input[type='number']:focus:not([readonly]),
.input-control input[type='tel']:focus:not([readonly]),
.input-control input[type='search']:focus:not([readonly]),
.input-control input[type='date']:focus:not([readonly]),
.input-control textarea:focus:not([readonly]) {
  border: 1px solid #2575E8;
}
.input-control input[type='date'] + label {
  outline: none;
  top: 5px;
  color: grey;
  font-size: 14px;
  pointer-events: none;
  border: none;
}
.input-control textarea {
  height: 200px;
  resize: none;
}
.input-control label {
  top: 18px;
  left: 15px;
  font-size: 16px;
  text-align: left;
  color: grey;
  position: absolute;
  cursor: text;
  -webkit-transition: all ease 0.2s;
  transition: all ease 0.2s;
  z-index: 9;
}
.input-control .icon[class*="icon-"] {
  position: absolute;
  right: 0;
  top: 1px;
  height: 53px;
  width: 55px;
  z-index: 9;
  border-left: solid 1px #dedede;
  text-align: center;
  font-size: 24px;
  color: #808080;
}
.input-control .icon[class*="icon-"]:before {
  line-height: 55px;
}
.input-control.isset input + label,
.input-control input:focus:not([readonly]) + label,
.input-control input:focus:not([type="checkbox"]) + label,
.input-control.isset textarea + label,
.input-control textarea:focus + label {
  outline: none;
  top: 5px;
  color: #2575E8;
  font-size: 14px;
  pointer-events: none;
  border: none;
}
.input-control.isset textarea + label,
.input-control textarea:focus:not([readonly]) + label {
  background: #FFF;
  width: calc(100% - 25px);
  left: 1px;
  top: 1px;
  height: 25px;
  line-height: 25px;
  text-indent: 15px;
}
.input-control.isset input[readonly] + label,
.input-control input[readonly]:focus + label,
.input-control.isset textarea[readonly] + label,
.input-control textarea[readonly]:focus + label {
  color: #808080;
}
.input-control[data-error]:after {
  content: attr(data-error);
  font-size: 12px;
  color: #d81a13;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}
.input-control[data-error] input,
.input-control[data-error] select,
.input-control[data-error] textarea {
  border-color: #d81a13;
}
.input-control[data-error] label {
  color: #d81a13 !important;
}
.input-control.file {
  border-radius: 3px;
  border: solid 1px #dedede;
  height: 55px;
  position: relative;
}
.input-control.file:not(.isset) input[type="file"]:focus + label,
.input-control.file:not(.isset) input[type="file"]:hover + label {
  top: 18px;
  color: #808080;
  font-size: 16px;
}
.input-control.file .value {
  position: absolute;
  top: 25px;
  left: 60px;
  font-size: 15px;
  opacity: 0;
  -webkit-transition: 1s ease opacity;
  transition: 1s ease opacity;
  width: calc(100% - 120px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.input-control.file input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  outline: none;
  opacity: 0;
  z-index: 2;
  left: 0;
  top: 0;
}
.input-control.file input[type="file"] + label {
  left: 60px;
  cursor: pointer;
}
.input-control.file .archive {
  position: absolute;
  left: 0;
  top: 0;
  width: 54px;
  height: 53px;
  border-right: 1px solid #dedede;
  z-index: 1;
  text-align: center;
  line-height: 53px;
  font-size: 24px;
  color: grey;
}
.input-control.file .remove {
  position: absolute;
  right: 0;
  top: 0;
  width: 54px;
  height: 53px;
  border-left: 1px solid #dedede;
  z-index: 3;
  text-align: center;
  line-height: 53px;
  font-size: 24px;
  color: grey;
  cursor: pointer;
  display: none;
}
.input-control.file.isset .value {
  opacity: 1;
}
.input-control.file.isset .remove {
  display: inline-block;
}
@media only screen and (max-width: 575px) {
  .data-form > [class*="col-"] {
    padding: 0;
  }
  .data-form .block-single {
    border-radius: 0;
  }
  .data-form .salvar-dados {
    margin-right: 25px;
  }
}
@media only screen and (max-width: 575px), only screen and (max-width: 767px) {
  .data-form .title-mobile {
    display: block;
    padding: 0 20px;
    color: #425573;
    font-size: 18px;
    letter-spacing: -0.1px;
    margin-bottom: 10px;
  }
  .input-control.select .select_list .search input {
    height: 40px;
    line-height: 40px;
  }
  .input-control.select .select_list ul.list li {
    height: auto!important;
    line-height: normal!important;
    padding: 9px 5px 16px 35px !important;
    border-bottom: 1px solid #e6e6e6;
  }
}
/* DayPicker styles */
.DayPicker {
  display: inline-block;
  font-size: 1rem;
}
.DayPicker-wrapper {
  outline: none;
  position: relative;
  flex-direction: row;
  padding-bottom: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #dfe3e9;
  background-color: #ffffff;
}
.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DayPicker-NavButton {
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  display: inline-block;
  margin-top: 2px;
  width: 48px;
  height: 48px;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
  outline: none;
  opacity: 0.5;
}
.DayPicker-NavButton:before {
  content: "";
  display: table;
  margin: 0 auto;
  width: 6px;
  height: 10px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  top: 18px;
}
.DayPicker-NavButton:hover {
  opacity: 1;
}
.DayPicker-NavButton--prev {
  left: 0;
  right: auto;
}
.DayPicker-NavButton--prev:before {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}
.DayPicker-NavButton--next:before {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}
.DayPicker-NavButton--interactionDisabled {
  display: none;
}
.DayPicker-Caption {
  display: table-caption;
  margin: -1em -1em 0 -1em;
  text-align: center;
  border-bottom: 1px solid #dfe3e9;
  padding: 15px 0 15px 0;
}
.DayPicker-Caption > div {
  font-size: 14px;
  color: #354052;
  text-align: center;
}
.DayPicker-Weekdays {
  display: table-header-group;
}
.DayPicker-WeekdaysRow {
  display: table-row;
}
.DayPicker-Weekday {
  display: table-cell;
  padding: 20px 0 15px 0;
  color: #bac4d2;
  text-align: center;
  font-size: 10px;
}
.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}
.DayPicker-Body {
  display: table-row-group;
}
.DayPicker-Week {
  display: table-row;
}
.DayPicker-Day {
  display: table-cell;
  padding: 12px;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  outline: none;
}
.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}
.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}
.DayPicker-Footer {
  padding-top: 0.5em;
}
.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}
/* Default modifiers */
.DayPicker-Day--today {
  color: #0096c5;
  font-weight: 700;
}
.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}
.DayPicker-Day--disabled {
  color: #DCE0E0;
  cursor: default;
  /* background-color: #eff1f1; */
}
/* Example modifiers */
.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}
.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  color: #ffffff;
  z-index: 2;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  z-index: -1;
  background-color: #1a91eb;
  border-radius: 100px;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover:after {
  background-color: #51A0FA;
}
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF;
}
/* DayPickerInput */
.DayPickerInput {
  display: inline-block;
}
.DayPickerInput-OverlayWrapper {
  position: relative;
}
.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
.DayPicker-Day--markGreen:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
  color: green;
  background-color: #eefdee;
}
.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
  background-color: transparent;
  border-radius: 0;
  color: #354052;
  z-index: 1;
}
.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):before {
  content: "";
  position: absolute;
  width: calc(100% + 18px);
  height: 60%;
  display: inline-block;
  left: -9px;
  top: 20%;
  z-index: -1;
  background-color: #f4f4f4;
}
.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):after {
  display: none;
}
@media only screen and (max-width: 450px) {
  .DayPicker .DayPicker-Day {
    padding: 3.5vw;
  }
}
@media only screen and (max-width: 400px) {
  .DayPicker .DayPicker-Day {
    padding: 3vw;
  }
}
@media only screen and (max-width: 350px) {
  .DayPicker .DayPicker-Day {
    padding: 2.5vw;
  }
}
.datepicker p {
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: -0.1px;
  color: #2575e8;
}
.input-control-calendar {
  cursor: pointer;
  position: relative;
}
.input-control-calendar .input-control {
  cursor: pointer;
}
.input-control-calendar .input-control input[readonly] {
  background: #FFFFFF;
  color: #000000;
  cursor: pointer;
}
.input-control-calendar .input-control label {
  cursor: pointer;
}
.input-control-calendar .input-control input[readonly]:focus {
  border-color: #2575E8;
}
.input-control-calendar .input-control:not(.isset) input[readonly]:focus + label {
  top: 18px;
  font-size: 16px;
}
.input-control-calendar .input-control.isset input + label {
  color: #2575E8;
}
.slider {
  margin-bottom: 20px;
  width: 100%;
  height: 260px;
}
.slider h2 {
  font-size: 22px;
  font-weight: normal;
  letter-spacing: -0.2px;
  color: #535353;
}
.slider h2::before {
  content: attr(title);
}
.slider a {
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #2575E8;
  text-decoration: none;
  display: block;
}
.slider a .slider-container:first-child {
  margin-left: 0;
}
.slider .slider-container {
  margin: 0;
  width: auto;
  white-space: nowrap;
}
.slider .slider-container .slide-item {
  margin-left: 33px;
  width: 207px;
  display: inline-block;
  justify-content: center;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.slider .slider-container .slide-item:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
}
.slider .slider-container .slide-item:first-child {
  margin-left: 0;
}
@media only screen and (max-width: 991px) {
  .slider .slider-container .slide-item:last-child {
    margin-right: 10px;
  }
}
.slider .track-horizontal {
  background-color: #ced4da;
  width: 100%;
  left: 0px;
  bottom: 0px;
  height: 10px !important;
  display: none;
}
@media (max-width: 768px) {
  .slider h2 {
    font-size: 16px;
  }
}
@media (min-width: 801px) {
  .slider h2 {
    font-size: 22px;
  }
}
.newsletter-wrapper .status-block {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 22px 14px;
}
.newsletter-wrapper .status-block h3 {
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: -0.5px;
  color: #2575e8;
}
.newsletter-wrapper .status-block .line {
  display: inline-flex;
  width: 100%;
  font-size: 15px;
  letter-spacing: -0.5px;
  margin-top: 20px;
}
.newsletter-wrapper .status-block .line.inscritos {
  color: #417505;
}
.newsletter-wrapper .status-block .line.total {
  color: #2575E8;
}
.newsletter-wrapper .status-block .label {
  padding: 0;
}
.newsletter-wrapper .status-block .value {
  font-size: 13px;
  text-align: center;
  color: #2575e8;
}
.newsletter-wrapper .status-block .base {
  height: 20px;
  text-align: center;
  border-radius: 3px;
  background-color: #e9ecef;
}
.newsletter-wrapper .alterar-email-news {
  margin-bottom: 12px;
}
.newsletter-wrapper .alterar-email-news p {
  width: auto;
  height: 63px;
  margin: 0 0 10px;
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #3d5170;
}
.newsletter-wrapper .alterar-email-news .alterar-email-btn {
  width: 99.6%;
  height: 30%;
  margin: 10px 0 0;
  padding: 12px 69px 11px;
  border-radius: 5px;
  background-color: #2575e8;
  border: #2575e7;
  color: #ffffff;
  font-family: Lato, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: -0.44px;
}
.newsletter-wrapper .blocks-content {
  border-radius: 4px;
  background-color: #ffffff;
  display: table-cell;
}
.newsletter-wrapper .blocks-content h2 {
  font-size: 16px;
  font-weight: bold;
  padding: 0 16px;
  color: #000000;
}
.newsletter-wrapper .checked-layer {
  display: none;
}
.newsletter-wrapper .alert-mensagem {
  width: 100%;
}
.newsletter-wrapper .newsletter-desktop {
  display: none;
}
.newsletter-wrapper .newsletter-items .newsletter-title {
  display: none;
}
.newsletter-wrapper .newsletter-head-mobile {
  padding: 0;
}
.newsletter-wrapper .newsletter-head-mobile h3 {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #425573;
}
.newsletter-wrapper .newsletter-head-mobile p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5d5d5d;
}
.newsletter-wrapper .newsletter-head-mobile p.box {
  border-radius: 5px;
  border: solid 1px #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: center;
  color: #4a4a4a;
  padding: 8px 0;
}
.newsletter-wrapper .newsletter-desktop {
  display: block;
}
.newsletter-wrapper .newsletter-head-mobile {
  display: none;
}
.newsletter-wrapper .item-acordian-bg i {
  display: none;
}
.newsletter-wrapper .alterar-email-btn {
  width: 99.6%;
  height: 37px;
  padding: 7px 95px 8px;
  border: #2575e8;
  border-radius: 5px;
  background-color: #2575e8;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
}
.newsletter-wrapper .newsletter-items {
  border-radius: 4px;
  border: solid 1px #e9ecef;
  background-color: #ffffff;
  padding: 11px 9px;
  margin: 0;
  display: inline-block;
  width: 100%;
}
.newsletter-wrapper .newsletter-items .newsletter-title {
  display: inline-flex;
  width: 100%;
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}
.newsletter-wrapper .newsletter-items .newsletter-lgpd {
  margin-left: 10px;
}
.newsletter-wrapper .newsletter-items .newsletter-lgpd p {
  padding: 0;
  margin-top: 0;
}
.newsletter-wrapper .newsletter-item {
  float: left;
  width: 33.33%;
  padding: 9px;
  position: relative;
  display: block;
}
.newsletter-wrapper .newsletter-item .inner {
  min-height: 406px;
  border: solid 1px #cecece;
}
.newsletter-wrapper .newsletter-item .item-acordian {
  position: relative;
}
.newsletter-wrapper .newsletter-item .newsletter-content {
  padding: 14px 16px;
}
.newsletter-wrapper .newsletter-item .newsletter-content .title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
}
.newsletter-wrapper .newsletter-item .newsletter-content .title i {
  display: none;
}
.newsletter-wrapper .newsletter-item img {
  width: 100%;
  vertical-align: top;
}
.newsletter-wrapper .newsletter-item img.img-clean {
  width: auto;
}
.newsletter-wrapper .newsletter-item .newsletter-title {
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 9px;
}
.newsletter-wrapper .newsletter-item .texto {
  margin-bottom: 14px;
}
.newsletter-wrapper .newsletter-item .texto,
.newsletter-wrapper .newsletter-item .info-periodo {
  font-family: Lato;
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.29;
  color: #5d5d5d;
}
.newsletter-wrapper .newsletter-item[data-checked="true"] .checked-layer {
  background-color: rgba(37, 117, 232, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  margin-left: 0;
  text-align: center;
  padding: 20% 0 0 0;
  display: inline-block;
}
.newsletter-wrapper .newsletter-item[data-checked="true"] .checked-layer i {
  margin: auto;
  color: #fff;
  font-size: 40px;
}
.newsletter-wrapper .newsletter-item .row-subscribe {
  position: absolute;
  bottom: 32px;
  left: 16px;
  width: 100%;
}
.newsletter-wrapper .newsletter-item .assinante {
  font-size: 14px;
  color: #5d5d5d;
  margin-bottom: 14px;
}
.newsletter-wrapper .newsletter-item .assinante i {
  color: #000;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
}
.newsletter-wrapper .newsletter-item .assinante i.icon-lock-open-alt {
  color: #5d5d5d;
  margin-left: 3px;
  width: 19px;
  height: 19px;
  line-height: 18px;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  border: 1px solid #5d5d5d;
  border-radius: 50px;
}
.newsletter-wrapper .newsletter-item .btninscricao,
.newsletter-wrapper .newsletter-item .btninscricaom {
  width: 82%;
  text-transform: uppercase;
}
.newsletter-wrapper .newsletter-item .btninscricaoa {
  margin: 0 auto;
  width: 95%;
}
.newsletter-wrapper .newsletter-item .btninscricaom {
  width: 100%;
}
.newsletter-wrapper .newsletter-item .btninscricaom.true {
  color: #fff;
  background: #2575E8;
}
.newsletter-wrapper .newsletter-item .gratis30 {
  font-weight: normal;
  font-size: 14px;
  padding-bottom: 5px;
}
.newsletter-wrapper .newsletter-item .button-wrapper {
  position: absolute;
  bottom: 30px;
  width: 100%;
  left: 0;
  z-index: 1;
  text-align: center;
}
.newsletter-wrapper .newsletter-item .button-wrapper button.subscribe {
  border-radius: 4px;
  border: solid 1px #2575E8;
  background-color: #ffffff;
  padding: 0 24px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #2575E8;
  cursor: pointer;
  display: table;
}
.newsletter-wrapper .newsletter-item .button-wrapper button.subscribe:active {
  background: #e8e8e8;
}
.newsletter-wrapper .newsletter-item .button-wrapper button.subscribe.true {
  background-color: #2575E8;
  color: #fff;
}
.newsletter-wrapper .newsletter-item .button-wrapper button.subscribe.true:active {
  background: #0076b0;
}
@media only screen and (max-width: 767px) {
  .newsletter-wrapper .newsletter-container {
    background: #FFFFFF;
    margin-top: 40px;
  }
  .newsletter-wrapper .alterar-email-news {
    display: none;
  }
  .newsletter-wrapper .newsletter-status .newsletter-desktop {
    display: none;
  }
  .newsletter-wrapper .newsletter-status .newsletter-head-mobile {
    padding: 0;
    display: inline-block;
    width: 100%;
    margin-top: -50px;
  }
  .newsletter-wrapper .newsletter-status .newsletter-head-mobile h3 {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #425573;
  }
  .newsletter-wrapper .newsletter-status .newsletter-head-mobile p {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #5d5d5d;
    padding-top: 4px;
  }
  .newsletter-wrapper .newsletter-status .newsletter-head-mobile p.box {
    border-radius: 5px;
    border: solid 1px #4a4a4a;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    text-align: center;
    color: #4a4a4a;
    padding: 8px 0;
  }
  .newsletter-wrapper .newsletter-items {
    padding: 0;
    border: 0;
  }
  .newsletter-wrapper .newsletter-items .newsletter-title {
    display: none;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item {
    width: 100%;
    padding: 0;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .inner {
    height: 80px;
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
    min-height: auto;
    overflow: hidden;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .inner .item-acordian {
    height: 80px;
    overflow: hidden;
    z-index: 2;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .inner .item-acordian:before {
    content: "";
    box-shadow: inset 0 -50px 30px -30px #000000;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .inner .item-acordian .item-acordian-bg > i {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 50px;
    z-index: 3;
    color: #FFFFFF;
    font-size: 16px;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .inner .item-acordian .item-acordian-bg .checked-layer .img-clean {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .inner .newsletter-content .title {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    height: 75px;
    width: 100%;
    padding: 0 15px;
    color: #FFFFFF;
    font-weight: 900;
    font-size: 18px;
    line-height: 1.22;
    text-transform: uppercase;
    letter-spacing: normal;
    display: table;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .inner .newsletter-content .title span {
    display: table-cell;
    vertical-align: bottom;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .inner .newsletter-content .title .gratis30t {
    display: table-footer-group;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .inner .newsletter-content .title i {
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .inner .newsletter-content .title i.icon-lock-open-alt {
    margin-left: 3px;
    width: 19px;
    height: 19px;
    line-height: 18px;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 50px;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item.active .inner {
    height: 385px;
  }
  .newsletter-wrapper .newsletter-items .newsletter-item .btninscricao {
    width: 95%;
  }
}
@media only screen and (max-width: 767px) and (max-width: 360px) {
  .newsletter-wrapper .newsletter-items .newsletter-item .inner .newsletter-content .title {
    width: 280px;
  }
}
@media (max-width: 767px) {
  .newsletter-wrapper .titulo-header {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .newsletter-wrapper .titulo-header-mobile {
    display: none;
  }
}
.newsletter-wrapper .status-inscrever {
  font-size: 18px;
  line-height: 1.22;
  color: #3d5170;
}
.newsletter-wrapper .header-content .logo {
  width: 175px;
  height: 38px;
  display: block;
  float: left;
  margin-top: 0px;
}
.newsletter-wrapper .header-content .titulo-topo {
  display: block;
  float: left;
  font-size: 29px;
  line-height: 1.21;
  color: #2575e7;
  margin-top: 8px;
  margin-left: 125px;
}
@media (max-width: 767px) {
  .newsletter-wrapper .header-content .titulo-topo {
    display: none;
  }
}
.newsletter-wrapper .titulo {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.15px;
  color: #2575e8;
  margin-top: 14px;
}
.newsletter-wrapper .ver-ultima svg {
  margin-left: 7px;
}
.newsletter-wrapper .btn-ver-ultima {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.21;
  letter-spacing: -0.44px;
  padding: 0;
  color: #2575e8;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.newsletter-wrapper .box-inscricao {
  position: absolute;
  width: 380px;
  min-height: 226px;
  top: 270px;
  left: -45px;
  padding: 23px;
  z-index: 2;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  text-align: center;
}
@media (max-width: 767px) {
  .newsletter-wrapper .box-inscricao {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: none;
    border: none;
    height: auto;
    padding: 34px 0 0 0;
  }
}
.newsletter-wrapper .box-inscricao .bt-news {
  margin: 12px 0 0 0;
}
.newsletter-wrapper .inscricao-info {
  font-size: 14px;
  font-weight: 900;
  line-height: 1.21;
  text-align: left;
  text-transform: uppercase;
  margin: 30px 0 10px 0;
}
@media (max-width: 767px) {
  .newsletter-wrapper .inscricao-info {
    display: none;
  }
}
.newsletter-wrapper .inscricao-close {
  position: absolute;
  top: 14px;
  right: 18px;
  border: none;
  padding: 0;
  background-color: transparent;
  display: flex;
  cursor: pointer;
}
@media (max-width: 767px) {
  .newsletter-wrapper .inscricao-close {
    display: none;
  }
}
.newsletter-wrapper .opaque-item {
  opacity: 0.5;
}
.newsletter-wrapper .alterar-email {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  color: #2575e8;
  text-transform: uppercase;
  margin-bottom: 2px;
  background: none;
  border: none;
  cursor: pointer;
}
@media (max-width: 767px) {
  .newsletter-wrapper .newsletter-anonimo .button-wrapper {
    display: none;
  }
}
.newsletter-wrapper .subscribe-anonimo {
  border-radius: 4px;
  border: none;
  background-color: #fff;
  padding: 0 24px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
  color: #2575E8;
  border: solid 1px #2575E8;
  cursor: pointer;
  display: table;
}
.form-newsletter .form-news {
  width: 100%;
  /* HACK BG/Color :disabled modifica a cor e bg do fundo do input quando esta no estadao disabled */
  /* HACK BG modifica o bg o input quando tem autocomplete */
  /* HACK Color quando seleciona autocomplete e não é valido */
}
.form-newsletter .form-news .form-field {
  width: 100%;
  position: relative;
  /*Quando o input esta com onblur*/
}
@media screen and (min-width: 768px) {
  .form-newsletter .form-news .form-field {
    flex: 1 1;
    max-width: 430px;
  }
}
.form-newsletter .form-news .form-field[data-error]:after {
  content: attr(data-error);
  color: #d71a12;
  text-align: left;
  display: block;
  font-size: 12px;
  line-height: 1.33;
  margin-top: 5px;
  margin-bottom: 3px;
}
.form-newsletter .form-news .form-field[data-error] input {
  border-color: #e02020;
  color: #e02020;
}
.form-newsletter .form-news .form-field[data-error] input:focus,
.form-newsletter .form-news .form-field[data-error] input:valid {
  border-color: #e02020;
}
.form-newsletter .form-news .form-field[data-error] input:focus + .field-label-floating,
.form-newsletter .form-news .form-field[data-error] input:valid + .field-label-floating {
  color: #e02020;
}
.form-newsletter .form-news .form-field[data-error].isset .field-label-floating {
  color: #e02020;
}
.form-newsletter .form-news .form-field[data-error] .field-label-floating {
  color: #e02020;
}
.form-newsletter .form-news .form-field input {
  width: 100%;
  height: 55px;
  border-radius: 3px;
  border: 1px solid #dedede;
  padding: 0 15px;
  font-size: 16px;
  background: #fff;
}
.form-newsletter .form-news .form-field input:focus {
  padding-top: 18px;
  outline: none;
  border-color: #2575E8;
}
.form-newsletter .form-news .form-field input:focus + .field-label-floating {
  font-size: 14px;
  top: 8px;
  color: #2575E8;
}
.form-newsletter .form-news .form-field input + .field-label-floating {
  font-size: 16px;
  color: #808080;
  position: absolute;
  top: 18px;
  left: 15px;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
.form-newsletter .form-news .form-field input:disabled {
  background-color: rgba(83, 83, 83, 0.15);
  border-color: #e5e5e5;
  color: #808080;
  -webkit-text-fill-color: #808080;
  -webkit-opacity: 1;
  /* required on iOS */
  opacity: 1;
  /* required on iOS */
}
.form-newsletter .form-news .form-field input:disabled + .field-label-floating {
  color: #808080;
}
.form-newsletter .form-news .form-field.isset input {
  padding-top: 18px;
  outline: none;
}
.form-newsletter .form-news .form-field.isset input + .field-label-floating {
  font-size: 14px;
  top: 8px;
  color: #2575E8;
}
.form-newsletter .form-news .form-field.isset input:disabled + .field-label-floating {
  color: #808080;
}
.form-newsletter .form-news .form-field.isset-onblur input {
  border-color: #979797;
}
.form-newsletter .form-news .bt-news.-inscrever {
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .form-newsletter .form-news .bt-news.-inscrever {
    margin-top: 2px;
    margin-left: 12px;
    margin-right: 0;
  }
}
.form-newsletter .form-news input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  border-radius: 0;
}
.form-newsletter .form-news input:disabled:-webkit-autofill,
.form-newsletter .form-news input:disabled:-webkit-autofill:hover,
.form-newsletter .form-news input:disabled:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #e5e5e5 inset !important;
  -webkit-text-fill-color: #808080 !important;
}
.form-newsletter .form-news input:-webkit-autofill,
.form-newsletter .form-news input:-webkit-autofill:hover,
.form-newsletter .form-news input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}
.form-newsletter .form-news .form-field[data-error] input:-internal-autofill-selected {
  -webkit-text-fill-color: #e02020 !important;
}
.form-newsletter .group-field {
  display: flex;
  flex-wrap: wrap;
  -moz-flex-direction: row;
  flex-direction: row;
}
@media screen and (min-width: 768px) {
  .form-newsletter .group-field {
    flex-wrap: nowrap;
  }
}
.form-newsletter .form-news-label,
.form-newsletter .news-title {
  font-size: 16px;
  line-height: 1.13;
  float: none;
  color: #000;
  display: block;
}
.form-newsletter .form-news-label {
  margin: 0 0 8px 0;
}
.form-newsletter .news-title {
  margin: 0 0 10px 0;
}
.form-newsletter .bt-news {
  background-color: #fff;
  font-size: 14px;
  border-radius: 4px;
  width: 132px;
  height: 50px;
  line-height: 50px;
  padding: 0;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #2575E8;
  color: #2575E8;
  display: inline-block;
  outline: 0;
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
}
.form-newsletter .bt-news:hover,
.form-newsletter .bt-news:active {
  background-color: #2575E8;
  color: #fff;
  text-decoration: none;
}
.form-newsletter .bt-news.btn-success {
  background-color: #2575E8;
  color: #fff;
}
.form-newsletter .bt-news.-enviar {
  width: 132px;
}
.form-newsletter .bt-news.-more-news {
  width: 153px;
}
.form-newsletter .sair-conta {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  text-align: left;
  color: #808080;
  display: none;
  margin-bottom: 3px;
  margin-top: 5px;
}
.form-newsletter .sair-conta a {
  color: #2575E8;
  text-decoration: underline;
}
.overlay-newsletter-ultima {
  padding-top: 62px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  .overlay-newsletter-ultima {
    padding-top: 122px;
  }
}
@media (max-width: 767px) {
  .overlay-newsletter-ultima button {
    right: 6px;
  }
}
.newsletter-ultima {
  margin: 50px auto 0;
  padding: 0 5px 12px;
}
@media (min-width: 374px) {
  .newsletter-ultima {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 768px) {
  .newsletter-ultima {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 19px;
  }
}
.newsletter-ultima table {
  width: auto;
}
.newsletter-ultima table[width="100%"] {
  width: 100%;
}
.newsletter-ultima table[width="280"] {
  max-width: 280px;
  width: 100%;
}
.newsletter-ultima table td {
  border-bottom: none;
}
@media screen and (max-device-width: 374px) {
  .newsletter-ultima .contato {
    width: 100% !important;
  }
}
.news-dias {
  margin: 10px 0;
  object-fit: contain;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.modal-inscricao {
  padding: 32px;
}
@media (max-width: 767px) {
  .modal-inscricao {
    padding: 16px;
  }
}
.modal-inscricao > button {
  right: 26px;
  top: 28px;
}
@media (max-width: 767px) {
  .modal-inscricao > button {
    right: 11px;
    top: 12px;
  }
}
.content-news {
  width: 100%;
}
.content-news .logo {
  width: 88px;
  height: 19px;
  margin-top: 0px;
}
.content-news img {
  border: 0;
  display: inline-block;
  max-width: 100%;
}
.content-news .header-news {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 18.6px;
  margin-bottom: 20px;
  padding: 8px;
  background-color: rgba(0, 95, 147, 0.1);
  position: relative;
}
@media screen and (min-width: 768px) {
  .content-news .header-news {
    flex-wrap: nowrap;
    padding: 16px;
  }
}
.content-news .header-news-info {
  position: relative;
}
@media (max-width: 767px) {
  .content-news .header-news-info {
    width: 100%;
    height: 71px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    display: flex;
  }
  .content-news .header-news-info:before {
    content: "";
    box-shadow: inset 0 -50px 30px -30px #000;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content-news .header-news-info .title-figure {
  display: none;
}
@media (max-width: 767px) {
  .content-news .header-news-info .title-figure {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 8px;
    color: #fff;
  }
}
.content-news .header-news-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.22;
  margin: 0 0 8px 0;
  text-transform: uppercase;
  float: none;
  color: #000;
}
@media (max-width: 767px) {
  .content-news .header-news-title {
    display: none;
  }
}
.content-news .header-news-desc {
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.38;
  margin: 0;
}
.content-news .header-news-excl {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.21;
  color: #000;
  margin-top: 8px;
}
.content-news .header-news-excl .icon-round-lock {
  font-size: 16px;
}
.content-news .header-news-excl .icon-lock-open-alt {
  border: 1px solid #000;
  border-radius: 50px;
  font-size: 12px;
  margin-left: 4px;
}
.content-news .header-news-figure {
  overflow: hidden;
  margin-bottom: 10.5px;
  position: relative;
}
.content-news .header-news-figure .title-figure {
  display: none;
}
@media screen and (min-width: 768px) {
  .content-news .header-news-figure {
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    max-width: 246px;
  }
}
@media (max-width: 767px) {
  .content-news .header-news-figure {
    width: 100%;
    height: 71px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
  }
  .content-news .header-news-figure:before {
    content: "";
    box-shadow: inset 0 -50px 30px -30px #000;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .content-news .header-news-figure img {
    width: 100%;
  }
}
.content-news .header-news-box {
  flex: 1 1;
}
@media (max-width: 767px) {
  .content-news .header-news-box {
    margin-top: 5px;
  }
}
.content-news .main-news {
  display: block;
  width: 100%;
  /*assinante*/
  /*fechadas para os assinante*/
}
.content-news .main-news .mold-form-news {
  display: block;
}
.content-news .main-news .mold-news-btns {
  display: none;
}
.content-news .main-news.-subscriber.-exclusiveSubscriber .mold-form-news {
  display: block;
}
.content-news .main-news.-subscriber.-exclusiveSubscriber .mold-news-btns {
  display: none;
}
.content-news .main-news.-exclusiveSubscriber.-logged .news-link {
  display: none;
}
.content-news .main-news.-exclusiveSubscriber .mold-form-news {
  display: none;
}
.content-news .main-news.-exclusiveSubscriber .mold-news-btns {
  display: block;
}
.content-news .main-news.-sairConta .sair-conta {
  display: block;
}
.content-news .main-news.-buttonActive .-more-news {
  display: block;
}
.content-news .main-news .bt-news.-more-news {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .content-news .main-news .bt-news.-more-news {
    margin-top: 50px;
  }
}
.content-news .-more-news {
  display: none;
}
.content-news .news-btns {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  -moz-flex-direction: column;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .content-news .news-btns {
    -moz-flex-direction: row;
    flex-direction: row;
    webkit-box-pack: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    flex-wrap: nowrap;
  }
}
@media (max-width: 767px) {
  .content-news .news-btns {
    text-align: center;
  }
}
.content-news .news-url-selo {
  display: block;
}
.content-news .news-url-selo .news-img-selo {
  width: auto;
  max-width: 100%;
  display: block;
}
.content-news .news-link {
  font-size: 16px;
  line-height: normal;
  color: #2575E8;
  margin-top: 35px;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .content-news .news-link {
    margin: 0;
  }
}
.content-news .news-link span,
.content-news .news-link svg {
  display: inline-block;
  vertical-align: middle;
}
.content-news .news-link svg {
  margin-left: 5px;
}
.content-news .lgpd-news-modal {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  text-align: left;
  color: #808080;
}
.content-news .lgpd-news-modal > a {
  color: #2575E8;
}
.content-news .assinaturas .btn {
  min-width: auto;
  display: inline-block;
}
#faq-page .header-content > .row,
#tutoriais-page .header-content > .row {
  align-items: center;
}
#faq-page .header-content h2,
#tutoriais-page .header-content h2 {
  color: #2575E8;
  font-size: 1.8125rem;
}
#faq-page .header-content .assinaturas-wrapper,
#tutoriais-page .header-content .assinaturas-wrapper {
  padding-right: 2rem;
}
#faq-page .main-content,
#tutoriais-page .main-content {
  margin-bottom: 3.75rem;
}
#faq-page .main-content .accordion,
#tutoriais-page .main-content .accordion {
  margin: 0 auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  border-radius: 0.25rem;
  background-color: #fff;
}
#faq-page .main-content .card .card-header,
#tutoriais-page .main-content .card .card-header,
#faq-page .main-content .card .card-body .list-group-item a,
#tutoriais-page .main-content .card .card-body .list-group-item a,
#faq-page .main-content .card .card-body .article-content,
#tutoriais-page .main-content .card .card-body .article-content {
  padding: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.875rem;
}
#faq-page .main-content .card .card-header,
#tutoriais-page .main-content .card .card-header {
  color: #fff;
  background-color: #2575E8;
}
#faq-page .main-content .card .card-header h3,
#tutoriais-page .main-content .card .card-header h3,
#faq-page .main-content .card .card-header span,
#tutoriais-page .main-content .card .card-header span {
  margin: 0;
  width: 100%;
  display: flex;
  position: relative;
  font-weight: normal;
  font-size: 12px;
}
#faq-page .main-content .card .card-header h3 .ic-sort,
#tutoriais-page .main-content .card .card-header h3 .ic-sort,
#faq-page .main-content .card .card-header span .ic-sort,
#tutoriais-page .main-content .card .card-header span .ic-sort {
  right: 1.25rem;
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  height: 10px;
  top: -3px;
}
@media only screen and (max-width: 991px) {
  #faq-page .main-content .card .card-header h3 .ic-sort,
  #tutoriais-page .main-content .card .card-header h3 .ic-sort,
  #faq-page .main-content .card .card-header span .ic-sort,
  #tutoriais-page .main-content .card .card-header span .ic-sort {
    right: -15px;
  }
}
@media only screen and (max-width: 575px) {
  #faq-page .main-content .card .card-header h3 .ic-sort,
  #tutoriais-page .main-content .card .card-header h3 .ic-sort,
  #faq-page .main-content .card .card-header span .ic-sort,
  #tutoriais-page .main-content .card .card-header span .ic-sort {
    right: -25px;
  }
}
#faq-page .main-content .card .card-header h3 .ic-sort.ic-sort-desc,
#tutoriais-page .main-content .card .card-header h3 .ic-sort.ic-sort-desc,
#faq-page .main-content .card .card-header span .ic-sort.ic-sort-desc,
#tutoriais-page .main-content .card .card-header span .ic-sort.ic-sort-desc {
  top: 7px;
}
#faq-page .main-content .card .card-header h3 .ic-sort:active,
#tutoriais-page .main-content .card .card-header h3 .ic-sort:active,
#faq-page .main-content .card .card-header span .ic-sort:active,
#tutoriais-page .main-content .card .card-header span .ic-sort:active,
#faq-page .main-content .card .card-header h3 .ic-sort:hover,
#tutoriais-page .main-content .card .card-header h3 .ic-sort:hover,
#faq-page .main-content .card .card-header span .ic-sort:hover,
#tutoriais-page .main-content .card .card-header span .ic-sort:hover,
#faq-page .main-content .card .card-header h3 .ic-sort:focus,
#tutoriais-page .main-content .card .card-header h3 .ic-sort:focus,
#faq-page .main-content .card .card-header span .ic-sort:focus,
#tutoriais-page .main-content .card .card-header span .ic-sort:focus,
#faq-page .main-content .card .card-header h3 .ic-sort:visited,
#tutoriais-page .main-content .card .card-header h3 .ic-sort:visited,
#faq-page .main-content .card .card-header span .ic-sort:visited,
#tutoriais-page .main-content .card .card-header span .ic-sort:visited {
  outline: none;
}
#faq-page .main-content .card .card-header h3 .ic-sort .icon-caret-up,
#tutoriais-page .main-content .card .card-header h3 .ic-sort .icon-caret-up,
#faq-page .main-content .card .card-header span .ic-sort .icon-caret-up,
#tutoriais-page .main-content .card .card-header span .ic-sort .icon-caret-up,
#faq-page .main-content .card .card-header h3 .ic-sort .icon-caret-down,
#tutoriais-page .main-content .card .card-header h3 .ic-sort .icon-caret-down,
#faq-page .main-content .card .card-header span .ic-sort .icon-caret-down,
#tutoriais-page .main-content .card .card-header span .ic-sort .icon-caret-down {
  top: -4px;
  font-size: 12px;
  position: relative;
  color: #fff;
  opacity: 0.4;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
#faq-page .main-content .card .card-header h3 .ic-sort .icon-caret-up.active,
#tutoriais-page .main-content .card .card-header h3 .ic-sort .icon-caret-up.active,
#faq-page .main-content .card .card-header span .ic-sort .icon-caret-up.active,
#tutoriais-page .main-content .card .card-header span .ic-sort .icon-caret-up.active,
#faq-page .main-content .card .card-header h3 .ic-sort .icon-caret-down.active,
#tutoriais-page .main-content .card .card-header h3 .ic-sort .icon-caret-down.active,
#faq-page .main-content .card .card-header span .ic-sort .icon-caret-down.active,
#tutoriais-page .main-content .card .card-header span .ic-sort .icon-caret-down.active,
#faq-page .main-content .card .card-header h3 .ic-sort .icon-caret-up:hover,
#tutoriais-page .main-content .card .card-header h3 .ic-sort .icon-caret-up:hover,
#faq-page .main-content .card .card-header span .ic-sort .icon-caret-up:hover,
#tutoriais-page .main-content .card .card-header span .ic-sort .icon-caret-up:hover,
#faq-page .main-content .card .card-header h3 .ic-sort .icon-caret-down:hover,
#tutoriais-page .main-content .card .card-header h3 .ic-sort .icon-caret-down:hover,
#faq-page .main-content .card .card-header span .ic-sort .icon-caret-down:hover,
#tutoriais-page .main-content .card .card-header span .ic-sort .icon-caret-down:hover {
  opacity: 1;
  cursor: pointer;
}
#faq-page .main-content .card .card-body .list-group-item > a,
#tutoriais-page .main-content .card .card-body .list-group-item > a {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  display: block;
  color: #dfdfdf;
  border-color: #dfdfdf;
  border: 0;
  border-bottom-width: 0.125rem;
  border-style: solid;
  text-decoration: none;
}
#faq-page .main-content .card .card-body .list-group-item > a .article-title,
#tutoriais-page .main-content .card .card-body .list-group-item > a .article-title {
  color: #2575E8;
  font-size: 14px;
}
#faq-page .main-content .card .card-body .list-group-item > a .article-updated-at,
#tutoriais-page .main-content .card .card-body .list-group-item > a .article-updated-at {
  color: #000;
  font-size: 14px;
}
#faq-page .main-content .card .card-body .list-group-item .article-content-wrapper,
#tutoriais-page .main-content .card .card-body .list-group-item .article-content-wrapper {
  margin: 0;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#faq-page .main-content .card .card-body .list-group-item .article-content-wrapper a,
#tutoriais-page .main-content .card .card-body .list-group-item .article-content-wrapper a {
  color: #2575E8;
  padding: 0;
  margin: 0;
}
#faq-page .main-content .card .card-body .list-group-item.active .article-content-wrapper,
#tutoriais-page .main-content .card .card-body .list-group-item.active .article-content-wrapper {
  max-height: 100%;
}
.portal-do-assinante-wrapper {
  width: 100%;
  position: relative;
}
.portal-do-assinante-wrapper.hide {
  display: none;
}
.portal-do-assinante-wrapper #portal-do-assinante-iframe {
  width: 100%;
  border: 0;
}
.btn {
  display: inline-block;
  padding: 0 20px;
  font-weight: bold;
  color: #FFFFFF;
  background: #2575E8;
  border: 1px solid #2575E8;
  height: 50px;
  line-height: 48px;
  border-radius: 4px;
  font-size: 14px;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}
.btn:not(.disabled):hover,
.btn:not(.disabled):focus {
  background: #FFFFFF;
  color: #2575E8;
}
.btn.red {
  background: #c50303;
  border-color: #c50303;
}
.btn.red:not(.disabled):hover,
.btn.red:not(.disabled):focus {
  background: #FFFFFF;
  color: #c50303;
}
.btn.white {
  background: #FFFFFF;
  color: #2575E8;
}
.btn.white:not(.disabled):hover,
.btn.white:not(.disabled):focus {
  background: #2575E8;
  color: #FFFFFF;
}
.btn.green {
  background: #417505;
  border-color: #417505;
}
.btn.green:not(.disabled):hover,
.btn.green:not(.disabled):focus {
  background: #FFFFFF;
  color: #417505;
}
.btn:focus {
  outline: none;
}
.btn.disabled {
  opacity: 0.5;
  cursor: default;
}
.meus-dados .foto-do-usuario-container {
  margin: 0 0 15px 0;
  padding: 32px 0;
}
.meus-dados .foto-do-usuario-container .foto-usuario {
  margin-bottom: 5px;
  border-radius: 50% 50%;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .meus-dados .change-email-screen {
    display: none;
  }
}
.meus-dados .space-between {
  height: 30px;
}
.meus-dados .alterar-foto {
  min-width: 139px;
}
.meus-dados .block-content h1 {
  font-size: 30px;
  font-weight: normal;
  letter-spacing: -0.8px;
  text-align: center;
  color: #2575e8;
}
.meus-dados .block-content h2 {
  font-size: 20px;
  font-weight: normal;
  letter-spacing: -0.5px;
  text-align: center;
  color: #818ea3;
}
.meus-dados .data-user .title-mobile {
  display: none;
}
.meus-dados .data-user .block-single {
  margin-top: 0;
}
.meus-dados .data-user .salvar-dados {
  float: right;
  width: 30%;
  margin-top: 16px;
}
@media only screen and (max-width: 991px) {
  .meus-dados .data-user .salvar-dados {
    width: 60%
;
  }
}
@media only screen and (max-width: 767px) {
  .meus-dados .data-user .salvar-dados {
    width: 281px;
    margin-top: 0px;
  }
}
.meus-dados .data-user .bottom-block-button-notice {
  display: flex;
  align-items: end;
  margin-top: 30px;
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .meus-dados .data-user .bottom-block-button-notice {
    flex-direction: column;
    align-items: center;
  }
}
.meus-dados .data-user .atention-notice {
  width: 70%;
  height: 50px;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #f00;
  text-align: left;
  padding: 1% 2%;
  margin-right: 20px;
  color: #707070;
  font-size: 14px;
  overflow: hidden;
}
.meus-dados .data-user .atention-notice a {
  color: #2575e8;
  font-weight: bold;
}
@media only screen and (max-width: 991px) {
  .meus-dados .data-user .atention-notice {
    height: 68px;
    width: 60%;
    margin-bottom: 26px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .meus-dados .data-user .atention-notice {
    margin-bottom: 0px;
    padding: 2%;
    width: 281px;
    height: 94px;
  }
}
.meus-dados .data-user .valid-email {
  float: right;
}
.meus-dados .data-user button.redefinir-senha {
  background: transparent;
  border: solid 1px #2575E8;
  color: #2575E8;
  margin-right: 20px;
  float: right;
  margin-top: 16px;
}
.meus-dados .alterar-email {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
}
.meus-dados .alterar-email .right-section {
  border-radius: 5px;
  border: solid 1px #dedede;
  background-color: #fff;
  margin-right: 5px;
  margin-left: 5px;
}
.meus-dados .alterar-email .right-section p {
  font-size: 16px;
  color: #808080;
  margin: 10px;
}
.meus-dados .alterar-email .right-section .assinaturas {
  overflow: auto;
  max-height: 120px;
  margin: 9px;
  border: 1px solid #ddd;
}
.meus-dados .alterar-email .right-section .assinaturas input[type="checkbox"] {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  cursor: pointer;
  box-shadow: 0 0 0 2px #808080;
}
.meus-dados .alterar-email .right-section .assinaturas div {
  border-bottom: 1px solid #b7b5b5;
  padding: 8px;
}
.meus-dados .alterar-email .right-section .assinaturas input[type="checkbox"]:checked {
  background-color: #808080;
}
.meus-dados .alterar-email .right-section .assinaturas .selected {
  background-color: #f5f5f5;
}
.meus-dados .alterar-email .right-section .assinaturas label {
  color: gray;
  padding: 10px;
}
.meus-dados .alterar-email .right-section .assinaturas .select-all input[type="checkbox"] {
  box-shadow: 0 0 0 2px #2575e8;
}
.meus-dados .alterar-email .right-section .assinaturas .select-all input[type="checkbox"]:checked {
  background-color: #2575e8;
}
.meus-dados form {
  padding: 0 7px;
  margin-bottom: -12px;
}
.meus-dados form .row > [class*="col"] {
  padding-right: 7px;
  padding-left: 7px;
}
.meus-dados form .row.esqueci-minha-senha {
  margin-top: -5px;
  margin-bottom: 21px;
}
.meus-dados form .row.esqueci-minha-senha a {
  font-family: Lato, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #2575E8;
  text-decoration: none;
}
.block-single.redefinir-senha {
  padding: 25px!important;
}
.title-inside {
  font-family: Lato, Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  padding-left: 1.1%;
}
@media only screen and (max-width: 991px) {
  .title-inside {
    color: #425573;
  }
}
.title-inside-content {
  background-color: red;
  width: 99%;
  height: 46px;
  margin: 0 0 20px;
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}
#trocar-foto-do-usuario .title {
  font-size: 30px;
  color: #2575E8;
  margin-bottom: 15px;
}
#trocar-foto-do-usuario .crop-image .ReactCrop {
  width: 100%;
}
#trocar-foto-do-usuario .preview-image {
  display: table;
  margin: 0 auto;
}
#trocar-foto-do-usuario .preview-image .image {
  display: inline-block;
  width: 132px;
  height: 132px;
  border-radius: 150px;
  background-position: center;
  background-size: contain;
}
#trocar-foto-do-usuario .control {
  margin-top: 15px;
}
#trocar-foto-do-usuario .control .cancel,
#trocar-foto-do-usuario .control .confirm {
  margin-right: 10px;
}
.desconectar-social-box {
  display: flex;
  background-color: white;
  min-height: 413px;
  padding: 20px;
}
@media only screen and (max-width: 991px) {
  .desconectar-social-box {
    flex-direction: column;
  }
}
.desconectar-social-box .social-box-title-mobile {
  display: none;
}
@media only screen and (max-width: 991px) {
  .desconectar-social-box .social-box-title-mobile {
    display: block;
  }
}
.desconectar-social-box .social-box-title-desktop {
  display: block;
}
@media only screen and (max-width: 991px) {
  .desconectar-social-box .social-box-title-desktop {
    display: none;
  }
}
.desconectar-social-box .social-box-title .title-inside {
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 1rem;
}
.desconectar-social-box .social-box-title .text-inside {
  font-size: 17px;
  line-height: 21px;
  color: #808080;
  margin-bottom: 1rem;
}
.desconectar-social-box .social-media-name {
  text-transform: capitalize;
}
.desconectar-social-box .google-descr {
  display: none;
}
.desconectar-social-box .social-image-box {
  position: relative;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 384px;
  width: 100%;
  max-height: 500px;
  min-height: 250px;
  flex-grow: 1;
  margin-right: 1rem;
}
@media only screen and (max-width: 991px) {
  .desconectar-social-box .social-image-box {
    max-width: 700px;
    flex-grow: 0;
    height: 100%;
    margin-right: 0;
  }
}
.desconectar-social-box .social-image-box .google-container {
  background-color: transparent;
  padding: 0;
  margin: 0;
}
.desconectar-social-box .social-image-box .google-icon {
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.desconectar-social-box .social-image-box .google-icon .google-text {
  font-size: 50px;
}
.desconectar-social-box .social-form-box {
  margin-top: 1rem;
}
.desconectar-social-box .social-form-box .salvar-dados {
  margin: 25 !important;
}
.desconectar-social-box .social-form-box input {
  color: #808080;
}
.desconectar-social-box .social-form-box button {
  font-weight: normal;
}
@media only screen and (max-width: 575px) {
  .meus-dados .data-user > [class*="col-"] {
    padding: 0;
  }
  .meus-dados .data-user .block-single {
    border-radius: 0;
  }
  .meus-dados .data-user .salvar-dados {
    margin-right: 15px;
    width: 185px;
  }
  .meus-dados form .row.esqueci-minha-senha {
    margin-top: 5px;
    margin-bottom: 30px;
  }
  .meus-dados .alterar-email {
    display: inline-block;
  }
  .meus-dados .alterar-email .bx {
    max-width: 100%;
    padding-top: 10px;
  }
  .meus-dados .alterar-email .bx .row {
    margin: 0;
  }
  .meus-dados .alterar-email .right-section {
    margin: 0;
  }
}
@media only screen and (max-width: 575px), only screen and (max-width: 767px) {
  .meus-dados .data-user .title-mobile {
    display: block;
    padding: 0 20px;
    color: #425573;
    font-size: 18px;
    letter-spacing: -0.1px;
    margin-bottom: 10px;
  }
  .btn {
    float: none!important;
    margin: 25px auto !important;
    width: auto;
    min-width: 185px;
    display: block;
  }
  .btn:last-child {
    margin-bottom: 0 !important;
  }
}
.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
  background-color: #000;
}
.ReactCrop:focus {
  outline: none;
}
.ReactCrop--disabled,
.ReactCrop--locked {
  cursor: inherit;
}
.ReactCrop__image {
  /* autoprefixer: off */
  display: table;
  max-width: 100%;
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: fill-available;
}
.ReactCrop--crop-invisible .ReactCrop__image {
  opacity: 0.5;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
}
.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}
.ReactCrop__drag-handle {
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  outline: 1px solid transparent;
}
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}
.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}
.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}
.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}
.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}
@media (max-width: 768px), (pointer: coarse) {
  .ReactCrop__drag-handle {
    width: 17px;
    height: 17px;
  }
  .ReactCrop .ord-nw {
    margin-top: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-n {
    margin-top: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-ne {
    margin-top: -9px;
    margin-right: -9px;
  }
  .ReactCrop .ord-e {
    margin-top: -9px;
    margin-right: -9px;
  }
  .ReactCrop .ord-se {
    margin-bottom: -9px;
    margin-right: -9px;
  }
  .ReactCrop .ord-s {
    margin-bottom: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-sw {
    margin-bottom: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-w {
    margin-top: -9px;
    margin-left: -9px;
  }
  .ReactCrop__drag-bar.ord-n {
    height: 14px;
    margin-top: -7px;
  }
  .ReactCrop__drag-bar.ord-e {
    width: 14px;
    margin-right: -7px;
  }
  .ReactCrop__drag-bar.ord-s {
    height: 14px;
    margin-bottom: -7px;
  }
  .ReactCrop__drag-bar.ord-w {
    width: 14px;
    margin-left: -7px;
  }
}
.tabs {
  display: inline-flex;
  text-align: center;
  width: 100%;
  height: 56px;
  align-items: center;
}
.tabs .tab-item {
  width: 25%;
}
.tabs .tab-item .tab-label {
  color: #9e9e9e;
  -webkit-transition: color ease 200ms;
  transition: color ease 200ms;
}
.tabs .tab-item .tab-label i {
  display: block;
  font-size: 16px;
}
.tabs .tab-item[data-status="1"] .tab-label {
  -webkit-transition: color ease 200ms;
  transition: color ease 200ms;
  color: #2575E8;
}
.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  top: 0;
  left: 0;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.block-single {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 15px;
}
.block-single.text h3 {
  font-weight: normal;
  color: #2575e8;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-top: 0;
  margin-bottom: 20px;
}
.block-single.text p {
  font-size: 16px;
  color: #5a6169;
}
.slide-item.evento {
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.slide-item.evento .date {
  font-size: 16px;
  letter-spacing: 1.3px;
  text-align: center;
  color: #ffffff;
  display: block;
  height: 20px;
}
.slide-item.evento .content-text {
  height: 115px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  align-items: center;
  color: #fff;
  display: inline-flex;
  white-space: normal;
}
.slide-item.evento .button {
  display: table;
  margin: 0 auto;
}
.slide-item.evento .button a {
  width: 178px;
  height: 32px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  display: block;
  padding-top: 6px;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
.slide-item.evento .button a:hover {
  color: #000000;
  background-color: #FFFFFF;
}
.events-container {
  display: inline-block;
  width: 100%;
  margin: 0 -10px;
}
@media only screen and (max-width: 575px) {
  .events-container {
    margin: 0;
  }
}
.events-container h2 {
  margin: 15px 10px;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #535353;
}
.events-container > .item {
  float: left;
  width: 20%;
  margin-bottom: 20px;
  padding: 0 10px;
}
.events-container > .item > .evento {
  display: inline-block;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .events-container > .item {
    width: 25%;
  }
}
@media only screen and (max-width: 991px) {
  .events-container > .item {
    width: 33.33%;
  }
}
@media only screen and (max-width: 575px) {
  .events-container > .item {
    width: 50%;
    padding: 0 5px;
    margin-bottom: 10px;
  }
  .events-container > .item .evento {
    padding: 10px;
  }
  .events-container > .item .evento .content-text {
    font-size: 16px;
  }
  .events-container > .item .evento .button {
    width: 100%;
  }
  .events-container > .item .evento .button a {
    width: 100%;
    font-size: 14px;
  }
}
.publishing-container {
  margin: 0 -10px;
}
.publishing-container > .item {
  float: left;
  width: calc(20% - 20px);
  margin: 0 10px 20px;
}
.publishing-container > .item > .publishing {
  display: inline-block;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
}
.publishing-container > .item > .publishing a {
  display: block;
}
@media only screen and (max-width: 1199px) {
  .publishing-container > .item {
    width: 25%;
  }
}
@media only screen and (max-width: 991px) {
  .publishing-container > .item {
    width: 33.33%;
  }
}
@media only screen and (max-width: 575px) {
  .publishing-container > .item {
    width: 50%;
  }
}
.publishing.slide-item img {
  width: 100%;
}
.partners-container {
  margin: 0 -10px;
}
.partners-container > .item {
  float: left;
  width: 20%;
  margin-bottom: 20px;
}
.partners-container > .item > .partners {
  display: inline-block;
  padding: 0 10px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .partners-container > .item {
    width: 25%;
  }
}
@media only screen and (max-width: 991px) {
  .partners-container > .item {
    width: 33.33%;
  }
}
@media only screen and (max-width: 575px) {
  .partners-container > .item {
    width: 50%;
  }
}
.partners.slide-item img {
  width: 100%;
}
.wrapper-404 {
  text-align: center;
  padding: 50px 0;
}
.wrapper-404 .icon {
  display: table;
  margin: 0 auto;
  width: 300px;
  height: 300px;
  background-size: contain;
  background-image: url(/static/img/jornal.svg);
  background-repeat: no-repeat;
  text-indent: -9999px;
  overflow: hidden;
  background-position: center;
}
.wrapper-404 h1 {
  color: #757575;
  font-size: 40px;
  font-weight: normal;
  margin-bottom: 0px;
}
.wrapper-404 h2 {
  color: #757575;
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 80px;
}
.wrapper-404 .btn {
  font-size: 18px;
  font-weight: normal;
  height: 56px;
  line-height: 56px;
  width: 100%;
  max-width: 320px;
}
@media only screen and (max-width: 575px) {
  .wrapper-404 .icon {
    width: 200px;
    height: 200px;
  }
  .wrapper-404 h1 {
    font-size: 22px;
  }
  .wrapper-404 h2 {
    font-size: 16px;
  }
}
body.no-scroll {
  overflow: hidden;
}
.overlay-tutorial {
  display: none;
  opacity: 0;
  position: fixed;
  overflow-x: hidden;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
}
.overlay-tutorial.active {
  display: block;
  position: absolute;
  opacity: 1;
}
.overlay-tutorial .modal-target {
  background: #f5f5f5;
  padding: 15px;
}
.overlay-tutorial .modal-target .modal-current-number {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -14px;
  margin-left: -14px;
  border-radius: 50% 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #2575E8;
  font-size: 16px;
  font-weight: bold;
  width: 28px;
  height: 28px;
  border: solid 3px #2575E8;
  background-color: #f6f6f6;
  line-height: 1.88em;
}
.overlay-tutorial .modal-information {
  width: 268px;
  opacity: 0;
  -webkit-transition: opacity ease 200ms;
  transition: opacity ease 200ms;
  -webkit-transition-delay: 100ms;
          transition-delay: 100ms;
}
.overlay-tutorial .modal-information.active {
  opacity: 1;
}
.overlay-tutorial .modal-information .modal-header {
  display: inline-flex;
  padding: 20px;
  background-color: #e8e8e8;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #e8e8e8;
  font-size: 12px;
  border-bottom: none;
}
.overlay-tutorial .modal-information .modal-header .arrow-up {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 14px solid #e8e8e8;
  position: absolute;
  top: 0;
}
.overlay-tutorial .modal-information .modal-header .modal-step {
  width: 35px;
  height: 22px;
  border-radius: 2px;
  background-color: #2575E8;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding-top: 3px;
}
.overlay-tutorial .modal-information .modal-header .modal-title {
  color: #000;
  font-size: 12px;
  margin-left: 8px;
  font-weight: bold;
}
.overlay-tutorial .modal-information .modal-content {
  padding: 0 20px 13px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #e8e8e8;
  font-size: 12px;
  color: #000000;
  z-index: 9;
  position: relative;
}
.overlay-tutorial .modal-information .modal-content p {
  margin-top: 19px;
  min-height: 83px;
}
.overlay-tutorial .modal-information .modal-content .buttons {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
}
.overlay-tutorial .modal-information .modal-content .buttons .right {
  right: 13px;
  position: absolute;
  height: 31px;
  display: inline-flex;
}
.overlay-tutorial .modal-information .modal-content .buttons button {
  border-radius: 2px;
  border: solid 1px #c8c8c8;
  background-color: #e6e6e6;
  padding: 7px 0;
  color: #000;
  cursor: pointer;
  width: 73px;
  text-align: center;
}
.overlay-tutorial .modal-information .modal-content .buttons button.prev {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.overlay-tutorial .modal-information .modal-content .buttons button.next {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.overlay-tutorial .modal-information .modal-content .buttons button[disabled] {
  border-radius: 2px;
  border: solid 1px #c8c8c8;
  background-color: #f6f6f6;
  text-align: center;
  font-size: 12px;
  color: #7f7f7f;
}
.overlay-tutorial .modal-information .modal-content .buttons button[disabled].prev {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.overlay-tutorial .modal-information .modal-content .buttons button[disabled].next {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.overlay-tutorial .modal-information .modal-content .buttons button[data-action="close"]:not('.default') {
  color: #7f7f7f;
  background: #fff;
}
.overlay-tutorial .modal-information .modal-content .arrow-down {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #ffffff;
  position: absolute;
  margin-top: 13px;
}
.overlay-tutorial .user-informations {
  padding: 5px 8px;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
}
.overlay-tutorial .user-informations .user-image {
  display: inline-block;
  vertical-align: middle;
}
.overlay-tutorial .user-informations .user-image i {
  font-size: 20px;
  line-height: 21px;
}
.overlay-tutorial .user-informations .user-image .image {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50px;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .overlay-tutorial .user-informations .user-name {
    display: inline-block;
    font-size: 11px;
    color: #000;
    margin-left: 5px;
    text-transform: uppercase;
    padding-top: 4px;
  }
}
@media (max-width: 767px) {
  .overlay-tutorial .user-informations {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0;
  }
  .overlay-tutorial .user-informations .user-name {
    display: none;
  }
}
.banner-wrapper {
  width: 100%;
  margin-bottom: 25px;
}
.banner-wrapper img {
  max-width: 100%;
  display: table;
  margin: 0 auto;
}
.banner-wrapper img.mobile {
  display: none;
}
@media only screen and (max-width: 575px) {
  .banner-wrapper img {
    display: none;
  }
  .banner-wrapper img.mobile {
    display: table;
  }
}
.fale-conosco .success-message {
  color: #fff;
  background-color: #5bb85d;
  line-height: 100px;
  font-size: 30px;
  border-radius: 10px;
  text-indent: 15px;
}
.fale-conosco .success-message i {
  font-size: 40px;
  color: #8ee690;
  text-indent: 0;
  top: 4px;
  position: relative;
}
.interrupcao-temporaria .data-form .block-single {
  min-height: 240px;
}
.interrupcao-temporaria .data-form .block-single .content-form .calendar {
  width: 80%;
  margin: 0 auto;
}
@media only screen and (max-width: 575px), only screen and (max-width: 767px), only screen and (max-width: 991px) {
  .interrupcao-temporaria .data-form .block-single {
    min-height: auto;
  }
  .interrupcao-temporaria .data-form .block-single:first-child {
    margin-bottom: 25px;
  }
  .interrupcao-temporaria .data-form .salvar-dados {
    margin: 0;
  }
}
@media only screen and (max-width: 575px) {
  .interrupcao-temporaria .data-form .block-single .content-form .calendar {
    width: 100%;
  }
  .interrupcao-temporaria .data-form .salvar-dados {
    float: none;
    margin: 0 auto;
    display: table;
  }
}
.reclamacao-entrega .block-single .content-form .calendar {
  width: 80%;
  margin: 0 auto;
}
.reclamacao-entrega form {
  margin-bottom: -12px;
}
.reclamacao-entrega form .row {
  margin-right: -8px;
  margin-left: -8px;
}
.reclamacao-entrega form .row > [class*="col"] {
  padding-right: 7px;
  padding-left: 7px;
}
.reclamacao-entrega .legenda-entrega {
  font-size: 16px;
  color: #808080;
}
@media only screen and (max-width: 575px), only screen and (max-width: 767px), only screen and (max-width: 991px) {
  .reclamacao-entrega .data-form .block-single:first-child {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .reclamacao-entrega .data-form .block-single .content-form .calendar {
    width: 100%;
  }
  .reclamacao-entrega .data-form .salvar-dados {
    margin-top: 0;
  }
}
.correcao-redacao .block-single .content-form .calendar {
  width: 80%;
  margin: 0 auto;
}
.correcao-redacao form {
  margin-bottom: -12px;
}
.correcao-redacao form .row {
  margin-right: -8px;
  margin-left: -8px;
}
.correcao-redacao form .row > [class*="col"] {
  padding-right: 7px;
  padding-left: 7px;
}
.correcao-redacao .legenda-entrega {
  font-size: 16px;
  color: #808080;
}
@media only screen and (max-width: 575px), only screen and (max-width: 767px), only screen and (max-width: 991px) {
  .correcao-redacao .data-form .block-single:first-child {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .correcao-redacao .data-form .block-single .content-form .calendar {
    width: 100%;
  }
  .correcao-redacao .data-form .salvar-dados {
    margin-top: 0;
  }
}
.contratos-assinatura-wrapper .texto {
  height: 500px;
  overflow-y: auto;
  padding: 25px 40px;
  text-align: justify;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  word-break: break-word;
}
.contratos-assinatura-wrapper .texto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #F5F5F5;
  border-radius: 100px;
}
.contratos-assinatura-wrapper .texto::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
  border-radius: 100px;
}
.contratos-assinatura-wrapper .texto::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 100px;
}
.contratos-assinatura-wrapper .texto h3 {
  font-weight: 600;
  color: #5a6169;
}
.contratos-assinatura-wrapper .texto p.identacao {
  text-indent: 45px;
}
.contratos-assinatura-wrapper .texto ol {
  margin: 20px 0;
}
.contratos-assinatura-wrapper .contratos-status {
  border-radius: 4px;
  padding: 25px 20px;
}
.contratos-assinatura-wrapper .status-list {
  padding: 0;
  list-style: none;
}
.contratos-assinatura-wrapper .status-list li {
  margin-top: 0;
  margin-bottom: 25px;
}
.contratos-assinatura-wrapper .status-list .status-titulo {
  margin: 0;
  font-size: 16px;
  color: #5a6169;
  font-weight: 400;
  font-family: Lato, sans-serif;
}
.contratos-assinatura-wrapper .status-list p.status-valor {
  margin: 0;
  font-family: Lato, sans-serif;
  color: #5a6169;
  font-weight: bold;
}
@media print {
  .contratos-assinatura-wrapper .no-print,
  .contratos-assinatura-wrapper .no-print * {
    display: none !important;
  }
}
@media only screen and (max-width: 575px) {
  .contratos-assinatura-wrapper .conteudo {
    margin-top: 20px;
  }
  .contratos-assinatura-wrapper .conteudo .texto {
    height: 100%;
    overflow-y: auto;
    padding: 20px 5px;
    text-align: justify;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
    word-break: break-word;
  }
  .contratos-assinatura-wrapper .conteudo .texto::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #F5F5F5;
    border-radius: 100px;
  }
  .contratos-assinatura-wrapper .conteudo .texto::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
    border-radius: 100px;
  }
  .contratos-assinatura-wrapper .conteudo .texto::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 100px;
  }
  .contratos-assinatura-wrapper .conteudo .texto h3 {
    font-weight: 600;
    color: #5a6169;
  }
  .contratos-assinatura-wrapper .conteudo .texto p.identacao {
    text-indent: 45px;
  }
  .contratos-assinatura-wrapper .conteudo .texto ol {
    margin: 20px 0;
  }
}
.transferencia-temporaria .data-form .block-single {
  min-height: 240px;
}
.transferencia-temporaria .data-form .block-single .content-form .calendar {
  width: 80%;
  margin: 0 auto;
}
.transferencia-temporaria .data-form .salvar-dados .mobile {
  display: none;
}
.transferencia-temporaria form {
  padding: 0 7px;
  margin-bottom: -12px;
}
.transferencia-temporaria form .row {
  margin-right: -8px;
  margin-left: -8px;
}
.transferencia-temporaria form .row > [class*="col"] {
  padding-right: 7px;
  padding-left: 7px;
}
@media only screen and (max-width: 575px), only screen and (max-width: 767px), only screen and (max-width: 991px) {
  .transferencia-temporaria .data-form .block-single {
    min-height: auto;
  }
  .transferencia-temporaria .data-form .block-single:first-child {
    margin-bottom: 25px;
  }
  .transferencia-temporaria .data-form .salvar-dados {
    margin: 0;
  }
}
@media only screen and (max-width: 575px) {
  .transferencia-temporaria .data-form .block-single .content-form .calendar {
    width: 100%;
  }
  .transferencia-temporaria .data-form .salvar-dados {
    float: none;
    margin: 0 auto;
    display: table;
  }
  .transferencia-temporaria .data-form .salvar-dados span {
    display: none;
  }
  .transferencia-temporaria .data-form .salvar-dados span.mobile {
    display: inline-block;
  }
}
.alert-session p {
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5a6169;
  margin-bottom: 0;
}
.title-row p {
  margin-top: 0;
  font-family: Lato, Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.1px;
  color: #425573;
}
.input-control-calendar {
  cursor: pointer;
}
.input-control-calendar .input-control {
  cursor: pointer;
}
.input-control-calendar input[readonly] {
  background: #FFFFFF;
  color: #000000;
  cursor: pointer;
}
.input-control-calendar label {
  cursor: pointer;
}
.input-control-calendar input[readonly]:focus {
  border-color: #2575E8;
}
.input-control-calendar input[readonly]:focus + label {
  color: #2575E8;
}
.input-control-calendar .isset input + label {
  color: #2575E8;
}
.nota-fiscal-wrapper {
  background-color: #fff;
  padding: 5% 0 5%;
}
.nota-fiscal-wrapper p.descricao-nota {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #2575e8;
  margin-left: -15px;
  margin-bottom: 30px;
}
.nota-fiscal-wrapper p.descricao-nota a {
  text-decoration: none;
}
.nota-fiscal-wrapper .nota-fiscal-fundo {
  background-color: #efefef;
  padding: 75px 20px 55px;
  border-radius: 4px;
}
.nota-fiscal-wrapper .nota-fiscal-fundo .col-10,
.nota-fiscal-wrapper .nota-fiscal-fundo .col-2 {
  padding: 6px;
}
.nota-fiscal-wrapper .nota-fiscal-fundo .btn.btn-copy {
  background: #fff;
  border: solid 1px #dedede;
  border-radius: 4px;
  height: 55px;
}
.nota-fiscal-wrapper .nota-fiscal-fundo .btn.btn-copy i {
  color: #2a2a2a;
  font-size: 30px;
}
.nota-fiscal-wrapper .ajustes-btn .col-12 {
  margin-top: 30px;
  padding: 0;
}
@media only screen and (max-width: 575px) {
  .nota-fiscal-wrapper .nota-fiscal-fundo {
    padding: 30px 24px 20px 8px;
  }
  .nota-fiscal-wrapper .nota-fiscal-fundo .row {
    max-width: 100%;
  }
  .nota-fiscal-wrapper .nota-fiscal-fundo .btn.btn-copy {
    padding: 0 5px;
  }
  .nota-fiscal-wrapper .nota-fiscal-fundo .col-10,
  .nota-fiscal-wrapper .nota-fiscal-fundo .col-2 {
    padding: 1px;
  }
  .nota-fiscal-wrapper .nota-fiscal-fundo #input_chave_id {
    font-size: 12px;
  }
  .nota-fiscal-wrapper .nota-fiscal-fundo .btn.btn-copy {
    background: #fff;
    border: solid 1px #dedede;
    border-radius: 4px;
    height: 55px;
  }
  .nota-fiscal-wrapper .nota-fiscal-fundo .btn.btn-copy i {
    color: #2a2a2a;
    font-size: 30px;
  }
}
.fatura-container {
  background: #fff;
  padding: 20px;
}
.fatura-container table.table-fatura {
  border-collapse: collapse;
  font-family: 'Lato', sans-serif;
  width: 100%;
  border: 1px solid #ccc;
  color: #5a6169;
}
.fatura-container table.table-fatura h1,
.fatura-container table.table-fatura h2,
.fatura-container table.table-fatura p {
  margin: 0;
}
.fatura-container table.table-fatura tr td {
  box-sizing: border-box;
  clear: both;
  border: 1px solid #ccc;
  padding: 5px 20px;
  font-size: 16px;
}
.fatura-container table.table-fatura tr td h1 {
  font-size: 29px;
  font-family: 'Lato Bold', sans-serif;
}
.fatura-container table.table-fatura tr td b {
  font-family: "Lato bold", sans-serif;
}
.fatura-container table.table-fatura tr td h2 {
  font-size: 16px;
}
.google-container {
  background: white;
  padding: 90px 45px;
}
.google-container p.google-descr {
  color: #808080;
  margin: 0;
  font-family: 'Flama', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 10px 0;
}
.google-container p.google-descr.bold {
  line-height: 24px;
  font-weight: bold;
}
.google-container p.google-descr.bold:first-child {
  margin-top: 5px;
  margin-bottom: 20px;
}
.google-container p.google-descr br {
  margin: 20px 0 ;
}
.google-container .google-icon {
  height: 100px;
  border-radius: 4px;
  border: solid 1px #d1d1d1;
  display: table;
  vertical-align: middle;
  width: 100%;
}
.google-container .google-icon > div {
  vertical-align: middle;
  justify-content: center;
  display: table-cell;
  text-align: center;
}
.google-container .google-icon > div .google-text {
  font-size: 24px;
  font-family: sans-serif;
}
.google-container .google-icon > div .google-text.color-1 {
  color: #3182f6;
}
.google-container .google-icon > div .google-text.color-2 {
  color: #ef4131;
}
.google-container .google-icon > div .google-text.color-3 {
  color: #fdbe07;
}
.google-container .google-icon > div .google-text.color-4 {
  color: #31aa52;
}
.a-non-d {
  text-decoration: none;
}
.paypal_box {
  border-radius: 4px;
  border: solid 1px #d1d1d1;
}
.paypal_icon {
  width: 150px;
  height: 26px;
  background-image: url("/static/img/logo-paypal.png");
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
  overflow: hidden;
  margin: 32px 5px;
  background-position: center;
}
.apple_box {
  text-align: center;
  padding: 50px;
  background-color: #f5f5f5;
}
.apple_box img {
  width: 80px;
}
.apple_container {
  padding: 0;
  padding: 15px;
}
.apple_text {
  width: 496px;
  padding-top: 60px;
}
.apple-descr {
  color: #808080;
  margin: 0;
  font-family: 'Flama', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 10px 0;
}
.apple-descr.bold {
  line-height: 24px;
  font-weight: bold;
}
.apple-descr.bold:first-child {
  margin-top: 5px;
  margin-bottom: 20px;
}
.apple-descr br {
  margin: 20px 0 ;
}
.status-list {
  list-style: none;
  padding: 0;
}
.status-list li {
  margin-top: 0;
  margin-bottom: 25px;
}
.status-list li .status-titulo {
  margin: 0;
  font-size: 16px;
  color: #5a6169;
  font-weight: 400;
  font-family: Lato, sans-serif;
}
.status-list li p.status-valor {
  margin: 0;
  font-family: Lato, sans-serif;
  color: #5a6169;
  font-weight: 700;
}
@media only screen and (max-width: 575px) {
  .nota-fiscal-container {
    margin-top: 0;
  }
  .nota-fiscal-wrapper p.descricao-nota {
    margin-left: 0;
  }
  .fatura-container {
    margin-top: 20px;
  }
  .fatura-container table.table-fatura {
    border: 0;
  }
  .fatura-container table.table-fatura tr td {
    display: block;
    text-align: left !important;
  }
  .nota-status {
    display: none;
  }
  .nota-fiscal-wrapper {
    margin-top: 20px;
  }
  .nota-fiscal-container .row.justify-content-center,
  .nota-fiscal-container .nota-fiscal > .row {
    max-width: 100vw;
  }
  .nota-fiscal-container .espacamento-btn {
    margin-top: 30px;
  }
  .fatura-container {
    margin-top: 20px;
  }
  .google-container {
    padding: 50px 0;
    margin-top: 20px;
  }
  .google-container .google-icon {
    width: 80%;
    margin-bottom: 20px;
    margin-left: 10%;
  }
}
#as-react-datatable-table-head {
  display: none;
}
#as-react-datatable-table-foot {
  display: none;
}
table {
  font-family: Lato, Arial, sans-serif;
  border-spacing: 0 !important;
  width: 100%;
}
table thead {
  background-color: #2575E8;
}
table th {
  padding: 8px 16px;
  text-align: left;
  color: #fff !important;
  font-size: 12px;
  font-weight: normal;
}
@media print {
  table {
    border: 0 !important;
  }
  table tr:first-of-type td {
    border: 0 !important;
  }
  table th {
    border: 0 !important;
  }
}
table {
  width: 100%;
}
table .historico-pagamento {
  font-size: 14px;
  padding: 12px 16px;
  color: #000000;
}
table .historico-pagamento.ciclo {
  font-weight: normal;
}
table .historico-pagamento.action {
  display: inline-flex;
  min-width: 220px;
  min-height: 44px;
}
table .historico-pagamento.action button {
  color: #2575E8;
  font-size: 15px;
  display: inline-flex;
  border-radius: 3px;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all ease 200ms;
  transition: all ease 200ms;
}
table .historico-pagamento.action button:hover {
  color: #417505;
}
table .historico-pagamento.action button[disabled] {
  color: #636363;
  opacity: 0.6;
}
table .historico-pagamento.action button[disabled]:hover {
  border: 0;
}
table .historico-pagamento input {
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  padding: 12px 16px;
  color: #000000;
}
table td {
  border-bottom: solid 1px #dfdfdf;
}
table td p {
  margin: 0;
}
table td.status {
  font-weight: bold;
}
table td.status .red {
  color: #d0021b;
}
table td.status .yellow {
  color: #f7b500;
}
table td.status .green {
  color: #417505;
}
table td.expand-arrow img {
  display: none;
  -webkit-transition: -webkit-transform ease 200ms;
  transition: -webkit-transform ease 200ms;
  transition: transform ease 200ms;
  transition: transform ease 200ms, -webkit-transform ease 200ms;
}
table th.expand-arrow {
  width: 70px;
}
table button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  float: left;
}
.expanded-row td {
  height: 0;
  -webkit-transition: height ease 200ms;
  transition: height ease 200ms;
  display: block;
  border: none;
}
.expanded-row td .expanded-content {
  -webkit-transition: all ease 200ms;
  transition: all ease 200ms;
  position: absolute;
  overflow: hidden;
  height: 0;
  font-size: 14px;
  text-align: left;
}
.expanded-row td .expanded-content button {
  margin: 0;
  padding: 0;
  color: #2575E8;
  float: none;
  width: 100%;
}
.expanded-row td .expanded-content button i {
  margin-left: -3px;
  font-size: 16px;
  margin-right: 15px;
}
th.sortable {
  color: #2196F3;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("/static/icons/datatable/icon_arrow.svg");
  background-position: 95% 50%;
  background-size: 10px;
}
.as-react-table .input-group-text:empty {
  display: none;
}
.asrt-page-length {
  display: inline-block;
}
.asrt-page-length .input-group-addon {
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 0;
  background: #fff;
  border: none;
}
.asrt-pagination {
  margin: 0;
}
.alert-row {
  padding: 0 16px;
}
.google h1 {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #2575e8;
}
.google h2 {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #5a6169;
}
@media (max-width: 768px) {
  .data-pagamento {
    background-color: #fff;
  }
  .data-pagamento .block-single {
    padding: 0;
  }
  .data-pagamento .block-single .row {
    margin: 0;
  }
  .data-pagamento .block-single .col-md-12 {
    padding: 0;
  }
  .data-pagamento .block-single table {
    border: solid 1px #dfdfdf;
    margin-bottom: 20px;
  }
  .data-pagamento .block-single table tr.active .expand-arrow img {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .data-pagamento .block-single table tr.expanded-row td {
    height: 0;
    border: none;
    overflow: hidden;
    padding: 0;
    min-height: 0;
    min-height: initial;
  }
  .data-pagamento .block-single table tr.expanded-row.active {
    display: block;
    min-height: 215px;
    height: auto;
  }
  .data-pagamento .block-single table tr.expanded-row.active td {
    border: none;
    display: block;
    height: 150px;
  }
  .data-pagamento .block-single table tr.expanded-row.active td .expanded-content {
    min-height: 150px;
    height: 215px;
    padding: 9px 16px;
    width: 100%;
  }
  .data-pagamento .block-single table tr.expanded-row.active td .expanded-content p {
    margin: 0 0 8px 0 !important;
  }
  .data-pagamento .block-single table tr.active + tr td {
    border-top: solid 1px #dfdfdf;
  }
  .data-pagamento .block-single table th {
    font-size: 12px;
  }
  .data-pagamento .block-single table th.sortable {
    background-size: 8px;
  }
  .data-pagamento .block-single table th.ciclo {
    display: none;
  }
  .data-pagamento .block-single table th.action {
    display: none;
  }
  .data-pagamento .block-single table th.expand-arrow {
    width: 34px;
  }
  .data-pagamento .block-single table td {
    border-bottom: solid 1px #dfdfdf;
    text-align: center;
    padding: 8px 5px;
    min-height: 34px;
  }
  .data-pagamento .block-single table td:nth-last-child {
    border: none;
  }
  .data-pagamento .block-single table td p {
    margin: 0;
    width: 100%;
  }
  .data-pagamento .block-single table td.expand-arrow {
    display: table-cell;
  }
  .data-pagamento .block-single table td.ciclo {
    display: none;
  }
  .data-pagamento .block-single table td.action {
    display: none;
  }
  .data-pagamento .block-single table td.expand-arrow img {
    display: block;
  }
}
.button-icon-credit-card {
  width: 140px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  display: inline-flex;
}
.button-icon-credit-card .text-button-icon-credit-card {
  color: white;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 14px;
  word-wrap: break-word;
}
.button-icon-print {
  width: 140px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  display: inline-flex;
}
.button-icon-print .text-button {
  color: #2575E8;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 14px;
  word-wrap: break-word;
}
.button-icon-mail {
  width: 140px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  display: inline-flex;
}
.button-icon-mail .text-button-icon-mail {
  color: #2575E8;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 14px;
  word-wrap: break-word;
}
.button-icon-barcode {
  width: 140px;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  display: inline-flex;
}
.button-icon-barcode .text-button-icon-barcode {
  color: #2575E8;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 14px;
  word-wrap: break-word;
}
.button-icon-barcode:hover {
  background-color: #E9F1FD;
}
.bg-red {
  border: 1px #d0021b solid;
  background: #d0021b;
}
.bg-yellow {
  border: 1px #DC720C solid;
  background: #DC720C;
}
.button-icon-credit-card-m {
  width: 100%;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  display: inline-flex;
}
.button-icon-credit-card-m .text-button-icon-credit-card-m {
  color: white;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 14px;
  word-wrap: break-word;
}
.button-icon-print-m {
  width: 100%;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  display: inline-flex;
}
.button-icon-print-m .text-button-m {
  color: #2575E8;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 14px;
  word-wrap: break-word;
}
.button-icon-mail-m {
  width: 100%;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  display: inline-flex;
}
.button-icon-mail-m .text-button-icon-mail-m {
  color: #2575E8;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 14px;
  word-wrap: break-word;
}
.button-icon-barcode-m {
  width: 100%;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 5px;
  border: 1px #2575E8 solid;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  display: inline-flex;
}
.button-icon-barcode-m .text-button-icon-barcode-m {
  color: #2575E8;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 14px;
  word-wrap: break-word;
}
.menu1 {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0px 1px 5px rgba(102, 102, 102, 0.6);
  z-index: 9999;
}
.menu2 {
  height: 80px;
  padding: 12px;
  left: 0px;
  top: 3px;
  position: absolute;
  background: white;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
  border-bottom: 2px #2575E8 solid;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 12px;
  gap: 12px;
  display: inline-flex;
}
.menu3 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 12px;
  gap: 12px;
  display: flex;
  margin-top: -25px;
}
.menu4 {
  width: 149px;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.menu5 {
  width: 4.71px;
  height: 8.09px;
  position: relative;
}
.menu6 {
  width: 4.71px;
  height: 8.09px;
  left: 0px;
  top: 0px;
  position: absolute;
  background: #2575E8;
}
.menu7 {
  width: 136px;
  color: #1E1E21;
  font-size: 12px;
  font-family: Lato;
  font-weight: 700;
  word-wrap: break-word;
}
.menu8 {
  width: 149px;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  display: inline-flex;
}
.menu9 {
  width: 149px;
  color: #1E1E21;
  font-size: 12px;
  font-family: Lato;
  font-weight: 400;
  word-wrap: break-word;
  text-align: left;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  padding: 5px 0;
}
.menu9:hover {
  color: #2575E8;
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  font-weight: bold;
}
.menu9:hover::before {
  color: #2575E8;
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  content: "> ";
  font-weight: bold;
}
.seta {
  color: #2575E8;
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  font-family: FontAwesome;
  content: '\F0DA';
}
.menu10 {
  width: 149px;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  display: inline-flex;
}
.menu11 {
  width: 149px;
  color: #1E1E21;
  font-size: 12px;
  font-family: Lato;
  font-weight: 400;
  word-wrap: break-word;
}
.blue_arrow_r {
  width: 20px;
  height: 20px;
  opacity: 0;
}
.blue_arrow_r:hover {
  width: 20px;
  height: 20px;
  opacity: 1;
}
.forma-de-pagamento .inner-content .tab-list {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  width: 100%;
  display: table;
  height: 85px;
  text-align: center;
  background: #FFFFFF;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #dedede;
}
.forma-de-pagamento .inner-content .tab-list[data-length="3"] li {
  width: 33.33%;
}
@media only screen and (max-width: 575px) {
  .forma-de-pagamento .inner-content .tab-list[data-length="3"] li {
    padding: 0 15px;
    font-size: 14px;
  }
}
.forma-de-pagamento .inner-content .tab-list li {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
  cursor: pointer;
  font-size: 16px;
  color: #b3b3b3;
  border-right: 1px solid #dedede;
  background: #fbfbfb;
}
.forma-de-pagamento .inner-content .tab-list li:last-child {
  border-right: none;
}
.forma-de-pagamento .inner-content .tab-list li.active {
  font-weight: bold;
  background: #FFFFFF;
  color: #5a6169;
}
.forma-de-pagamento .inner-content .input-control:not(.checkbox):not(.radio):not(.select) {
  background: transparent;
}
.forma-de-pagamento .inner-content .block-single {
  border-radius: 0 0 5px 5px;
  display: table;
  width: 100%;
}
.forma-de-pagamento .inner-content .block-single form {
  padding: 0 5px;
  margin-bottom: -12px;
}
.forma-de-pagamento .inner-content .block-single form .row {
  margin-right: -5px;
  margin-left: -5px;
}
.forma-de-pagamento .inner-content .block-single form .row > [class*="col"] {
  padding-right: 5px;
  padding-left: 5px;
}
.forma-de-pagamento .inner-content .lock-size-form {
  display: table;
  margin: 0 auto;
  max-width: 634px;
  width: 100%;
}
.forma-de-pagamento .inner-content .credit-card form,
.forma-de-pagamento .inner-content .boleto-por-email form {
  background-color: #efefef;
  border-radius: 4px;
  padding: 20px;
}
.forma-de-pagamento .inner-content .credit-card .bandeira span,
.forma-de-pagamento .inner-content .boleto-por-email .bandeira span {
  background: #FFFFFF;
  border-radius: 3px;
  border: solid 1px #dedede;
  width: 100%;
  height: 55px;
  text-align: center;
  line-height: 55px;
  font-size: 30px;
  color: #dedede;
  display: inline-block;
}
.forma-de-pagamento .inner-content .credit-card .bandeira span i:before,
.forma-de-pagamento .inner-content .boleto-por-email .bandeira span i:before {
  width: 37px;
}
.forma-de-pagamento .inner-content .credit-card .bandeira span i[data-flag]:before,
.forma-de-pagamento .inner-content .boleto-por-email .bandeira span i[data-flag]:before {
  content: "";
  background-image: url("/static/img/sprite-cartoes.png");
  background-repeat: no-repeat;
  position: relative;
}
.forma-de-pagamento .inner-content .credit-card .bandeira span i[data-flag][data-flag="AMEX"]:before,
.forma-de-pagamento .inner-content .boleto-por-email .bandeira span i[data-flag][data-flag="AMEX"]:before {
  width: 22px;
  height: 22px;
  background-position: -87px -5px;
}
.forma-de-pagamento .inner-content .credit-card .bandeira span i[data-flag][data-flag="VISA"]:before,
.forma-de-pagamento .inner-content .boleto-por-email .bandeira span i[data-flag][data-flag="VISA"]:before {
  width: 30px;
  height: 22px;
  background-position: -44px -5px;
}
.forma-de-pagamento .inner-content .credit-card .bandeira span i[data-flag][data-flag="MASTERCARD"]:before,
.forma-de-pagamento .inner-content .boleto-por-email .bandeira span i[data-flag][data-flag="MASTERCARD"]:before {
  width: 26px;
  height: 22px;
  background-position: -7px -5px;
}
.forma-de-pagamento .inner-content .credit-card .bandeira span i[data-flag][data-flag="DINERS"]:before,
.forma-de-pagamento .inner-content .boleto-por-email .bandeira span i[data-flag][data-flag="DINERS"]:before {
  width: 23px;
  height: 22px;
  background-position: -126px -6px;
}
.forma-de-pagamento .inner-content .credit-card .bandeira.disabled span,
.forma-de-pagamento .inner-content .boleto-por-email .bandeira.disabled span {
  background-color: #f9f9f9;
  color: #808080;
}
.forma-de-pagamento .inner-content .credit-card .text-blue,
.forma-de-pagamento .inner-content .boleto-por-email .text-blue {
  color: #2575e8;
  font-size: 18px;
  margin: 0;
  display: table;
  height: 55px;
  text-align: right;
  width: 100%;
}
.forma-de-pagamento .inner-content .credit-card .text-blue span,
.forma-de-pagamento .inner-content .boleto-por-email .text-blue span {
  display: table-cell;
  vertical-align: middle;
}
.forma-de-pagamento .inner-content .direct-debit form {
  background-color: #efefef;
  border-radius: 4px;
  padding: 20px;
  min-height: 240px;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos {
  display: table;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 405px;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li {
  display: table-cell;
  vertical-align: middle;
  width: 25%;
  padding: 0 3px;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand {
  position: relative;
  width: 100%;
  max-width: 95px;
  height: 55px;
  margin: 0 auto;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand i {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 4px;
  border: solid 1px #dedede;
  background-color: #ffffff;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  margin: 0;
  cursor: pointer;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input + i:after {
  content: "";
  display: block;
  margin: 0 auto;
  background-image: url("/static/img/sprite-bancos.png");
  background-repeat: no-repeat;
  background-size: 150px;
  position: relative;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input[value="bancobrasil"] + i:after {
  width: 40px;
  height: 40px;
  top: 7px;
  background-position: 0 -41px;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input[value="bradesco"] + i:after {
  width: 36px;
  height: 40px;
  top: 7px;
  background-position: -41px -39px;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input[value="itau"] + i:after {
  width: 32px;
  height: 32px;
  top: 11px;
  background-position: -77px -43px;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input[value="santander"] + i:after {
  width: 40px;
  height: 36px;
  top: 9px;
  background-position: -110px -39px;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input:checked[value="bancobrasil"] + i {
  background-color: #fff22d;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input:checked[value="bancobrasil"] + i:after {
  background-position: 0 0;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input:checked[value="bradesco"] + i {
  background-color: #cc203c;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input:checked[value="bradesco"] + i:after {
  background-position: -41px 1px;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input:checked[value="itau"] + i {
  background-color: #db812e;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input:checked[value="itau"] + i:after {
  background-position: -77px -2px;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input:checked[value="santander"] + i {
  background-color: #fe0000;
}
.forma-de-pagamento .inner-content .direct-debit form .lista-de-bancos > li .input-brand input:checked[value="santander"] + i:after {
  background-position: -110px 0;
}
.forma-de-pagamento .inner-content .direct-debit form .active-banco p {
  text-align: left;
}
.forma-de-pagamento .inner-content .direct-debit form p {
  text-align: center;
  font-weight: lighter;
  width: 100%;
}
.forma-de-pagamento .inner-content .paypal-form {
  width: 370px;
  margin: auto;
  padding: 8px;
}
.forma-de-pagamento .inner-content .paypal-form .paypal-texts {
  width: 100%;
  margin-left: 23px;
}
.forma-de-pagamento .inner-content .paypal-form .h1-paypal {
  width: 100%;
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #0070ba;
  margin-top: 12px;
}
.forma-de-pagamento .inner-content .paypal-form .h2-paypal {
  width: 100%;
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #0070ba;
  margin-top: 16px;
}
.forma-de-pagamento .inner-content .paypal-form .text-paypal {
  width: 100%;
  font-family: Lato, Arial, sans-serif;
  font-size: 16px;
  color: #4a4a4a;
}
.forma-de-pagamento .inner-content .paypal-form #paypal-button {
  margin-top: 32px;
}
.forma-de-pagamento .inner-content .swg {
  padding: 50px;
}
.forma-de-pagamento .inner-content .swg .google-brand {
  display: table;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  border-radius: 4px;
  border: solid 1px #d1d1d1;
  height: 100px;
}
.forma-de-pagamento .inner-content .swg .google-brand .center {
  display: table-cell;
  vertical-align: middle;
}
.forma-de-pagamento .inner-content .swg .google-brand .center img {
  width: 100%;
  max-width: 100px;
  margin: 0 auto;
  display: table;
}
.forma-de-pagamento .inner-content .swg p {
  font-weight: lighter;
  color: #808080;
  margin-top: 0;
  font-size: 16px;
}
@media only screen and (max-width: 575px), only screen and (max-width: 767px) {
  .forma-de-pagamento .inner-content .swg {
    padding: 30px 10px;
  }
}
@media only screen and (max-width: 575px) {
  .forma-de-pagamento .inner-content .swg .google-brand {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .forma-de-pagamento .data-form .inner-content {
    margin-top: 25px;
    padding-top: 0;
  }
}
@media only screen and (max-width: 575px) {
  .forma-de-pagamento .data-form .inner-content {
    margin-top: 25px;
  }
  .forma-de-pagamento .data-form .inner-content .block-single {
    padding: 15px 0;
  }
  .forma-de-pagamento .data-form .inner-content .credit-card form {
    border-radius: 0;
  }
  .forma-de-pagamento .data-form .inner-content .credit-card form .row .bandeira {
    padding-right: 0;
  }
  .forma-de-pagamento .data-form .inner-content .credit-card form .row .bandeira span {
    border-radius: 3px 0 0 3px;
    border-right: none;
  }
  .forma-de-pagamento .data-form .inner-content .credit-card form .row .bandeira + div {
    padding-left: 0;
  }
  .forma-de-pagamento .data-form .inner-content .credit-card form .row .bandeira + div input {
    border-radius: 0 3px 3px 0;
  }
  .forma-de-pagamento .data-form .salvar-dados {
    font-size: 13px;
  }
}
.controle-de-acesso {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 25px;
}
.controle-de-acesso .top {
  display: table;
  margin-bottom: 16px;
  width: 100%;
}
.controle-de-acesso .top .btn {
  height: 50px;
  border-radius: 4px;
  border: solid 1px #2575E8;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.44px;
  text-align: center;
  color: #2575E8;
}
.controle-de-acesso .top p {
  font-size: 16px;
  color: #232323;
  margin: 8px 0 0;
}
.controle-de-acesso .top p span {
  color: #2575E8;
}
.controle-de-acesso .item {
  display: inline-flex;
  margin-bottom: 16px;
  width: 100%;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  border-radius: 4px;
  border: solid 1px #dedede;
  background-color: #f9f9f9;
}
@media only screen and (max-width: 575px) {
  .controle-de-acesso .item {
    display: block;
  }
}
.controle-de-acesso .item:hover {
  border-color: #2575E8;
}
.controle-de-acesso .item .field {
  display: inline;
  font-size: 16px;
  color: #222222;
  width: calc(100% - 102px);
  vertical-align: middle;
}
.controle-de-acesso .item .field.action {
  width: 102px;
}
.controle-de-acesso .item .field.action .btn {
  height: 50px;
  width: 102px;
  border-radius: 4px;
  border: solid 1px #2575E8;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.44px;
  text-align: center;
  color: #2575E8;
  padding: 0;
  min-width: 102px;
}
.controle-de-acesso .item .field em {
  display: block;
  font-size: 10px;
  color: #2575E8;
}
.controle-de-acesso .item .field p {
  font-size: 16px;
  margin: 0;
  color: #5a6169;
}
.controle-de-acesso .item .field p.owner {
  color: #5bb85d !important;
  font-weight: bold;
  margin-bottom: 8px;
}
.alert-controle {
  margin: 0 0 15px 0;
  padding: 32px;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #232323;
}
.alert-controle .block-content {
  text-align: center;
}
.alert-controle .block-content p {
  text-align: left;
  margin: 0 0 12px;
}
.alert-controle .block-content button {
  font-size: 13px;
}
@media only screen and (max-width: 575px) {
  .alert-controle {
    margin-top: 32px !important;
  }
}
#add-to-home-screen {
  position: fixed;
  top: 10vh;
  width: 100%;
  z-index: 9999999;
}
#add-to-home-screen .center {
  display: table;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: 420px;
}
#add-to-home-screen .center .inner {
  padding: 15px;
  border-radius: 1px;
  width: 100%;
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.18);
  position: relative;
}
#add-to-home-screen .center .inner .control {
  display: table;
  width: 100%;
  padding-top: 15px;
}
#add-to-home-screen .center .inner p {
  font-size: 14px;
}
#add-to-home-screen .center .inner img {
  display: table;
  margin: 0 auto;
  max-width: 90px;
  width: 100%;
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
}
#add-to-home-screen .center .inner button {
  display: inline-block;
  border-radius: 2px;
  border: solid 1px #e9e9e9;
  font-family: 'Flama-Book', sans-serif;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  background-color: #ffffff;
  cursor: pointer;
  padding: 10px 20px;
  margin-left: 10px;
  float: right;
}
#add-to-home-screen .center .inner button.install {
  background-color: #2575e8;
  color: #FFFFFF;
}
@font-face {
  font-family: 'icones-meu-estadao';
  src: url("/static/fonts/icones-meu-estadao/meu-estadao.eot");
  src: url("/static/fonts/icones-meu-estadao/meu-estadao.eot#iefix") format('embedded-opentype'), url("/static/fonts/icones-meu-estadao/meu-estadao.woff2") format('woff2'), url("/static/fonts/icones-meu-estadao/meu-estadao.woff") format('woff'), url("/static/fonts/icones-meu-estadao/meu-estadao.ttf") format('truetype'), url("/static/fonts/icones-meu-estadao/meu-estadao.svg") format('svg');
  font-style: 'normal';
  font-weight: 'normal';
}
@font-face {
  font-family: 'icones-meu-estadao';
  src: url("/static/fonts/icones-meu-estadao/meu-estadao.eot");
  src: url("/static/fonts/icones-meu-estadao/meu-estadao.eot#iefix") format('embedded-opentype'), url("/static/fonts/icones-meu-estadao/meu-estadao.woff2") format('woff2'), url("/static/fonts/icones-meu-estadao/meu-estadao.woff") format('woff'), url("/static/fonts/icones-meu-estadao/meu-estadao.ttf") format('truetype'), url("/static/fonts/icones-meu-estadao/meu-estadao.svg") format('svg');
  font-style: 'normal';
  font-weight: 'normal';
}
@font-face {
  font-family: 'Flama-semibold';
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.eot");
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.eot#iefix") format('embedded-opentype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.woff2") format('woff2'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.woff") format('woff'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.ttf") format('truetype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.svg") format('svg');
  font-style: 'normal';
  font-weight: 'normal';
}
@font-face {
  font-family: 'Flama-semibold';
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.eot");
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.eot#iefix") format('embedded-opentype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.woff2") format('woff2'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.woff") format('woff'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.ttf") format('truetype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Semibold-webfont.svg") format('svg');
  font-style: 'normal';
  font-weight: 'normal';
}
@font-face {
  font-family: 'Flama-bold';
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.eot");
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.eot#iefix") format('embedded-opentype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.woff2") format('woff2'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.woff") format('woff'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.ttf") format('truetype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.svg") format('svg');
  font-style: 'normal';
  font-weight: 'normal';
}
@font-face {
  font-family: 'Flama-bold';
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.eot");
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.eot#iefix") format('embedded-opentype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.woff2") format('woff2'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.woff") format('woff'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.ttf") format('truetype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/Flama-Bold-webfont.svg") format('svg');
  font-style: 'normal';
  font-weight: 'normal';
}
@font-face {
  font-family: 'Flama';
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.eot");
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.eot#iefix") format('embedded-opentype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.woff2") format('woff2'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.woff") format('woff'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.ttf") format('truetype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.svg") format('svg');
  font-style: 'normal';
  font-weight: 'normal';
}
@font-face {
  font-family: 'Flama';
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.eot");
  src: url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.eot#iefix") format('embedded-opentype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.woff2") format('woff2'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.woff") format('woff'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.ttf") format('truetype'), url("https://statics.estadao.com.br/s2016/portal/2018/font/flama/regular/Flama-Book-webfont.svg") format('svg');
  font-style: 'normal';
  font-weight: 'normal';
}
/*
   Animation example, for spinners
*/
.animate-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icones-meu-estadao";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-location:before {
  content: '\E800';
}
/* '' */
.icon-building:before {
  content: '\E801';
}
/* '' */
.icon-mail:before {
  content: '\E802';
}
/* '' */
.icon-calendar:before {
  content: '\E803';
}
/* '' */
.icon-book:before {
  content: '\E804';
}
/* '' */
.icon-star:before {
  content: '\E805';
}
/* '' */
.icon-barcode:before {
  content: '\E806';
}
/* '' */
.icon-arrows-cw:before {
  content: '\E807';
}
/* '' */
.icon-list-alt:before {
  content: '\E808';
}
/* '' */
.icon-megaphone:before {
  content: '\E809';
}
/* '' */
.icon-up-big:before {
  content: '\E80A';
}
/* '' */
.icon-down-big:before {
  content: '\E80B';
}
/* '' */
.icon-minus:before {
  content: '\E80C';
}
/* '' */
.icon-bell:before {
  content: '\E80D';
}
/* '' */
.icon-credit-card:before {
  content: '\E80E';
}
/* '' */
.icon-user:before {
  content: '\E80F';
}
/* '' */
.icon-chat:before {
  content: '\E810';
}
/* '' */
.icon-cog:before {
  content: '\E811';
}
/* '' */
.icon-floppy:before {
  content: '\E812';
}
/* '' */
.icon-cancel-1:before {
  content: '\E813';
}
/* '' */
.icon-upload:before {
  content: '\E814';
}
/* '' */
.icon-cancel:before {
  content: '\E815';
}
/* '' */
.icon-eye:before {
  content: '\E816';
}
/* '' */
.icon-trash-empty:before {
  content: '\E817';
}
/* '' */
.icon-plus:before {
  content: '\E818';
}
/* '' */
.icon-ok-circled:before {
  content: '\E819';
}
/* '' */
.icon-login:before {
  content: '\E81A';
}
/* '' */
.icon-award:before {
  content: '\E81B';
}
/* '' */
.icon-clock:before {
  content: '\E81C';
}
/* '' */
.icon-tags:before {
  content: '\E81D';
}
/* '' */
.icon-perfil:before {
  content: '\E81E';
}
/* '' */
.icon-shape:before {
  content: '\E81F';
}
/* '' */
.icon-attach:before {
  content: '\E820';
}
/* '' */
.icon-lock:before {
  content: '\E821';
}
/* '' */
.icon-check:before {
  content: '\E822';
}
/* '' */
.icon-check-empty-1:before {
  content: '\E823';
}
/* '' */
.icon-download:before {
  content: '\E824';
}
/* '' */
.icon-estadao:before {
  content: '\E900';
}
/* '' */
.icon-long-arrow-up:before {
  content: '\E901';
}
/* '' */
.icon-long-arrow-down:before {
  content: '\E902';
}
/* '' */
.icon-angle-left:before {
  content: '\E905';
}
/* '' */
.icon-angle-right:before {
  content: '\E906';
}
/* '' */
.icon-angle-down:before {
  content: '\E907';
}
/* '' */
.icon-angle-up:before {
  content: '\E908';
}
/* '' */
.icon-caret-left:before {
  content: '\E909';
}
/* '' */
.icon-caret-right:before {
  content: '\E90A';
}
/* '' */
.icon-caret-up:before {
  content: '\E90B';
}
/* '' */
.icon-caret-down:before {
  content: '\E90C';
}
/* '' */
.icon-bars:before {
  content: '\E90F';
}
/* '' */
.icon-search:before {
  content: '\E910';
}
/* '' */
.icon-facebook:before {
  content: '\E914';
}
/* '' */
.icon-twitter:before {
  content: '\E915';
}
/* '' */
.icon-google-plus:before {
  content: '\E916';
}
/* '' */
.icon-instagram:before {
  content: '\E917';
}
/* '' */
.icon-linkedin:before {
  content: '\E918';
}
/* '' */
.icon-youtube-play:before {
  content: '\E919';
}
/* '' */
.icon-whatsapp:before {
  content: '\E91A';
}
/* '' */
.icon-play:before {
  content: '\E920';
}
/* '' */
.icon-wi-day-cloudy:before {
  content: '\E928';
}
/* '' */
.icon-wi-day-rain:before {
  content: '\E929';
}
/* '' */
.icon-wi-day-rain-mix:before {
  content: '\E92A';
}
/* '' */
.icon-wi-day-snow:before {
  content: '\E92B';
}
/* '' */
.icon-wi-day-storm-showers:before {
  content: '\E92C';
}
/* '' */
.icon-wi-day-sunny:before {
  content: '\E92D';
}
/* '' */
.icon-wi-day-sunny-overcast:before {
  content: '\E92E';
}
/* '' */
.icon-wi-fog:before {
  content: '\E92F';
}
/* '' */
.icon-wi-night-alt-cloudy:before {
  content: '\E930';
}
/* '' */
.icon-wi-night-alt-partly-cloudy:before {
  content: '\E931';
}
/* '' */
.icon-wi-night-alt-rain-mix:before {
  content: '\E932';
}
/* '' */
.icon-wi-night-alt-showers:before {
  content: '\E933';
}
/* '' */
.icon-wi-night-alt-snow:before {
  content: '\E934';
}
/* '' */
.icon-wi-night-alt-storm-showers:before {
  content: '\E935';
}
/* '' */
.icon-wi-snow:before {
  content: '\E936';
}
/* '' */
.icon-round-lock:before {
  content: '\E937';
}
/* '' */
.icon-link-ext:before {
  content: '\F08E';
}
/* '' */
.icon-check-empty:before {
  content: '\F096';
}
/* '' */
.icon-up-circled:before {
  content: '\F0AA';
}
/* '' */
.icon-docs:before {
  content: '\F0C5';
}
/* '' */
.icon-money:before {
  content: '\F0D6';
}
/* '' */
.icon-doc-text:before {
  content: '\F0F6';
}
/* '' */
.icon-circle-empty:before {
  content: '\F10C';
}
/* '' */
.icon-spinner:before {
  content: '\F110';
}
/* '' */
.icon-circle:before {
  content: '\F111';
}
/* '' */
.icon-folder-open-empty:before {
  content: '\F115';
}
/* '' */
.icon-rocket:before {
  content: '\F135';
}
/* '' */
.icon-lock-open-alt:before {
  content: '\F13E';
}
/* '' */
.icon-doc-text-inv:before {
  content: '\F15C';
}
/* '' */
.icon-apple:before {
  content: '\F179';
}
/* '' */
.icon-android:before {
  content: '\F17B';
}
/* '' */
.icon-dot-circled:before {
  content: '\F192';
}
/* '' */
.icon-file-pdf:before {
  content: '\F1C1';
}
/* '' */
.icon-paper-plane:before {
  content: '\F1D8';
}
/* '' */
.icon-paper-plane-empty:before {
  content: '\F1D9';
}
/* '' */
.icon-share:before {
  content: '\F1E0';
}
/* '' */
.icon-newspaper:before {
  content: '\F1EA';
}
/* '' */
.icon-bell-off-empty:before {
  content: '\F1F7';
}
/* '' */
.icon-toggle-off:before {
  content: '\F204';
}
/* '' */
.icon-toggle-on:before {
  content: '\F205';
}
/* '' */
.icon-cart-plus:before {
  content: '\F217';
}
/* '' */
.icon-pinterest:before {
  content: '\F231';
}
/* '' */
.icon-user-plus:before {
  content: '\F234';
}
/* '' */
.icon-television:before {
  content: '\F26C';
}
/* '' */
.icon-calendar-check-o:before {
  content: '\F274';
}
/* '' */
.icon-commenting-o:before {
  content: '\F27B';
}
/* '' */
.icon-credit-card-alt:before {
  content: '\F283';
}
/* '' */
.icon-shopping-basket:before {
  content: '\F291';
}
/* '' */
.icon-percent:before {
  content: '\F295';
}
/* '' */
.icon-question-circle-o:before {
  content: '\F29C';
}
/* '' */
.icon-envelope-open-o:before {
  content: '\F2B7';
}
/* '' */
.icon-user-circle-o:before {
  content: '\F2BE';
}
/* '' */
.icon-id-card-o:before {
  content: '\F2C3';
}
/* '' */
.icon-podcast:before {
  content: '\F2CE';
}
/* '' */
.icon-facebook-squared:before {
  content: '\F308';
}
/* '' */
.icon-print:before {
  content: "\E825";
}
.icon-docs:before {
  content: '\F0C5';
}
/* '' */
.icon-doc-text:before {
  content: '\F0F6';
}
/* '' */
.icon-bookmark-o:before {
  content: '\E903';
}
.icon-location:before {
  content: '\E800';
}
/* '' */
.icon-building:before {
  content: '\E801';
}
/* '' */
.icon-mail:before {
  content: '\E802';
}
/* '' */
.icon-calendar:before {
  content: '\E803';
}
/* '' */
.icon-book:before {
  content: '\E804';
}
/* '' */
.icon-star:before {
  content: '\E805';
}
/* '' */
.icon-barcode:before {
  content: '\E806';
}
/* '' */
.icon-arrows-cw:before {
  content: '\E807';
}
/* '' */
.icon-list-alt:before {
  content: '\E808';
}
/* '' */
.icon-megaphone:before {
  content: '\E809';
}
/* '' */
.icon-up-big:before {
  content: '\E80A';
}
/* '' */
.icon-down-big:before {
  content: '\E80B';
}
/* '' */
.icon-minus:before {
  content: '\E80C';
}
/* '' */
.icon-bell:before {
  content: '\E80D';
}
/* '' */
.icon-credit-card:before {
  content: '\E80E';
}
/* '' */
.icon-user:before {
  content: '\E80F';
}
/* '' */
.icon-chat:before {
  content: '\E810';
}
/* '' */
.icon-cog:before {
  content: '\E811';
}
/* '' */
.icon-floppy:before {
  content: '\E812';
}
/* '' */
.icon-cancel-1:before {
  content: '\E813';
}
/* '' */
.icon-upload:before {
  content: '\E814';
}
/* '' */
.icon-cancel:before {
  content: '\E815';
}
/* '' */
.icon-eye:before {
  content: '\E816';
}
/* '' */
.icon-trash-empty:before {
  content: '\E817';
}
/* '' */
.icon-plus:before {
  content: '\E818';
}
/* '' */
.icon-ok-circled:before {
  content: '\E819';
}
/* '' */
.icon-login:before {
  content: '\E81A';
}
/* '' */
.icon-award:before {
  content: '\E81B';
}
/* '' */
.icon-clock:before {
  content: '\E81C';
}
/* '' */
.icon-tags:before {
  content: '\E81D';
}
/* '' */
.icon-perfil:before {
  content: '\E81E';
}
/* '' */
.icon-shape:before {
  content: '\E81F';
}
/* '' */
.icon-attach:before {
  content: '\E820';
}
/* '' */
.icon-lock:before {
  content: '\E821';
}
/* '' */
.icon-check:before {
  content: '\E822';
}
/* '' */
.icon-check-empty-1:before {
  content: '\E823';
}
/* '' */
.icon-download:before {
  content: '\E824';
}
/* '' */
.icon-estadao:before {
  content: '\E900';
}
/* '' */
.icon-long-arrow-up:before {
  content: '\E901';
}
/* '' */
.icon-long-arrow-down:before {
  content: '\E902';
}
/* '' */
.icon-angle-left:before {
  content: '\E905';
}
/* '' */
.icon-angle-right:before {
  content: '\E906';
}
/* '' */
.icon-angle-down:before {
  content: '\E907';
}
/* '' */
.icon-angle-up:before {
  content: '\E908';
}
/* '' */
.icon-caret-left:before {
  content: '\E909';
}
/* '' */
.icon-caret-right:before {
  content: '\E90A';
}
/* '' */
.icon-caret-up:before {
  content: '\E90B';
}
/* '' */
.icon-caret-down:before {
  content: '\E90C';
}
/* '' */
.icon-bars:before {
  content: '\E90F';
}
/* '' */
.icon-search:before {
  content: '\E910';
}
/* '' */
.icon-facebook:before {
  content: '\E914';
}
/* '' */
.icon-twitter:before {
  content: '\E915';
}
/* '' */
.icon-google-plus:before {
  content: '\E916';
}
/* '' */
.icon-instagram:before {
  content: '\E917';
}
/* '' */
.icon-linkedin:before {
  content: '\E918';
}
/* '' */
.icon-youtube-play:before {
  content: '\E919';
}
/* '' */
.icon-whatsapp:before {
  content: '\E91A';
}
/* '' */
.icon-play:before {
  content: '\E920';
}
/* '' */
.icon-wi-day-cloudy:before {
  content: '\E928';
}
/* '' */
.icon-wi-day-rain:before {
  content: '\E929';
}
/* '' */
.icon-wi-day-rain-mix:before {
  content: '\E92A';
}
/* '' */
.icon-wi-day-snow:before {
  content: '\E92B';
}
/* '' */
.icon-wi-day-storm-showers:before {
  content: '\E92C';
}
/* '' */
.icon-wi-day-sunny:before {
  content: '\E92D';
}
/* '' */
.icon-wi-day-sunny-overcast:before {
  content: '\E92E';
}
/* '' */
.icon-wi-fog:before {
  content: '\E92F';
}
/* '' */
.icon-wi-night-alt-cloudy:before {
  content: '\E930';
}
/* '' */
.icon-wi-night-alt-partly-cloudy:before {
  content: '\E931';
}
/* '' */
.icon-wi-night-alt-rain-mix:before {
  content: '\E932';
}
/* '' */
.icon-wi-night-alt-showers:before {
  content: '\E933';
}
/* '' */
.icon-wi-night-alt-snow:before {
  content: '\E934';
}
/* '' */
.icon-wi-night-alt-storm-showers:before {
  content: '\E935';
}
/* '' */
.icon-wi-snow:before {
  content: '\E936';
}
/* '' */
.icon-round-lock:before {
  content: '\E937';
}
/* '' */
.icon-link-ext:before {
  content: '\F08E';
}
/* '' */
.icon-check-empty:before {
  content: '\F096';
}
/* '' */
.icon-up-circled:before {
  content: '\F0AA';
}
/* '' */
.icon-docs:before {
  content: '\F0C5';
}
/* '' */
.icon-money:before {
  content: '\F0D6';
}
/* '' */
.icon-doc-text:before {
  content: '\F0F6';
}
/* '' */
.icon-circle-empty:before {
  content: '\F10C';
}
/* '' */
.icon-spinner:before {
  content: '\F110';
}
/* '' */
.icon-circle:before {
  content: '\F111';
}
/* '' */
.icon-folder-open-empty:before {
  content: '\F115';
}
/* '' */
.icon-rocket:before {
  content: '\F135';
}
/* '' */
.icon-lock-open-alt:before {
  content: '\F13E';
}
/* '' */
.icon-doc-text-inv:before {
  content: '\F15C';
}
/* '' */
.icon-apple:before {
  content: '\F179';
}
/* '' */
.icon-android:before {
  content: '\F17B';
}
/* '' */
.icon-dot-circled:before {
  content: '\F192';
}
/* '' */
.icon-file-pdf:before {
  content: '\F1C1';
}
/* '' */
.icon-paper-plane:before {
  content: '\F1D8';
}
/* '' */
.icon-paper-plane-empty:before {
  content: '\F1D9';
}
/* '' */
.icon-share:before {
  content: '\F1E0';
}
/* '' */
.icon-newspaper:before {
  content: '\F1EA';
}
/* '' */
.icon-bell-off-empty:before {
  content: '\F1F7';
}
/* '' */
.icon-toggle-off:before {
  content: '\F204';
}
/* '' */
.icon-toggle-on:before {
  content: '\F205';
}
/* '' */
.icon-cart-plus:before {
  content: '\F217';
}
/* '' */
.icon-pinterest:before {
  content: '\F231';
}
/* '' */
.icon-user-plus:before {
  content: '\F234';
}
/* '' */
.icon-television:before {
  content: '\F26C';
}
/* '' */
.icon-calendar-check-o:before {
  content: '\F274';
}
/* '' */
.icon-commenting-o:before {
  content: '\F27B';
}
/* '' */
.icon-credit-card-alt:before {
  content: '\F283';
}
/* '' */
.icon-shopping-basket:before {
  content: '\F291';
}
/* '' */
.icon-percent:before {
  content: '\F295';
}
/* '' */
.icon-question-circle-o:before {
  content: '\F29C';
}
/* '' */
.icon-envelope-open-o:before {
  content: '\F2B7';
}
/* '' */
.icon-user-circle-o:before {
  content: '\F2BE';
}
/* '' */
.icon-id-card-o:before {
  content: '\F2C3';
}
/* '' */
.icon-podcast:before {
  content: '\F2CE';
}
/* '' */
.icon-facebook-squared:before {
  content: '\F308';
}
/* '' */
.icon-bookmark-o:before {
  content: '\E903';
}
/* '' */
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icones-meu-estadao";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-location:before {
  content: '\E800';
}
/* '' */
.icon-building:before {
  content: '\E801';
}
/* '' */
.icon-mail:before {
  content: '\E802';
}
/* '' */
.icon-calendar:before {
  content: '\E803';
}
/* '' */
.icon-book:before {
  content: '\E804';
}
/* '' */
.icon-star:before {
  content: '\E805';
}
/* '' */
.icon-barcode:before {
  content: '\E806';
}
/* '' */
.icon-arrows-cw:before {
  content: '\E807';
}
/* '' */
.icon-list-alt:before {
  content: '\E808';
}
/* '' */
.icon-megaphone:before {
  content: '\E809';
}
/* '' */
.icon-up-big:before {
  content: '\E80A';
}
/* '' */
.icon-down-big:before {
  content: '\E80B';
}
/* '' */
.icon-minus:before {
  content: '\E80C';
}
/* '' */
.icon-bell:before {
  content: '\E80D';
}
/* '' */
.icon-credit-card:before {
  content: '\E80E';
}
/* '' */
.icon-user:before {
  content: '\E80F';
}
/* '' */
.icon-chat:before {
  content: '\E810';
}
/* '' */
.icon-cog:before {
  content: '\E811';
}
/* '' */
.icon-floppy:before {
  content: '\E812';
}
/* '' */
.icon-cancel-1:before {
  content: '\E813';
}
/* '' */
.icon-upload:before {
  content: '\E814';
}
/* '' */
.icon-cancel:before {
  content: '\E815';
}
/* '' */
.icon-eye:before {
  content: '\E816';
}
/* '' */
.icon-trash-empty:before {
  content: '\E817';
}
/* '' */
.icon-plus:before {
  content: '\E818';
}
/* '' */
.icon-ok-circled:before {
  content: '\E819';
}
/* '' */
.icon-login:before {
  content: '\E81A';
}
/* '' */
.icon-award:before {
  content: '\E81B';
}
/* '' */
.icon-clock:before {
  content: '\E81C';
}
/* '' */
.icon-tags:before {
  content: '\E81D';
}
/* '' */
.icon-perfil:before {
  content: '\E81E';
}
/* '' */
.icon-shape:before {
  content: '\E81F';
}
/* '' */
.icon-attach:before {
  content: '\E820';
}
/* '' */
.icon-lock:before {
  content: '\E821';
}
/* '' */
.icon-check:before {
  content: '\E822';
}
/* '' */
.icon-check-empty-1:before {
  content: '\E823';
}
/* '' */
.icon-download:before {
  content: '\E824';
}
/* '' */
.icon-estadao:before {
  content: '\E900';
}
/* '' */
.icon-long-arrow-up:before {
  content: '\E901';
}
/* '' */
.icon-long-arrow-down:before {
  content: '\E902';
}
/* '' */
.icon-angle-left:before {
  content: '\E905';
}
/* '' */
.icon-angle-right:before {
  content: '\E906';
}
/* '' */
.icon-angle-down:before {
  content: '\E907';
}
/* '' */
.icon-angle-up:before {
  content: '\E908';
}
/* '' */
.icon-caret-left:before {
  content: '\E909';
}
/* '' */
.icon-caret-right:before {
  content: '\E90A';
}
/* '' */
.icon-caret-up:before {
  content: '\E90B';
}
/* '' */
.icon-caret-down:before {
  content: '\E90C';
}
/* '' */
.icon-bars:before {
  content: '\E90F';
}
/* '' */
.icon-search:before {
  content: '\E910';
}
/* '' */
.icon-facebook:before {
  content: '\E914';
}
/* '' */
.icon-twitter:before {
  content: '\E915';
}
/* '' */
.icon-google-plus:before {
  content: '\E916';
}
/* '' */
.icon-instagram:before {
  content: '\E917';
}
/* '' */
.icon-linkedin:before {
  content: '\E918';
}
/* '' */
.icon-youtube-play:before {
  content: '\E919';
}
/* '' */
.icon-whatsapp:before {
  content: '\E91A';
}
/* '' */
.icon-play:before {
  content: '\E920';
}
/* '' */
.icon-wi-day-cloudy:before {
  content: '\E928';
}
/* '' */
.icon-wi-day-rain:before {
  content: '\E929';
}
/* '' */
.icon-wi-day-rain-mix:before {
  content: '\E92A';
}
/* '' */
.icon-wi-day-snow:before {
  content: '\E92B';
}
/* '' */
.icon-wi-day-storm-showers:before {
  content: '\E92C';
}
/* '' */
.icon-wi-day-sunny:before {
  content: '\E92D';
}
/* '' */
.icon-wi-day-sunny-overcast:before {
  content: '\E92E';
}
/* '' */
.icon-wi-fog:before {
  content: '\E92F';
}
/* '' */
.icon-wi-night-alt-cloudy:before {
  content: '\E930';
}
/* '' */
.icon-wi-night-alt-partly-cloudy:before {
  content: '\E931';
}
/* '' */
.icon-wi-night-alt-rain-mix:before {
  content: '\E932';
}
/* '' */
.icon-wi-night-alt-showers:before {
  content: '\E933';
}
/* '' */
.icon-wi-night-alt-snow:before {
  content: '\E934';
}
/* '' */
.icon-wi-night-alt-storm-showers:before {
  content: '\E935';
}
/* '' */
.icon-wi-snow:before {
  content: '\E936';
}
/* '' */
.icon-round-lock:before {
  content: '\E937';
}
/* '' */
.icon-link-ext:before {
  content: '\F08E';
}
/* '' */
.icon-check-empty:before {
  content: '\F096';
}
/* '' */
.icon-up-circled:before {
  content: '\F0AA';
}
/* '' */
.icon-docs:before {
  content: '\F0C5';
}
/* '' */
.icon-money:before {
  content: '\F0D6';
}
/* '' */
.icon-doc-text:before {
  content: '\F0F6';
}
/* '' */
.icon-circle-empty:before {
  content: '\F10C';
}
/* '' */
.icon-spinner:before {
  content: '\F110';
}
/* '' */
.icon-circle:before {
  content: '\F111';
}
/* '' */
.icon-folder-open-empty:before {
  content: '\F115';
}
/* '' */
.icon-rocket:before {
  content: '\F135';
}
/* '' */
.icon-lock-open-alt:before {
  content: '\F13E';
}
/* '' */
.icon-doc-text-inv:before {
  content: '\F15C';
}
/* '' */
.icon-apple:before {
  content: '\F179';
}
/* '' */
.icon-android:before {
  content: '\F17B';
}
/* '' */
.icon-dot-circled:before {
  content: '\F192';
}
/* '' */
.icon-file-pdf:before {
  content: '\F1C1';
}
/* '' */
.icon-paper-plane:before {
  content: '\F1D8';
}
/* '' */
.icon-paper-plane-empty:before {
  content: '\F1D9';
}
/* '' */
.icon-share:before {
  content: '\F1E0';
}
/* '' */
.icon-newspaper:before {
  content: '\F1EA';
}
/* '' */
.icon-bell-off-empty:before {
  content: '\F1F7';
}
/* '' */
.icon-toggle-off:before {
  content: '\F204';
}
/* '' */
.icon-toggle-on:before {
  content: '\F205';
}
/* '' */
.icon-cart-plus:before {
  content: '\F217';
}
/* '' */
.icon-pinterest:before {
  content: '\F231';
}
/* '' */
.icon-user-plus:before {
  content: '\F234';
}
/* '' */
.icon-television:before {
  content: '\F26C';
}
/* '' */
.icon-calendar-check-o:before {
  content: '\F274';
}
/* '' */
.icon-commenting-o:before {
  content: '\F27B';
}
/* '' */
.icon-credit-card-alt:before {
  content: '\F283';
}
/* '' */
.icon-shopping-basket:before {
  content: '\F291';
}
/* '' */
.icon-percent:before {
  content: '\F295';
}
/* '' */
.icon-question-circle-o:before {
  content: '\F29C';
}
/* '' */
.icon-envelope-open-o:before {
  content: '\F2B7';
}
/* '' */
.icon-user-circle-o:before {
  content: '\F2BE';
}
/* '' */
.icon-id-card-o:before {
  content: '\F2C3';
}
/* '' */
.icon-podcast:before {
  content: '\F2CE';
}
/* '' */
.icon-facebook-squared:before {
  content: '\F308';
}
/* '' */
.icon-bookmark-o:before {
  content: '\E903';
}
/* '' */
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.align-center {
  /* display:inline-flex; */
  display: flex;
  align-items: center;
  position: relative;
}
.align-end {
  align-items: end;
  position: relative;
}
.align-start {
  align-items: start;
  position: relative;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.full-width {
  width: 100%;
}
.right {
  float: right;
}
.nomargin {
  margin: 0 !important;
}
.nomargin-top {
  margin-top: 0px !important;
}
.nopadding {
  padding: 0 !important;
}
.nopadding-left {
  padding-left: 0 !important;
}
.nopadding-right {
  padding-right: 0 !important;
}
.nopadding-bottom {
  padding-bottom: 0 !important;
}
/* Usando as Cores - Estadão com Opacidade - Use função Fade Less
    color: fade(@cor-estadao, 75%);
    background-color: fade(@bg-estadao, 75%);
    border-color: fade(@brd-estadao, 75%);
*/
header .top {
  height: 115px;
  border-bottom: 1px solid #e8e8e8;
}
@media only screen and (max-width: 991px) {
  header .top .jornal-de-hoje .title {
    display: none;
  }
  header .top .band .logo {
    margin-top: 0;
  }
}
header .top .jornal-de-hoje {
  padding-top: 10px;
}
@media only screen and (max-width: 991px) {
  header .top .jornal-de-hoje .title {
    display: none;
  }
}
header .top .band {
  display: table;
  margin: 0 auto;
}
header .top .band .logo {
  width: 305px;
  height: 51px;
  background-size: 100%;
  background-image: url("/static/img/logo-meuEstadao.svg");
  background-repeat: no-repeat;
  text-indent: -9999px;
  overflow: hidden;
}
header .top .bottom-band ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
header .top .bottom-band ul li {
  display: inline-block;
  color: #444444;
  font-size: 12px;
}
header .top .bottom-band ul li:after {
  content: "|";
  margin: 0 8px;
  display: inline-block;
}
header .top .bottom-band ul li:last-child:after {
  display: none;
}
header .top .bottom-band ul li.tempo .previsao-do-tempo {
  display: inline-block;
  margin-left: 5px;
  vertical-align: top;
}
header .top .bottom-band ul li .eldorado {
  text-decoration: none;
  color: #2575E8;
  display: inline-block;
  vertical-align: bottom;
}
header .top .bottom-band ul li .eldorado > i {
  color: #f5a01a;
  font-size: 7px;
  display: inline-block;
  vertical-align: middle;
  border: 0.5px solid #f5a01a;
  width: 15px;
  height: 15px;
  line-height: 13px;
  text-align: center;
  border-radius: 15px;
  margin-right: 3px;
}
header .top .bottom-band ul li .eldorado > i:before {
  margin-left: 3px;
}
header .top .bottom-band ul li .eldorado > span {
  display: inline-block;
  vertical-align: middle;
}
header .alert-notification {
  height: 34px;
  width: 34px;
  border-radius: 4px;
  cursor: pointer;
  border: solid 1px #2575E8;
  background-color: #ffffff;
}
header .alert-notification .alert-qty {
  width: 18px;
  height: 18px;
  background-color: #4a90e2;
  position: absolute;
  border-radius: 50% 50%;
  color: #fff;
  text-align: center;
  font-size: 12px;
  margin-top: -10px;
  margin-left: -9px;
}
header .alert-notification i {
  font-size: 18px;
  color: #2575E8;
  margin-top: 4px;
  margin-left: auto;
  margin-right: auto;
}
header .user-items {
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  text-align: right;
}
header .user-informations {
  display: inline-block;
  cursor: pointer;
  height: 34px;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  padding: 5px 8px;
  margin-left: 10px;
}
header .user-informations .user-image {
  display: inline-block;
  vertical-align: middle;
}
header .user-informations .user-image i {
  font-size: 20px;
  line-height: 21px;
}
header .user-informations .user-image .image {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50px;
  vertical-align: middle;
}
header .user-informations .user-name {
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
  color: #000000;
  margin-left: 5px;
  text-transform: uppercase;
}
header .col-menu-items {
  width: 344px;
  float: left;
}
header .col-article-items {
  width: 824px;
}
header .article-menu {
  width: 100%;
}
header .article-menu .mid-col {
  justify-content: center;
  display: inline-block;
}
header .article-menu .mid-col .article-menu-item {
  margin: 0 auto;
}
header .article-menu .last-col {
  justify-content: end;
  display: inline-block;
}
header .article-menu .last-col .article-menu-item {
  position: absolute;
  right: 0;
}
header .article-menu .article-menu-item {
  width: 230px;
}
header .article-menu a {
  padding: 0 !important;
}
header .article-menu a:after {
  content: "";
  display: none;
}
header .article-menu .editorial {
  margin-bottom: 10px;
}
header .article-menu .editorial a {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #2575E8 !important;
  text-transform: none !important;
}
header .article-menu .editorial a:hover {
  text-decoration: none;
}
header .article-menu .imagem a {
  opacity: 0.9;
  -webkit-transition: opacity ease 0.2s;
  transition: opacity ease 0.2s;
}
header .article-menu .imagem a:hover {
  opacity: 1;
}
header .article-menu .intro a {
  font-family: 'EstadoHeadline';
  font-size: 18px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  line-height: 1.33 !important;
  letter-spacing: normal !important;
  color: #000000 !important;
}
header .article-menu .intro a:hover {
  text-decoration: none !important;
}
@media only screen and (max-width: 575px), only screen and (max-width: 767px) {
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  header .top {
    height: 56px;
    background-color: #2575E8;
  }
  header .top .band {
    margin: inherit;
    margin-left: 6px;
  }
  header .top .band .logo {
    margin-top: 11px;
    width: 163px;
    height: 27px;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
  }
  header .user-informations {
    background: none;
    border: none;
  }
  header .user-informations .user-name {
    display: none;
  }
  header .user-informations .user-image i {
    color: #FFFFFF;
  }
  header .alert-notification {
    border: 0;
    background-color: inherit;
  }
  header .alert-notification i {
    color: #f5f5f5;
  }
  header .alert-notification .alert-qty {
    margin-top: 0 !important;
    margin-left: 0 !important;
    background-color: #f5a623;
  }
}
.before-footer {
  background: #f5f5f5;
  padding-bottom: 30px;
}
.before-footer .item {
  background: #FFFFFF;
  display: inline-block;
  text-decoration: none;
  width: 100%;
  border-radius: 5px;
  position: relative;
  padding: 15px;
  font-size: 14px;
  color: #999999;
  top: 0;
  right: 0;
  -webkit-transition: 0.15s ease all;
  transition: 0.15s ease all;
  vertical-align: top;
  border: none;
  text-align: left;
  height: 100%;
  cursor: pointer;
  font-family: Lato, Arial, sans-serif;
}
.before-footer .item .title-mobile {
  display: none;
}
.before-footer .item .title {
  color: #4a4a4a;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 55px;
  margin-top: 15px;
  display: block;
  width: 154px;
}
.before-footer .item > i {
  position: absolute;
  right: 0;
  top: 0;
  color: #e9ecef;
  font-size: 62px;
}
@media only screen and (max-width: 1199px) {
  .before-footer .item > i {
    font-size: 70px;
  }
}
.before-footer .item:not(.disabled):hover {
  top: -2px;
  right: -2px;
  box-shadow: -2px 2px 5px -2px #000;
}
.before-footer .item .p-f {
  margin-top: 0;
}
footer .sac-block {
  border-radius: 4px;
  background-color: #f4f4f4;
  padding: 12px 16px;
  margin: 22px -15px 0 -15px;
  font-size: 14px;
}
footer .sac-block h3 {
  margin: 0;
}
footer .sac-block ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}
footer .sac-block ul:last-child {
  margin-bottom: 0;
}
footer .sac-block ul li {
  float: left;
  display: inline-block;
  margin-right: 8px;
}
footer .sac-block ul li:after {
  content: "|";
  margin-left: 8px;
}
footer .sac-block ul li:last-child:after {
  display: none;
}
footer .menu-content {
  margin: 10px 0;
}
footer .menu-content ul {
  display: block;
  padding: 0;
  margin: 0;
  text-align: left;
}
footer .menu-content li {
  margin-right: 10px;
  display: inline-block;
}
footer .menu-content li:after {
  content: "\B7";
  margin-left: 10px;
}
footer .menu-content li:last-child:after {
  display: none;
}
footer .menu-content li a {
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  -webkit-transition: color ease 0.2s;
  transition: color ease 0.2s;
}
footer .menu-content li a:hover {
  color: #2575E8;
}
footer .copyright-mobile {
  display: none;
}
@media only screen and (max-width: 991px) {
  .before-footer {
    padding-bottom: 0;
  }
  .before-footer .col {
    padding: 0;
  }
  .before-footer .container {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
  .before-footer .container .row {
    margin: 0;
    display: grid;
    grid-template-columns: auto auto;
  }
  .before-footer .item {
    border: 1px solid #f5f5f5;
    border-radius: 0;
    padding: 3px 0 8px 0;
  }
  .before-footer .item > i {
    position: relative;
    font-size: 35px;
    display: block;
    text-align: center;
    color: #c3c8cd;
    margin-bottom: 8px;
  }
  .before-footer .item > strong,
  .before-footer .item .title,
  .before-footer .item > p {
    display: none;
  }
  .before-footer .item > .title-mobile {
    display: block;
    text-align: center;
    margin: 0;
    color: #868686;
    font-size: 12px;
    text-transform: uppercase;
    width: 100%;
  }
  footer .footer-brand-wrapper {
    display: none;
  }
  footer .footer-newspaper-wrapper {
    display: none;
  }
  footer .menu-content {
    display: none;
  }
  footer .sac-block {
    margin: 20px 0;
  }
  footer .sac-block ul li {
    float: none;
    line-height: 24px;
  }
  footer .sac-block ul li:after {
    display: none;
  }
  footer .copyright-mobile {
    display: block;
    margin: 20px 0;
    text-align: center;
  }
}
@media only screen and (max-width: 575px) {
  footer {
    padding-bottom: 70px;
  }
  footer .copyright-mobile {
    display: block;
    margin: 0;
    text-align: left;
  }
}
.menu {
  border-bottom: 1px solid #e8e8e8;
}
.menu .menu-principal {
  font-family: Lato, Arial, sans-serif;
}
.menu .menu-principal ul.list {
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
}
.menu .menu-principal ul.list i[class*="icon-"] {
  display: none;
}
.menu .menu-principal ul.list > li {
  display: inline-block;
  position: relative;
}
.menu .menu-principal ul.list > li.home {
  display: none;
}
.menu .menu-principal ul.list > li:not(.focusout):hover > a {
  background-color: rgba(0, 94, 149, 0.05);
}
.menu .menu-principal ul.list > li:not(.focusout):hover > .submenu {
  display: inline-block;
}
.menu .menu-principal ul.list > li > a {
  padding: 0 20px;
  display: inline-block;
  font-weight: normal;
  height: 56px;
  line-height: 56px;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000000;
  background: #FFFFFF;
}
.menu .menu-principal ul.list > li > .submenu + a:after {
  font-family: "icones-meu-estadao";
  content: "\E907";
  margin-left: 5px;
}
.menu .menu-principal ul.list > li > .submenu {
  display: none;
  z-index: 100;
  position: absolute;
  top: 99%;
  left: 0;
  text-align: left;
  padding: 20px;
  background: #FFFFFF;
  min-width: 270px;
  -webkit-filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.5));
  border-radius: 3px 3px 0 0;
  border-bottom: 4px solid #2575E8;
}
.menu .menu-principal ul.list > li > .submenu:before {
  content: "";
  border: 4px solid transparent;
  border-bottom-color: #FFFFFF;
  position: absolute;
  bottom: 100%;
  left: 25%;
  z-index: 2;
}
.menu .menu-principal ul.list > li > .submenu > a {
  position: relative;
  display: inline-block;
  width: 100%;
  float: left;
  white-space: nowrap;
  font-weight: 600;
  text-decoration: none;
  color: #000000;
  font-size: 15px;
  padding: 8px 0;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
}
.menu .menu-principal ul.list > li > .submenu > a.mobile {
  display: none;
}
.menu .menu-principal ul.list > li > .submenu > a:not(.lock):before {
  font-family: "icones-meu-estadao";
  content: "\E906";
  margin-left: 5px;
  opacity: 0;
  position: absolute;
  left: -15px;
  top: 10px;
  text-indent: 0;
  -webkit-transition: 0.2s ease-in-out all;
  transition: 0.2s ease-in-out all;
}
.menu .menu-principal ul.list > li > .submenu > a:not(.lock):hover,
.menu .menu-principal ul.list > li > .submenu > a:not(.lock).active {
  color: #2575E8;
  padding-left: 15px;
}
.menu .menu-principal ul.list > li > .submenu > a:not(.lock):hover:before,
.menu .menu-principal ul.list > li > .submenu > a:not(.lock).active:before {
  opacity: 1;
  left: 0px;
}
.menu .menu-principal ul.list > li > .submenu > a.lock {
  color: #9b9b9b;
}
.menu .menu-principal ul.list > li > .submenu > a.lock:after {
  padding-left: 10px;
  font-family: "icones-meu-estadao";
  content: "\E937";
}
.menu .menu-principal ul.list > li > .submenu > .assine {
  display: inline-block;
  width: 100%;
  padding-top: 30px;
}
.menu .menu-principal ul.list > li > .submenu > .assine > a {
  line-height: 32px;
  padding: 0 20px;
  width: 100%;
  height: 34px;
  text-align: center;
  white-space: nowrap;
  font-size: 10px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px), only screen and (max-width: 575px) {
  .menu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 48px;
    background: #fafafa;
  }
  .menu .menu-principal {
    display: table;
    width: 100%;
    height: 100%;
    background: #fafafa;
    box-shadow: 0 -0.6px 0 0 #cecece;
  }
  .menu .menu-principal ul.list {
    display: table-row;
  }
  .menu .menu-principal ul.list i[class*="icon-"] {
    display: block;
  }
  .menu .menu-principal ul.list > li {
    display: table-cell;
    width: 25%;
    height: 100%;
    float: left;
  }
  .menu .menu-principal ul.list > li[data-key="meus_dados"] {
    float: right;
  }
  .menu .menu-principal ul.list > li.home {
    display: table-cell;
  }
  .menu .menu-principal ul.list > li > a {
    white-space: nowrap;
    width: 100%;
    height: 100%;
    background: #fafafa;
    text-transform: none;
    font-size: 10px;
    line-height: normal;
    line-height: initial;
    padding: 0;
    padding-top: 8px;
    color: #9e9e9e;
  }
  .menu .menu-principal ul.list > li > a.active {
    color: #2575E8;
    background: #fafafa;
  }
  .menu .menu-principal ul.list > li > a > i {
    font-size: 16px;
  }
  .menu .menu-principal ul.list > li > a[data-mlabel]:not([data-mlabel="false"]):after {
    content: attr(data-mlabel);
    font-family: Lato, Arial, sans-serif;
    margin: 0;
  }
  .menu .menu-principal ul.list > li > a[data-mlabel]:not([data-mlabel="false"]) span {
    display: none;
  }
  .menu .menu-principal ul.list > li > .submenu {
    display: inline-block;
    position: fixed;
    top: 55px;
    left: 100%;
    width: 100%;
    height: calc(100% - 103px);
    z-index: -1;
    border-radius: 0;
    border-radius: initial;
    border-bottom: none;
    padding: 0;
    -webkit-filter: none;
            filter: none;
    -webkit-transition: 0.5s ease all;
    transition: 0.5s ease all;
    overflow: auto;
    padding-bottom: 15px;
  }
  .menu .menu-principal ul.list > li > .submenu:before {
    display: none;
  }
  .menu .menu-principal ul.list > li > .submenu > a {
    height: 80px;
    line-height: 79px;
    border-bottom: 1px solid #f5f5f5;
    font-size: 13px;
    font-weight: normal;
    padding: 0 6px;
    color: #535353;
  }
  .menu .menu-principal ul.list > li > .submenu > a.mobile {
    display: inline-block;
  }
  .menu .menu-principal ul.list > li > .submenu > a.active {
    color: #2575E8;
  }
  .menu .menu-principal ul.list > li > .submenu > a i {
    display: inline-block;
    vertical-align: middle;
    font-size: 40px;
    height: 100%;
  }
  .menu .menu-principal ul.list > li > .submenu > a > span {
    display: inline-block;
    vertical-align: middle;
  }
  .menu .menu-principal ul.list > li > .submenu > a:not(.lock):hover,
  .menu .menu-principal ul.list > li > .submenu > a:not(.lock).active {
    text-indent: 0;
    padding-left: 0;
  }
  .menu .menu-principal ul.list > li > .submenu > a:not(.lock):hover:before,
  .menu .menu-principal ul.list > li > .submenu > a:not(.lock).active:before {
    display: none;
  }
  .menu .menu-principal ul.list > li > .submenu > a [data-mlabel]:not([data-mlabel="false"]) {
    text-indent: -9999px;
    overflow: hidden;
  }
  .menu .menu-principal ul.list > li > .submenu > a [data-mlabel]:not([data-mlabel="false"]):after {
    text-indent: 0;
    float: right;
    content: attr(data-mlabel);
    font-family: Lato, Arial, sans-serif;
    margin: 0;
  }
  .menu .menu-principal ul.list > li > .submenu > .assine > a {
    font-size: 16px;
    height: 45px;
    line-height: 45px;
    max-width: 95%;
    margin: 0 auto;
    display: table;
  }
  .menu .menu-principal ul.list > li.focus > a {
    background-color: rgba(0, 94, 149, 0.05);
    color: #9e9e9e;
  }
  .menu .menu-principal ul.list > li.focus > .submenu {
    left: 0;
    display: inline-block;
  }
}
.overlay-modal {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 999;
  -webkit-transition: opacity ease 100ms;
  transition: opacity ease 100ms;
}
.overlay-modal .container-modal {
  background: #f5f5f5;
  position: absolute;
  max-height: 100%;
  padding: 22px 16px;
}
.overlay-modal .container-modal .modal-title {
  font-family: 'Lato';
  font-size: 14px;
  color: #000;
  display: inline-flex;
  align-items: center;
  margin-bottom: 28px;
}
.overlay-modal .container-modal .modal-title i {
  font-size: 18px;
}
.overlay-modal .container-modal .modal-title .title {
  margin-left: 13px;
}
.overlay-modal .container-modal .block-notification {
  font-family: 'Lato';
  font-size: 12px;
  color: #757575;
  margin-top: 8px;
}
.overlay-modal .container-modal .block-notification .content {
  border-radius: 2px;
  background-color: #ffffff;
  padding: 8px;
  margin-top: 8px;
  position: relative;
}
.overlay-modal .container-modal .block-notification .content .notification-head {
  width: 100%;
}
.overlay-modal .container-modal .block-notification .content .notification-head i {
  margin-right: 8px;
}
.overlay-modal .container-modal .block-notification .content .notification-head i.icon-chat {
  color: #3f51b5;
}
.overlay-modal .container-modal .block-notification .content .notification-head i.icon-question-circle-o {
  color: #ff9800;
}
.overlay-modal .container-modal .block-notification .content .notification-head i.icon-credit-card {
  color: #2196f3;
}
.overlay-modal .container-modal .block-notification .content .notification-head i.icon-doc-text-inv {
  color: #e91e63;
}
.overlay-modal .container-modal .block-notification .content .notification-head .time-notification {
  right: 8px;
  top: 8px;
  position: absolute;
}
.overlay-modal .container-modal.notification {
  display: none;
  width: 0;
  height: 100%;
  top: 0;
  opacity: 0;
  right: -262px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.overlay-modal .container-modal.notification.active {
  display: block;
  width: 262px;
  opacity: 1;
  right: 0;
}
.overlay-modal.active {
  opacity: 1;
}
.btn-clear {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
.btn-clear i:before {
  margin: 0;
  padding: 0;
}
.visible-tablet-mobile {
  display: none;
}
@media (max-width: 992px) {
  .visible-tablet-mobile {
    display: unset !important;
  }
}
@media only screen and (max-width: 575px), only screen and (max-width: 767px) {
  .nopadding-mobile {
    padding: 0 !important;
  }
  .ultimas-noticias {
    padding-right: 0 !important;
  }
  .inner-content {
    padding-top: 56px;
  }
  .inner-content .header-content .container {
    padding-bottom: 0;
  }
  .inner-content .welcome-wrapper {
    padding: 0 15px !important;
  }
  .inner-content .welcome-wrapper h2 {
    margin: 0;
    color: #888;
  }
  .inner-content .parceiro,
  .inner-content .eventos {
    margin-bottom: 0;
  }
  .inner-content .parceiro h2::before,
  .inner-content .eventos h2::before {
    content: attr(title-mobile);
  }
  .inner-content .parceiro,
  .inner-content .eventos {
    margin-top: 0;
    padding: 0 15px;
  }
  .inner-content .parceiro h2,
  .inner-content .eventos h2 {
    font-weight: lighter;
  }
  .inner-content .parceiro a,
  .inner-content .eventos a {
    font-weight: bold;
  }
  .inner-content .slider-mobile {
    display: block;
    padding: 0 15px;
  }
  .inner-content .slider-mobile .scroll-area {
    display: block;
    width: auto !important;
    margin-left: -20px;
    margin-right: -20px;
  }
  .inner-content .slider-mobile .scroll-area .slider-container {
    padding: 0 20px;
  }
  .inner-content .slider-mobile .track-horizontal {
    display: inline;
  }
  .main-content-wrapper {
    padding-bottom: 4px;
  }
  .large-bottom-margin {
    margin-bottom: 65px;
  }
  h1.welcome {
    font-size: 20px;
  }
  label[for="assinatura-option"] {
    display: none;
  }
  [name="assinatura-selected"] {
    border-radius: 5px;
    padding: 8px 30px 8px 8px !important;
    border: solid 1px #9b9b9b;
    background: #f5f5f5;
    font-size: 13px !important;
    color: #4a4a4a;
    width: 100%;
  }
  .bloco-simples-wrapper {
    margin-bottom: 70px;
  }
}

