.slider {
  margin-bottom: 20px;
  width: 100%;
  height: 260px;

  h2 {
    font-size: 22px;
    font-weight: normal;
    letter-spacing: -0.2px;
    color: #535353;

    &::before {
      content: attr(title);
    }
  }

  a {
    font-size: 14px;
    letter-spacing: -.1px;
    color: #2575E8;
    text-decoration: none;
    display: block;

    .slider-container {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .slider-container {
    margin: 0;
    width: auto;
    white-space: nowrap;

    .slide-item {
      margin-left: 33px;
      width: 207px;
      display: inline-block;
      justify-content: center;
      border-radius: 4px;
      background-color: #ffffff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
      }

      &:first-child {
        margin-left: 0;
      }

      @media @mediaMd {
        &:last-child {
          margin-right: 10px;
        }
      }
    }
  }

  .track-horizontal {
    background-color: #ced4da;
    width: 100%;
    left: 0px;
    bottom: 0px;
    height: 10px !important;
    display: none;
  }
}

@media (max-width: 768px) {
  .slider {
    h2 {
      font-size: 16px;
    }
  }
}

@media (min-width: 801px) {
  .slider {
    h2 {
      font-size: 22px;
    }
  }
}
