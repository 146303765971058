#add-to-home-screen{
    position: fixed;
    top: 10vh;
    width: 100%;
    z-index: 9999999;
    .center{
        display: table;
        margin: 0 auto;
        padding: 20px;
        width: 100%;
        max-width: 420px;
        .inner{
            padding: 15px;
            border-radius: 1px;
            width: 100%;
            display: inline-block;
            background-color: #ffffff;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.18);
            position: relative;
            .control{
                display: table;
                width: 100%;
                padding-top: 15px;
            }
            p{
                font-size: 14px;
            }
            img{
                display: table;
                margin: 0 auto;
                max-width: 90px;
                width: 100%;
                filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .4));
            }
            button{
                display: inline-block;
                border-radius: 2px;
                border: solid 1px #e9e9e9;
                font-family: 'Flama-Book',sans-serif;
                font-size: 10px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.6;
                letter-spacing: normal;
                text-align: center;
                color: #000000;
                background-color: #ffffff;
                cursor: pointer;
                padding: 10px 20px;
                margin-left: 10px;
                float: right;
                &.install{
                    background-color: #2575e8;
                    color: #FFFFFF;
                }
            }
        }
    }
}