.transferencia-temporaria{
    .data-form{
        .block-single{
            min-height: 240px;
            .content-form{
                .calendar{
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }
        .salvar-dados{
            .mobile{
                display: none;
            }
        }
    }

    form {
        padding: 0 7px;
        margin-bottom: -12px;
        .row {
            margin-right: -8px;
            margin-left: -8px;
            &> [class*="col"] {
                padding-right: 7px;
                padding-left: 7px;
            }

        }

    }
}

@media @mediaXs, @mediaSm, @mediaMd {
    .transferencia-temporaria {
        .data-form {
            .block-single {
                min-height: auto;
                &:first-child {
                    margin-bottom: 25px;
                }
            }
            .salvar-dados{
                margin: 0;
            }
        }
    }
}

@media @mediaXs {
    .transferencia-temporaria{
        .data-form{
            .block-single{
                .content-form{
                    .calendar{
                        width: 100%;
                    }
                }
            }
            .salvar-dados{
                float: none;
                margin: 0 auto;
                display: table;
                span{
                    display: none;
                    &.mobile{
                        display: inline-block;
                    }
                }
            }
        }
    }
}