.meus-dados{
  .foto-do-usuario-container{
    margin: 0 0 15px 0;
    padding: 32px 0;
    .foto-usuario{
      margin-bottom:5px;
      border-radius: 50% 50%;
      overflow: hidden;
    }
  }

  .change-email-screen {
    @media @mediaSm {
      display: none;
    }
  }
  
  .space-between{
    height:30px;
  }
  .alterar-foto{
    min-width: 139px;
  }
  .block-content{
    h1{
      font-size: 30px;
      font-weight: normal;
      letter-spacing: -0.8px;
      text-align: center;
      color: #2575e8;
    }
    h2{
      font-size: 20px;
      font-weight: normal;
      letter-spacing: -0.5px;
      text-align: center;
      color: #818ea3;
    }
  }

  .data-user{
    .title-mobile{
      display: none;
    }
    .block-single{
      margin-top: 0;
    }
    .salvar-dados{
      float: right;
      width: 30%;
      margin-top: 16px;

      @media @mediaMd {
        width: 60%
;      }
      @media @mediaSm {
        width: 281px;
        margin-top: 0px;
      }
    }
    .bottom-block-button-notice{
      display: flex;
      align-items: end;
      margin-top: 30px;
      justify-content: space-between;
      @media @mediaMd {
        flex-direction: column;
        align-items: center;
      }
    }
    .atention-notice{
      width: 70%;
      height: 50px;
      background-color: #fff;
      border-radius: 5px;
      border: solid 1px #f00;
      text-align: left;
      padding: 1% 2%;
      margin-right: 20px;
      color: #707070;
      font-size: 14px;
      overflow: hidden;

      a {
        color: #2575e8;
        font-weight: bold;
      }

      @media @mediaMd {
        height: 68px;
        width: 60%;
        margin-bottom: 26px;
        margin-right: 0px;
      }

      @media @mediaSm {
        margin-bottom: 0px;
        padding: 2%;
        width: 281px;
        height: 94px;
      }
      
    }
    .valid-email{
      float: right;
    }
    button{
      &.redefinir-senha{
        background: transparent;
        border:solid 1px #2575E8;
        color:#2575E8;
        margin-right:20px;
        float: right;
        margin-top: 16px;
      }
    }
  }


  .alterar-email {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    .right-section {
      border-radius: 5px;
      border: solid 1px #dedede;
      background-color: #fff;    
      margin-right: 5px;
      margin-left: 5px;
      p {
        font-size: 16px;
        color: #808080;
        margin: 10px;
      }

      .assinaturas {
        overflow: auto;
        max-height: 120px;
        margin: 9px;
        border: 1px solid #ddd;
        input[type="checkbox"] {
          width: 1.3em;
          height: 1.3em;
          background-color: white;
          border-radius: 50%;
          vertical-align: middle;
          border: 1px solid #ddd;
          -webkit-appearance: none;
          cursor: pointer;
          box-shadow: 0 0 0 2px #808080;
        }
        div{
          border-bottom: 1px solid #b7b5b5;
          padding: 8px;
        }

        input[type="checkbox"]:checked {
          background-color: #808080;
        }

        .selected {
          background-color: #f5f5f5;
        }

       label{
          color:gray;
          padding: 10px;
        }

        .select-all {
          input[type="checkbox"] {
            box-shadow: 0 0 0 2px #2575e8;
          }

          input[type="checkbox"]:checked {
            background-color: #2575e8;
          }
        }
      }
    }
  }

  form{
    padding: 0 7px;
    margin-bottom: -12px;
    .row{
      &>[class*="col"]{
        padding-right: 7px;
        padding-left: 7px;
      }
      &.esqueci-minha-senha{
        margin-top: -5px;
        margin-bottom: 21px;
        a{
          font-family:Lato, Arial, sans-serif;
          font-size: 12px;
          font-weight: normal;
          color: #2575E8;
          text-decoration: none;
        }
      }
    }
  }
}
.block-single{
  &.redefinir-senha{
    padding: 25px!important;
  }
}

.title-inside{
  font-family:Lato, Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  padding-left: 1.1%;

  @media @mediaMd {
    color: #425573;
  }
}

.title-inside-content {
  background-color: red;
  width: 99%;
  height: 46px;
  margin: 0 0 20px;
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

#trocar-foto-do-usuario{
  .title{
    font-size: 30px;
    color: #2575E8;
    margin-bottom: 15px;
  }
  .crop-image{
    .ReactCrop{
      width: 100%;
    }
  }

  .preview-image{
    display: table;
    margin: 0 auto;

    .image{
      display: inline-block;
      width: 132px;
      height: 132px;
      border-radius: 150px;
      background-position: center;
      background-size: contain;
    }
  }

  .control{
    margin-top: 15px;
    .cancel, .confirm{
        margin-right: 10px;
    }

  }
}

.desconectar-social-box {
  display: flex;
  background-color: white;
  min-height: 413px;
  padding: 20px;
  
  @media @mediaMd{
    flex-direction: column;
  }

  .social-box-title {
    &-mobile{
      display: none;
      @media @mediaMd {
        display: block;
      }
    }

    &-desktop{
      display: block;
      @media @mediaMd {
        display: none;
      }
    }

    .title-inside {
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 1rem;
    }

    .text-inside {
      font-size: 17px;
      line-height: 21px;
      color: #808080;
      margin-bottom: 1rem;
    }
  }

  .social-media-name {
    text-transform: capitalize;
  }

  .google-descr {
    display: none;
  }

  .social-image-box {
    position: relative;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 384px;
    width: 100%;
    max-height: 500px;
    min-height: 250px;
    flex-grow: 1;
    margin-right: 1rem;
    
    @media @mediaMd{
      max-width: 700px;
      flex-grow: 0;
      height: 100%;
      margin-right: 0;
    }

    .google-container {
      background-color: transparent;
      padding: 0;
      margin: 0;
    }

    .google-icon {
      border: none;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      .google-text {
        font-size: 50px;
      }
    }
  }

  .social-form-box {
    margin-top: 1rem;

    .salvar-dados {
      margin: 25 !important;
    }

    input {
      color: #808080;
    }
    
    button {
      font-weight: normal;
    }
  }
}

/// Mobile
@media @mediaXs{
  .meus-dados{
    .data-user{
      &>[class*="col-"]{
        padding: 0;
      }
      .block-single{
        border-radius: 0;
      }
      .salvar-dados{
        margin-right: 15px;
        width: 185px;
      }
    }
    form{
      .row{
        &.esqueci-minha-senha{
          margin-top: 5px;
          margin-bottom: 30px;
        }
      }
    }
    .alterar-email {
      display: inline-block;
      .bx {
        max-width: 100%;
        padding-top: 10px;
        .row {
          margin: 0;
        }
      }
      .right-section {
        margin: 0;
      }
    }
  }
}

@media @mediaXs, @mediaSm{
  .meus-dados{
    .data-user{
      .title-mobile{
        display: block;
        padding: 0 20px;
        color: #425573;
        font-size: 18px;
        letter-spacing: -0.1px;
        margin-bottom: 10px;
      }
    }
  }
  .btn{
    float:none!important;
    margin:25px auto !important;
    width: auto;
    min-width:185px;
    display:block;
    &:last-child{
      margin-bottom: 0 !important;
    }
  }
}