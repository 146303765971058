.interrupcao-temporaria{
    .data-form{
        .block-single{
            min-height: 240px;
            .content-form{
                .calendar{
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media @mediaXs, @mediaSm, @mediaMd {
    .interrupcao-temporaria {
        .data-form {
            .block-single {
                min-height: auto;
                &:first-child {
                    margin-bottom: 25px;
                }
            }
            .salvar-dados{
                margin: 0;
            }
        }
    }
}

@media @mediaXs {
    .interrupcao-temporaria{
        .data-form{
            .block-single{
                .content-form{
                    .calendar{
                        width: 100%;
                    }
                }
            }
            .salvar-dados{
                float: none;
                margin: 0 auto;
                display: table;
            }
        }
    }
}