.wrapper-404{
    text-align: center;
    padding: 50px 0;
    .icon{
        display: table;
        margin: 0 auto;
        width: 300px;
        height: 300px;
        background-size: contain;
        background-image: url(/static/img/jornal.svg);
        background-repeat: no-repeat;
        text-indent: -9999px;
        overflow: hidden;
        background-position: center;
    }
    h1{
        color: #757575;
        font-size: 40px;
        font-weight: normal;
        margin-bottom: 0px;
    }
    h2{
        color: #757575;
        font-size: 25px;
        font-weight: normal;
        margin-bottom: 80px;
    }
    .btn{
        font-size: 18px;
        font-weight: normal;
        height: 56px;
        line-height: 56px;
        width: 100%;
        max-width: 320px;
    }
}

@media @mediaXs{
    .wrapper-404{
        .icon{
            width: 200px;
            height: 200px;
        }
        h1{
            font-size: 22px;
        }
        h2{
            font-size: 16px;
        }
    }
}