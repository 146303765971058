.btn{
    display: inline-block;
    padding: 0 20px;
    font-weight: bold;
    color: #FFFFFF;
    background: #2575E8;
    border: 1px solid #2575E8;
    height: 50px;
    line-height: 48px;
    border-radius: 4px;
    font-size: 14px;
    transition: 0.2s ease all;
    cursor: pointer;
    text-decoration: none;
    position: relative;

    &:not(.disabled) {
        &:hover, &:focus {
            background: #FFFFFF;
            color: #2575E8;
        }
    }

    &.red{
        background: #c50303;
        border-color: #c50303;
        &:not(.disabled){
            &:hover, &:focus{
                background: #FFFFFF;
                color: #c50303;
            }
        }
    }

    &.white{
        background: #FFFFFF;
        color: #2575E8;
        &:not(.disabled) {
            &:hover, &:focus {
                background: #2575E8;
                color: #FFFFFF;
            }
        }
    }

    &.green{
        background: #417505;
        border-color: #417505;
        &:not(.disabled) {
            &:hover, &:focus {
                background: #FFFFFF;
                color: #417505;
            }
        }
    }

    &:focus{
        outline: none;
    }

    &.disabled{
        opacity: 0.5;
        cursor: default;
    }
}