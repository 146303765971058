header{
    .top{
        height: 115px;
        border-bottom: 1px solid #e8e8e8;
        @media @mediaMd{
            .jornal-de-hoje{
                .title{
                    display: none;
                }
            }
            .band{
                .logo{
                    margin-top: 0;
                }
            }
        }
        .jornal-de-hoje{
            padding-top: 10px;
            @media @mediaMd{
                .title{
                    display: none;
                }
            }
        }

        .band{
            display: table;
            margin:  0 auto;
            .logo{
                width: 305px;
                height: 51px;
                background-size: 100%;
                background-image: url("/static/img/logo-meuEstadao.svg");
                background-repeat: no-repeat;
                text-indent: -9999px;
                overflow: hidden;
            }
        }

        .bottom-band{
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: center;
                li{
                    display: inline-block;
                    color: #444444;
                    font-size: 12px;
                    &:after{
                        content: "|";
                        margin: 0 8px;
                        display: inline-block;
                    }
                    &:last-child:after{
                        display: none;
                    }
                    &.tempo{
                        .previsao-do-tempo{
                            display: inline-block;
                            margin-left: 5px;
                            vertical-align: top;
                        }
                    }

                    .eldorado{
                        text-decoration: none;
                        color: #2575E8;
                        display: inline-block;
                        vertical-align: bottom;
                        &>i{
                            color: #f5a01a;
                            font-size: 7px;
                            display: inline-block;
                            vertical-align: middle;
                            border: .5px solid #f5a01a;
                            width: 15px;
                            height: 15px;
                            line-height: 13px;
                            text-align: center;
                            border-radius: 15px;
                            margin-right: 3px;
                            &:before{
                                margin-left: 3px;
                            }
                        }
                        &>span{
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }

    .alert-notification{
        height: 34px;
        width: 34px;
        border-radius: 4px;
        cursor:pointer;
        border: solid 1px #2575E8;
        background-color: #ffffff;
        .alert-qty{
            width: 18px;
            height: 18px;
            background-color: #4a90e2;
            position:absolute;
            border-radius:50% 50%;
            color:#fff;
            text-align:center;
            font-size: 12px;
            margin-top: -10px;
            margin-left: -9px;
        }
        i{
            font-size: 18px;
            color: #2575E8;
            margin-top: 4px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .user-items{
        white-space: nowrap;
        width:100%;
        display: inline-block;
        text-align:right;
    }
    .user-informations{
        display: inline-block;
        cursor: pointer;
        height: 34px;
        align-items:center;
        border-radius: 4px;
        border: solid 1px #e8e8e8;
        background-color: #ffffff;
        padding: 5px 8px;
        margin-left: 10px;
        .user-image{
            display: inline-block;
            vertical-align: middle;
            i{
                font-size: 20px;
                line-height: 21px;
            }
            .image{
                display: inline-block;
                width: 20px;
                height: 20px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 50px;
                vertical-align: middle;
            }
        }
        .user-name{
            display: inline-block;
            vertical-align: middle;
            font-size: 11px;
            color: #000000;
            margin-left: 5px;
            text-transform: uppercase;
        }
    }
    .col-menu-items{
        width: 344px;
        float:left;
    }
    .col-article-items{
        width:824px;
    }
    .article-menu{
        width: 100%;
        .mid-col{
            justify-content: center;
            display: inline-block;
            .article-menu-item{
                margin:0 auto;
            }
        }
        .last-col{
            justify-content: end;
            display: inline-block;
            .article-menu-item{
                position: absolute;
                right: 0;
            }
        }
        .article-menu-item{
            width:230px;
        }
        a{
            padding:0 !important;
            &:after{
                content:"";
                display:none;
            }
        }
        .editorial{
            margin-bottom: 10px;
            a{
                font-size: 15px !important;;
                font-weight: 600 !important;;
                color: #2575E8 !important;
                text-transform:none !important;
                &:hover{
                    text-decoration: none;
                }
            }
        }
        .imagem{
            a{
                opacity:0.9;
                transition:opacity ease 0.2s;
                &:hover{
                    opacity:1;
                }
            }
        }
        .intro{
            a{
                font-family: 'EstadoHeadline';
                font-size: 18px !important;
                font-weight: 600 !important;
                text-transform:none !important;
                line-height: 1.33 !important;
                letter-spacing: normal !important;
                color: #000000 !important;
                &:hover{
                    text-decoration: none !important;
                }
            }
        }
    }
}

@media @mediaXs, @mediaSm{
    header {
        position:fixed;
        top:0;
        left:0;
        width:100%;
        .top {
            height: 56px;
            background-color: #2575E8;

            .band {
                margin: inherit;
                margin-left: 6px;

                .logo {
                    margin-top: 11px;
                    width: 163px;
                    height: 27px;
                    filter: brightness(0) invert(1);
                }
            }
        }

        .user-informations{
            background: none;
            border: none;
            .user-name{
                display: none;
            }
            .user-image{
                i{
                    color: #FFFFFF;
                }
            }
        }

        .alert-notification {
            border: 0;
            background-color: inherit;

            i {
                color: #f5f5f5;
            }

            .alert-qty {
                margin-top: 0 !important;
                margin-left: 0 !important;
                background-color: #f5a623;
            }
        }
    }
}