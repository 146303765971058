.controle-de-acesso{
    border-radius: 4px;
    background-color: #ffffff;
    padding:25px;
    .top{
        display: table;
        margin-bottom: 16px;
        width: 100%;
        .btn{
            height: 50px;
            border-radius: 4px;
            border: solid 1px #2575E8;
            background-color: #ffffff;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.44px;
            text-align: center;
            color: #2575E8;
        }
        p{
            font-size: 16px;
            color: #232323;
            margin: 8px 0 0;
            span{
                color:#2575E8;
            }
        }
    }
    .item{
        display: inline-flex;
        margin-bottom: 16px;
        width: 100%;
        transition: 0.5s ease all;
        border-radius: 4px;
        border: solid 1px #dedede;
        background-color: #f9f9f9;
        @media @mediaXs{
            display: block;
        }
        &:hover{
            border-color: #2575E8
        }
        .field{
            display:inline;
            font-size: 16px;
            color: #222222;
            width:calc(100% - 102px);
            vertical-align: middle;
            &.action{
                width: 102px;
                .btn{
                    height: 50px;
                    width: 102px;
                    border-radius: 4px;
                    border: solid 1px #2575E8;
                    background-color: #ffffff;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: -0.44px;
                    text-align: center;
                    color: #2575E8;
                    padding:0;
                    min-width:102px;
                }
            }
            em{
                display: block;
                font-size: 10px;
                color: #2575E8;
            }
            p{
                font-size: 16px;
                margin:0;
                color: #5a6169;
                &.owner{
                    color: #5bb85d !important;
                    font-weight: bold;
                    margin-bottom:8px;
                }
            }
        }
    }
}
.alert-controle{
    margin: 0 0 15px 0;
    padding: 32px;
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    color: #232323;
    .block-content{
        text-align:center;
        p{
            text-align:left;
            margin:0 0 12px;
        }
        button{
            font-size: 13px;
        }

    }
}
@media @mediaXs{
    .alert-controle {
        margin-top: 32px !important;
    }
}